import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";


//import { Accordion } from 'react-bootstrap';
//import ProductsCheckout from '../../Products/ProductsCheckout/ProductsCheckout';

//Components
import CheckoutCardTour from '../CheckoutCards/CheckoutCardTour';
import CheckoutCardTransfer from '../CheckoutCards/CheckoutCardTransfer';

import "./StepBook.scss"
import CheckoutCardTicket from '../CheckoutCards/CheckoutCardTicket';
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import SimpleAlert from '../../Alert/SimpleAlert/SimpleAlert';
import DiscountCoupon from '../../DiscountCoupon/DiscountCoupon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { removeCupomCart, updateDadosCart } from '../../../store/cart';

import { scroller } from "react-scroll";

export interface propAction {
  action: any,
};

const StepBook: React.FC<propAction> = ({
  action
}: propAction) => {
  const { t } = useTranslation();
  const [alert, setAlert] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [pickupSelect, setPickupSelect] = useState<any>(null);

  const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));
  const dispatch = useAppDispatch();


  const addPickup = (objPickup: any, indexP: any, integration: any) => {
    
    setPickupSelect(objPickup.id);

    if (integration === true) {
      cartBPA.dados[indexP].IdPickupDestino = objPickup.idPickupLocation;
      cartBPA.dados[indexP].AntecedenciaPickupDestino = objPickup.minutesPrior;
      cartBPA.dados[indexP].NomeListaPickupDestino = objPickup.nomeListaPickup;
      cartBPA.dados[indexP].ListaPickupDestino = objPickup.pickupIdStranger;
      cartBPA.dados[indexP].NomePickupDestino = objPickup.nomePickup;
    }

    cartBPA.dados[indexP].pickup = objPickup.id;
    cartBPA.dados[indexP].pickupInfo = objPickup;
    cartBPA.dados[
      indexP
    ].meetingPoint = `${objPickup.name} - ${objPickup.address}`;

    dispatch(updateDadosCart(cartBPA.dados));
  };

  const validate = () => {
    var vali = true;
    cartBPA.dados.forEach((cItem: any, index: any) => {
      if (cItem.typePickup === "1" || cItem.typePickup === "0") {
        if (cItem.pickup === 0 && cItem.reservationSystem === 99) {
          vali = false;

          scroller.scrollTo(`card${index}`, {
            duration: 100,
            delay: 0,
            smooth: true,
            offset: 0,
          })
        }
      }
    });

    if (vali === true) {
      action();
      setAlert(null)
    } else {
      setAlert(
        <SimpleAlert alertType={"danger py-1 px-2 my-1"}>
          {t("checkoutSteps.error")}
        </SimpleAlert>
      )
      setTimeout(() => setAlert(null), 2000);
    }
  }

  /* function onSubmit(e: any) {
    e.preventDefault();
  } */

  function ScrollTop() {
    window.scrollTo(0, 0);
  }

  if (cartBPA.dados && cartBPA.dados.length >= 1) {
    let totalCart = 0;
    return (
      <>

        <div className="row">
          <div className="col-12 col-md-8 col-lg-8 order-1" data-listproduct="checkout">
            {cartBPA.dados.length >= 1 ? cartBPA.dados.map((cItem: any, index: any) => {

              let priceProduct = (
                (Number(cItem.adults) * Number(cItem.priceAdults))
                + (Number(cItem.childs) * Number(cItem.priceChilds))
                + (Number(cItem.infants) * Number(cItem.priceInfants))
                + (Number(cItem.elders) * Number(cItem.priceElders))
                + (Number(cItem.student) * Number(cItem.priceStudent))
                + (Number(cItem.priceGlobalPeople))
              );
              totalCart = Number(totalCart) + (priceProduct - (priceProduct * (cItem.discount / 100)));

              if (cItem.productType === 1) {
                return (
                  <div key={index}>
                    <CheckoutCardTour product={cItem} indexP={index} alert={alert} addPickup={addPickup}>
                    </CheckoutCardTour>
                  </div>
                )
              } else if (cItem.productType === 4) {
                return (
                  <div key={index}>
                    <CheckoutCardTicket product={cItem} indexP={index} alert={alert} addPickup={addPickup}></CheckoutCardTicket>
                  </div>
                )
              } else if (cItem.productType === 2) {
                return (
                  <div key={index}>
                    <CheckoutCardTransfer transfers={[cItem]}></CheckoutCardTransfer>
                  </div>
                )
              } else {
                return (
                  <div key={index}></div>
                )
              }
            }) : ''}
          </div>

          <div className="col-12 col-md-4 col-lg-4 col-xl-4 order-2 aside" id="asideCheck01">
            <div className="strickPrecoCkekout sticky-top mt-4">
              <div className="bg-white p-2 mb-2 mb-md-4 asideCheck01total">
                <div className="row bg-black-10 p-2 p-lg-3 m-0">
                  {
                    cartBPA?.cupom?.type === 2
                      ?
                      <small style={{ display: "flex", justifyContent: "space-between" }}>
                        <span>{cartBPA.cupom.codeName}</span>
                        <span>R$ {cartBPA?.cupom?.value.toFixed(2).split(".").join(",")}</span>
                      </small>
                      :
                      ''
                  }

                  {
                    cartBPA?.cupom?.type === 4 ?
                      <small style={{ display: "flex", justifyContent: "space-between", paddingRight: "0", fontSize: "12px" }}>
                        <span >{cartBPA.cupom.codeName}</span>
                        <span> {cartBPA?.cupom?.value + '%'}{/*  - {(totalCart * (cartBPA?.cupom?.value / 100)).toFixed(2).split(".").join(",")} */}
                          <button
                            onClick={() => {
                              dispatch(removeCupomCart());
                            }}
                            className="p-0 m-0 text-muted"
                            style={{ cursor: "pointer", marginRight: "12px" }}
                          >
                            <i className="text-muted btn-exclui text-dark btn-exclui-carrinho ml-2">
                              <FontAwesomeIcon style={{ color: "#212529" }} icon={["fal", "times"]} />
                            </i>
                          </button>
                        </span>
                      </small>
                      :
                      ''}

                  <div className="col-6 text-left">
                    TOTAL
                  </div>

                  {/* {cartBPA?.cupom?.type === 2 
                    ?
                    <div className="col-6 text-right font-weight-bold text-primary h5 p-0 m-0" data-price="total">R$ {(totalCart - cartBPA?.cupom?.value).toFixed(2).split(".").join(",")}</div>
                    : 
                    <div className="col-6 text-right font-weight-bold text-primary h5 p-0 m-0" data-price="total">R$ {(totalCart).toFixed(2).split(".").join(",")}</div>
                    } */}

                  {/* {cartBPA?.cupom?.type === 2 ?
                    <div className="col-6 text-right font-weight-bold text-primary h5 p-0 m-0" data-price="total">R$ {(cartBPA.totalCart - cartBPA.discount).toFixed(2).split(".").join(",")}</div>
                    : cartBPA?.cupom?.type === 4 ?
                      <div className="col-6 text-right font-weight-bold text-primary h5 p-0 m-0" data-price="total">R$ {(totalCart - totalCart * (cartBPA?.cupom?.value / 100)).toFixed(2).split(".").join(",")}</div>
                      : <div className="col-6 text-right font-weight-bold text-primary h5 p-0 m-0" data-price="total">R$ {(totalCart).toFixed(2).split(".").join(",")}</div>
                  } */}

                  <div className="col-6 text-right font-weight-bold text-primary h5 p-0 m-0" data-price="total">R$ {(cartBPA.totalCart).toFixed(2).split(".").join(",")}</div>
                </div>

                <div className="row mt-2">
                  <div className="col-6">
                    <a href={window.location.origin} title="" className="btn btn-outline-primary btn-block text-primary btn-activities">{t("checkoutSteps.reserve")}</a>
                  </div>
                  <div className="col-6">
                    <button className="btn btn-primary bg-primary text-white btn-block btn-compra w-100" data-toggle="pill" data-btn="step1" onClick={() => { validate(); ScrollTop() }} >{t("checkoutSteps.buy")}</button>
                  </div>
                </div>
              </div>
              <DiscountCoupon></DiscountCoupon>
            </div>
          </div>
        </div>
        {/* <ProductsCheckout /> */}
      </>
    );
  } else {
    return (
      <div className='border-empty my-5 py-4 d-flex flex-column align-items-center'>
        <h4>{t("checkoutSteps.emptyCart")} :(</h4>
        <div>
          <Link className='btn btn-primary px-4' to={'/'} >{t("checkoutSteps.back")}</Link>
        </div>
      </div>
    )
  }
}

export default StepBook;