import React from 'react';

import i18next from "i18next";
import { useTranslation } from 'react-i18next';

import PageDafault from '../../../../templates/PageDafault';
import RegulationPT from './RegulationPT/RegulationPT';
import RegulationEN from './RegulationEN/RegulationEN';
import RegulationES from './RegulationES/RegulationES';

function Regulation() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t] = useTranslation();

    return (
        <>
            <PageDafault>
                <div className="container py-5">
                    {
                        i18next.language === "pt"
                            ?
                            <RegulationPT />
                            :
                            i18next.language === "en"
                                ?
                                <RegulationEN />
                                :
                                i18next.language === "es"
                                    ?
                                    <RegulationES />
                                    :
                                    <RegulationPT />
                    }
                </div>
            </PageDafault>
        </>
    )
}

export default Regulation;