import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from "react-i18next";

import { Container, Row, Col } from 'react-bootstrap';
import apiGoogleMaps from "../../services/apiGoogleMaps";
import configData from "../../config/config.json";
import InputMask from "react-input-mask";

import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import Button from 'react-bootstrap/Button';

import GlobalAlert from "../../components/Alert/GlobalAlert/GlobalAlert";

import './Profile.scss';
import { Link } from 'react-router-dom';
/* import ModalDefault from '../../components/Modal/ModalDefault/ModalDefault'; */
import PageDafault from '../../templates/PageDafault';
import ApprovedTerms from '../../components/AppovedTerms/ApprovedTerms';

import Modal from "react-bootstrap/Modal";

function Profile() {
    const [t] = useTranslation();
    const [modalShow, setModalShow] = React.useState(false);

    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cpf, setCpf] = useState('');
    const [passport, setPassport] = useState('');
    const [phone, setPhone] = useState('');
    const [whatsapp, setWhatsapp] = useState('');
    const [email, setEmail] = useState('');
    const [cep, setCEP] = useState('');
    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');
    const [foreign, setForeign] = useState<any>('');

    const [disableState, setDisableState] = useState(false);
    const [disableCity, setDisableCity] = useState(false);
    const [disableAddress, setDisableAddress] = useState(false);

    //const [loading, setLoading] = useState<any>(false);

    const [aceptPrivacy, setAceptPrivacy] = useState<any>(null);
    const [aceptWpp, setAceptWpp] = useState<any>(null);

    const profile = true;

    const [privacy, setPrivacy] = useState<any>(false);

    const [profileImg, setProfileImg] = useState<any>(null);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [profileNameImg, setProfileNameImg] = useState<any>('None');

    const [editProfile, setEditProfile] = useState<any>(false);

    const [sucess, setSucess] = useState<any>();

    const validated = false;

    const imageHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setProfileImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setProfileNameImg(e.target.files[0].name);
    }

    const handleChangeFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("firstName", newValue);
        setFirstName(newValue);
    }
    const handleChangeLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("lastName", newValue);
        setLastName(newValue);
    }
    /* const handleChangeCpf = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setCpf(newValue);
    } */
    const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("cellphone", newValue);
        setPhone(newValue);
    }
    const handleChangeWhatsapp = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("whatsapp", newValue);
        setWhatsapp(newValue);
    }

    /* const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setEmail(newValue);
    } */

    const handleChangeCEP = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("cep", newValue);
        setCEP(newValue);
    }

    const handleChangeState = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("state", newValue);
        setState(newValue);
    }

    const handleChangeCity = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("city", newValue);
        setCity(newValue);
    }

    const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("address", newValue);
        setAddress(newValue);
    }

    const handleChangeCountry = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("country", newValue);
        setCountry(newValue);
    }

    const handleChangeNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("number", newValue);
        setNumber(newValue);
    }

    const handleChangeComplement = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setValue("complement", newValue);
        setComplement(newValue);
    }

    function Leave() {
        if (window.location.pathname === '/nova-senha' && sucess === 'Alterado  com sucesso') {
            window.location.href = "/login";
        } else { }
        if (window.location.pathname === '/nova-conta' && sucess === 'Cadastro realizado com sucesso, favor verificar seu e-mail para confirmar o cadastro.') {
            window.location.href = "/login";
        } else { }
    }

    useEffect(() => {
        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}` },
        };

        const getAuthenticateAccount = async () => {

            try {
                const { data } = await api.post('/api/LoginSite/GetPerfil', '', config);
                if (data.status !== 400) {
                    setFirstName(data.data.firstName);
                    setLastName(data.data.lastName);
                    setCpf(data.data.cpf);
                    setPassport(data.data.passport)
                    setPhone(data.data.phone);
                    setWhatsapp(data.data.whatsapp);
                    setEmail(data.data.email);
                    setProfileImg(data.data.photo);
                    setCEP(data.data.userComplement.postCode);
                    setState(data.data.userComplement.state);
                    setCity(data.data.userComplement.city);
                    setAddress(data.data.userComplement.address);
                    setCountry(data.data.userComplement.country);
                    setAceptPrivacy(data.data.aceitePolitica);
                    setAceptWpp(data.data.aceiteComunicacao);
                    setForeign(data.data.foreign);
                    setNumber(data.data.userComplement.number);
                    setComplement(data.data.userComplement.complement);

                    setDisableState(!(data.data.userComplement.state === null || data.data.userComplement.state === ''));
                    setDisableCity(!(data.data.userComplement.city === null || data.data.userComplement.city === ''));
                    setDisableAddress(!(data.data.userComplement.address === null || data.data.userComplement.address === ''));
                } else {

                }

            } catch (error: any) {
                if (error?.response?.status === 400) {
                }

            }
        }

        getAuthenticateAccount();
    }, []);

    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue
    } = useForm();

    const onSubmit = (objData: any) => {
        setModalShow(true);

        let checked: any = document.querySelector('#checkNew')
        objData.checkNew = checked.checked;

        objData.firstName = firstName;
        objData.lastName = lastName;
        objData.cellphone = phone;
        objData.whatsapp = whatsapp;
        objData.cep = cep;
        objData.state = state;
        objData.city = city;
        objData.address = address;
        objData.country = country;
        objData.number = number;
        objData.complement = complement;

        //setLoading(true)
        setModalContent('loading');

        const token = localStorage.getItem('bpasiteAuth') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${JSON.parse(token)?.token?.token}` },
        };

        const getAuthenticateAccount = async () => {
            try {
                const { data } = await api.post('/api/LoginSite/UpdateLogin', { ///UPDATE LOGIN API
                    "firstName": objData.firstName,
                    "lastName": objData.lastName,
                    "cellphone": objData.cellphone,
                    "whatsapp": objData.whatsapp,
                    "photo": profileImg,
                    "userComplement": {
                        "address": objData.address,
                        "postCode": objData.cep,
                        "city": objData.city,
                        "state": objData.state,
                        "country": objData.country,
                        "number": objData.number,
                        "complement": objData.complement === "" ? null : objData.complement
                    },
                    "aceitePolitica": getValues('checkPolice'),
                    "aceiteComunicacao": objData.checkNew
                }, config);
                if (data.status !== 400) {
                    if (data.statusCode === 400) {
                        setTimeout(() => {
                            setSucess(data.errorMessage);
                            setModalContent('error');
                            setLog(data.data.log);
                        }, 750);
                    } else if (data.data.log === 1) {
                        setTimeout(() => {
                            setSucess(data.data.texto);
                            setModalContent('error');
                            setLog(data.data.log);
                        }, 750);
                    } else {
                        setTimeout(() => {
                            setSucess(data.data.texto);
                            setModalContent('sucess');
                            setLog(data.data.log);
                        }, 750);

                        //Altera a foto do perfil no header
                        var newImage: any = localStorage.getItem('bpasiteAuth');
                        newImage = JSON.parse(newImage);
                        newImage.userModel.photo = profileImg;
                        localStorage.setItem('bpasiteAuth', JSON.stringify(newImage));

                        <GlobalAlert alertType={"success"}>
                            {data.data.texto}
                        </GlobalAlert>

                        setTimeout(() => {
                            window.location.reload();
                        }, 2000);
                    }

                } else {

                }

            } catch (error: any) {
                if (error?.response?.status === 400) {
                    setSucess("Preencher informações")
                    setModalContent('error');
                    setLog(1);
                } else if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/login';
                }

            }
        }

        getAuthenticateAccount();
    }

    const searchCEP = async (term: any) => {
        setDisableState(false);
        setDisableCity(false);
        setDisableAddress(false);

        setValue("address", null);
        setAddress("");

        setValue("city", null);
        setCity("");

        setValue("state", null);
        setState("");

        const response = await apiGoogleMaps.get("/geocode/json", {
            params: {
                address: term,
                key: configData.GOOGLE_KEYS.GOOGLE_MAPS,
            },
        });

        if (response.data.status === "OK") {
            response.data.results[0].address_components.forEach(function (place: any) {
                switch (place.types[0]) {
                    case "route":
                        setValue("address", place.long_name);
                        setAddress(place.long_name);
                        setDisableAddress(true);
                        break;

                    case "administrative_area_level_2":
                        setValue("city", place.long_name);
                        setCity(place.long_name);
                        setDisableCity(true);
                        break;

                    case "country":
                        setValue("country", place.long_name);
                        setCountry(place.long_name);
                        break;

                    case "administrative_area_level_1":
                        setValue("state", place.long_name);
                        setState(place.long_name);
                        setDisableState(true);
                        break;

                    default:
                }
            });
        } else {
            setDisableState(false);
            setDisableCity(false);
            setDisableAddress(false);
        }
    };

    const handleBlurCEP = (event: any) => {
        searchCEP(event.target.value);
    };

    function ScrollTop() {
        window.scrollTo(0, 0);
    }
    
    return (
        <>
            <PageDafault>
                <div className="container profile">
                    <Container className="profile-container" fluid>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Row style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', }}>
                                <h2 className="display-5 mb-4 w-auto"> {t("profile.title")}</h2>
                                {editProfile === false ?
                                    <span className="w-auto text-primary" style={{ cursor: 'pointer', fontSize: '1.125rem' }} onClick={() => { setEditProfile(!editProfile) }}>
                                        {t("profile.edit")}
                                        <FontAwesomeIcon

                                            icon={["fal", "user-edit"]}
                                            size="1x"
                                            style={{ cursor: 'pointer' }}
                                            className="ml-2"
                                        />
                                    </span>
                                    : <></>}
                            </Row>

                            <Row className="mb-3">
                                <Form.Group className="mb-3">
                                    <div className="label">
                                        <label className="image-upload" htmlFor="input">
                                            <h4>
                                                {t("profile.profilePhoto")}
                                            </h4>
                                            <div className="mx-3 mb-3 mt-2"
                                                style={{ cursor: editProfile === true ? 'pointer' : 'auto' }}>
                                                <img src={profileImg} alt="" className="logo-info-company" referrerPolicy="no-referrer" />
                                            </div>
                                        </label>
                                        {editProfile === true ? <input type="file" accept="image/*" name="image-upload" id="input" onChange={imageHandler} /> : ""}
                                    </div>
                                </Form.Group>

                                <h4 className='mt-3 mb-3'>{t("profile.personalData.title")}</h4>

                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.personalData.name")}
                                    </Form.Label>
                                    {/* <input type="text" id="firstName" value={firstName} onChange={handleChangeFirstName} /> */}
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="firstName"
                                                rules={{ required: { value: true, message: 'Por favor, informe o nome' } }}
                                                defaultValue={firstName}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={firstName}
                                                        onChange={handleChangeFirstName}
                                                        aria-invalid={errors?.firstName ? "true" : ""}
                                                    //required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="firstName"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>
                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {firstName}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.personalData.lastname")}
                                    </Form.Label>
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="lastName"
                                                defaultValue={lastName}
                                                rules={{ required: { value: true, message: 'Por favor, informe o sobrenome' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={lastName}
                                                        onChange={handleChangeLastName}
                                                        aria-invalid={errors?.lastName ? "true" : ""}
                                                    //required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="lastName"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>
                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {lastName}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    {cpf && foreign === 2
                                        ?
                                        <>
                                            <Form.Label>
                                                {t("profile.personalData.cpf")}
                                            </Form.Label>
                                            <Form.Text className="text-muted text-user-info">
                                                {cpf}
                                            </Form.Text>
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                    {passport && foreign === 1
                                        ?
                                        <>
                                            <Form.Label>
                                                {t("profile.personalData.passport")}
                                            </Form.Label>
                                            <Form.Text className="text-muted text-user-info">
                                                {passport}
                                            </Form.Text>
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.personalData.phone")}
                                    </Form.Label>
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="cellphone"
                                                defaultValue={phone}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: 'Por favor, informe o celular.'
                                                    }
                                                }}
                                                render={() => (
                                                    <InputMask mask={`(99) 99999-9999`} value={phone} onChange={handleChangePhone}>
                                                        {({ inputProps }: any) => (
                                                            <Form.Control
                                                                {...inputProps}
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                aria-invalid={errors?.cellphone ? "true" : ""}
                                                            />
                                                        )}
                                                    </InputMask>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="cellphone"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>
                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {phone}
                                        </Form.Text>
                                    }
                                </Form.Group>
                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.personalData.whatsapp")}
                                    </Form.Label>
                                    {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="whatsapp"
                                                //rules={{ required: { value: true, message: 'Por favor, informe o whatsapp' } }}
                                                render={() => (
                                                    <InputMask mask={`(99) 99999-9999`} value={whatsapp} onChange={handleChangeWhatsapp} >
                                                        {(inputProps: any) => (
                                                            <Form.Control
                                                                {...inputProps}
                                                                variant="standard"
                                                                margin="normal"
                                                            //aria-invalid={errors?.cellphone ? "true" : ""}
                                                            />
                                                        )}
                                                    </InputMask>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="whatsapp"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>

                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {whatsapp || "Não informado"}
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <h4 className='mt-3 mb-3'>{t("profile.address.title")}</h4>

                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.address.postCode")}
                                    </Form.Label>
                                    {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="cep"
                                                defaultValue={cep}
                                                rules={{ required: { value: true, message: 'Por favor, informe o cep.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={cep}
                                                        onChange={handleChangeCEP}
                                                        onBlur={handleBlurCEP}
                                                        aria-invalid={errors?.cep ? "true" : ""}
                                                        onKeyPress={foreign === 2 ? (e: any) => !/[0-9]/.test(e.key) && e.preventDefault() : ""}
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="cep"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>

                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {cep}
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.address.state")}
                                    </Form.Label>
                                    {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="state"
                                                defaultValue={state}
                                                rules={{ required: { value: true, message: 'Por favor, informe o Estado.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={state}
                                                        onChange={handleChangeState}
                                                        aria-invalid={errors?.state ? "true" : ""}
                                                        disabled={disableState}
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="state"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>

                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {state}
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.address.city")}
                                    </Form.Label>
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="city"
                                                defaultValue={city}
                                                rules={{ required: { value: true, message: 'Por favor, informe a Cidade.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={city}
                                                        onChange={handleChangeCity}
                                                        aria-invalid={errors?.city ? "true" : ""}
                                                        disabled={disableCity}
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="city"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>

                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {city}
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.address.address")}
                                    </Form.Label>
                                    {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="address"
                                                defaultValue={address}
                                                rules={{ required: { value: true, message: 'Por favor, informe o Endereço.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={address}
                                                        onChange={handleChangeAddress}
                                                        aria-invalid={errors?.address ? "true" : ""}
                                                        disabled={disableAddress}
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="address"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>

                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {address}
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                    {t("profile.address.number")}:
                                    </Form.Label>
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="number"
                                                defaultValue={number}
                                                rules={{ required: { value: true, message: 'Por favor, informe o Número.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={number}
                                                        onChange={handleChangeNumber}
                                                        onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                        aria-invalid={errors?.number ? "true" : ""}
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="number"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>

                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {number}
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                    {t("profile.address.complement")}:
                                    </Form.Label>
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="complement"
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={complement}
                                                        onChange={handleChangeComplement}
                                                    //required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="complement"
                                                render={({ message }) => <div style={{ width: "100%", backgroundColor: "#f8d7da", padding: "0 0.75rem" }}><small style={{ color: "red" }}>{message}</small></div>}
                                            />
                                        </>

                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {complement || "Não informado"}
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group as={Col} md="4" className="mb-4" controlId="">
                                    <Form.Label>
                                        {t("profile.address.country")}
                                    </Form.Label>
                                    {/* <input type="text" id="cpf" value={cpf} onChange={handleChangeCPF} /> */}
                                    {editProfile === true ?
                                        <>
                                            <Controller
                                                control={control}
                                                name="country"
                                                defaultValue={country}
                                                rules={{ required: { value: true, message: 'Por favor, informe o País.' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        variant="standard"
                                                        margin="normal"
                                                        value={country}
                                                        onChange={handleChangeCountry}
                                                        aria-invalid={errors?.country ? "true" : ""}
                                                        required
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="country"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </>

                                        :
                                        <Form.Text className="text-muted text-user-info">
                                            {country}
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <h4 className='mt-3 mb-3'>{t("profile.infoLogin.title")}</h4>
                                <Form.Group as={Col} md="4" className="mb-0" controlId="">
                                    <Form.Label>
                                        {t("profile.infoLogin.email")}
                                    </Form.Label>
                                    <Form.Text className="text-muted text-user-info">
                                        {email}
                                    </Form.Text>
                                </Form.Group>

                                <h4 className='mt-3 mb-0'>Informações Adicionais</h4>

                                <ApprovedTerms
                                    control={control}
                                    setValue={setValue}
                                    setPrivacy={setPrivacy}
                                    privacy={privacy}
                                    errors={errors}
                                    editProfile={editProfile}
                                    aceptPrivacy={aceptPrivacy}
                                    aceptWpp={aceptWpp}
                                    profile={profile}
                                />

                                <h4 className='mt-3 mb-2'>{t("profile.password.title")}</h4>

                                <Link to="/nova-senha" className='w-auto' >
                                    {t("profile.password.changePassword")}
                                </Link>
                            </Row>

                            {editProfile === true ?
                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    <div className='w-auto px-0'>
                                        <Button type="button" className="form-button w-auto px-4 py-2 mr-3" variant="outline-primary" onClick={() => { setEditProfile(!editProfile); ScrollTop() }}>{t("profile.cancelBtn")}</Button>
                                    </div>

                                    <div className="modal-confirm" style={{ display: "contents" }}>
                                        <button type="submit" className="btn btn-primary">Editar Perfil</button>
                                    </div>

                                    {/*  <ModalDefault
                                        name={t("profile.editBtn")}
                                        header="no"
                                        class="modal-confirm "
                                        btnLeave="yes"
                                        modalContent={modalContent}
                                    >
                                        <div className='modal-body text-center'>
                                            {
                                                modalContent !== "loading"
                                                    ?
                                                    <>
                                                        <div>
                                                            {
                                                                log === 1 || log === "1"
                                                                    ?
                                                                    <FontAwesomeIcon
                                                                        icon={["fal", "times-circle"]}
                                                                        size="5x"
                                                                        style={{ fontSize: "7.5em" }}
                                                                    />
                                                                    :
                                                                    <FontAwesomeIcon
                                                                        icon={["fal", "check-circle"]}
                                                                        size="5x"
                                                                        style={{ fontSize: "7.5em" }}
                                                                    />
                                                            }
                                                        </div>
                                                        <div>
                                                            {sucess}
                                                        </div>
                                                        <div></div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="text-center">
                                                            <div className="load"></div>
                                                        </div>
                                                        <div>
                                                            <span>Processando</span>
                                                        </div>
                                                        <div></div>
                                                    </>
                                            }
                                        </div>
                                    </ModalDefault> */}
                                </Row>
                                :
                                <></>
                            }
                        </Form>
                    </Container>
                </div>

                <Modal
                    className="modal-custom modalAuth modal-confirm"
                    show={modalShow}
                    onHide={() => {
                        setModalShow(false)
                        Leave()
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body className='text-center'>
                            {
                                modalContent !== "loading"
                                    ?
                                    <>
                                        <div>
                                            {
                                                log === 1 || log === "1"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "times-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                            }
                                        </div>
                                        <div>
                                            {sucess}
                                        </div>
                                        <div></div>
                                    </>
                                    :
                                    <>
                                        <div className="text-center">
                                            <div className="load"></div>
                                        </div>
                                        <div>
                                            <span>Processando</span>
                                        </div>
                                        <div></div>
                                    </>
                            }
                        </Modal.Body>
                        {
                            modalContent !== "loading"
                                ?
                                <div className="btn-custom-modal d-flex justify-content-center">
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setModalShow(false)
                                            Leave()
                                            //props.setLoading(false)
                                        }}>
                                        OK
                                    </button>
                                </div>
                                :
                                ""
                        }
                    </>
                </Modal>
            </PageDafault>
        </>
    );
}

export default Profile;