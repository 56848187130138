import React from "react";

function WorkUsES() {
    return (
        <>
            ¿Sabías que el propósito del Parque Bondinho Pão de Açúcar es inspirar felicidad en todos los que se conectan con nosotros? <br />
            <br />
            Conozca un poco más sobre nosotros:<br />
            <br />
            Somos una empresa con el Sello GPTW (Great Place to Work). ¡Eso significa que estamos entre las mejores empresas para trabajar en Brasil!<br />
            <br />
            Más allá de un parque, somos una plataforma integrada de experiencias sostenibles en turismo, entretenimiento y medios.<br />
            <br />
            Aquí, tenemos el sentimiento de propiedad y estamos intensamente motivados y apasionados por encantar a las personas, inspirar sonrisas y superar las expectativas. Nuestra historia comenzó con una visión innovadora y es por eso que somos creativos por naturaleza y estamos siempre a la vanguardia en ingeniería y tecnología. También nos preocupamos constantemente por la seguridad y el bienestar de todos. Por eso, somos una empresa que piensa y promueve acciones sustentables para el desarrollo social, ambiental, cultural y económico. En nuestro día a día actuamos con integridad, con compromiso, respeto, honestidad, transparencia y ética. Además, estamos enfocados en resultados extraordinarios con metas claras.<br />
            <br />
            Diversidad: Tenemos vocación de acoger a todos con los brazos abiertos, respetando las individualidades. Privilegiamos lo diverso, y la inclusión es nuestra palabra clave. Buscamos talento, sin distinción de género, raza, credo o discapacidad, para todas nuestras oportunidades.<br />
            <br />
            Vea cuáles son nuestros beneficios:<br />

            • Vale de transporte;<br />

            • Restaurante en Morro da Urca con un menú variado, incluyendo opciones vegetarianas. ¡Sí, puedes tomar el teleférico para almorzar cuando estés allí!;<br />

            • Vale de alimentación;<br />

            • Seguro de vida prudencial;<br />

            • Asistencia médica SulAmérica, extendida al cónyuge e hijos;<br />

            • Asistencia Dental SulAmérica, también extendida al cónyuge e hijo;<br />

            • Programa de Participación en las Utilidades (PPR) de hasta 2 salarios - este bono está condicionado a la consecución de los objetivos de la empresa de acuerdo con la política vigente;<br />

            • Asistencia para el cuidado de niños;<br />

            • Reembolso de útiles escolares.<br />
            <br />
            Y aún hay más:<br />

            • Gympass;<br />

            • Día libre en el cumpleaños 🥳;<br />

            • 02 (dos) propinas por mes en el Parque Bondinho Pão de Açúcar 🧡;<br />

            • Descuentos en servicios y productos de empresas asociadas;<br />

            • La licencia de maternidad es de 180 días y la licencia de paternidad es de 20 días;<br />

            • Kit de maternidad y paternidad.<br />
            <br />
            Entonces, ¿quieres abordar con nosotros en este?<br />
            <br />
            <a href="https://www.linkedin.com/company/bondinhopaodeacucar/jobs/" target="__blank">¡Haga clic aquí para conocer nuestras oportunidades e inscribirse!</a>
        </>
    )
}

export default WorkUsES;