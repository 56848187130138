import React, { useEffect, useRef } from 'react';
import { useTranslation } from "react-i18next";

import { Link } from 'react-router-dom';

import './MenuSideBarCaracol.scss';

function MenuSideBarCaracol(props: any) {
  const [t] = useTranslation();

  useEffect(() => {
    if (props.open === true) {
      document.getElementById("menuSideBar")!.style.left = "0px";
      document.getElementById("shadow")!.style.display = "block";

    } else {

      document.getElementById("menuSideBar")!.style.left = "-350px";
      document.getElementById("shadow")!.style.display = "none";

    }
  }, [props.open])

  /* Detecta clique fora da div#wrapper para fechar o menuSideBar */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          props.setOpen(false);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /* //Detecta clique fora da div#wrapper para fechar o menuSideBar\\ */

  return (
    <>
      <div id="menuSideBar" className="hidden">
        <div
          data-v-403394da=""
          className="fixed" /* style="z-index: 3003;" */
        >
          <div>
            <div data-v-403394da="">
              <div
                id="wrapper"
                ref={wrapperRef}
                className="custom-scroll x-offset-canvas-menu relative bg-white z-3003 bottom-0 menu-drop" /* style="width: 350px;" */
              >
                <button
                  id="menu-close-button"
                  className="x-close-button absolute"
                  onClick={() => {
                    props.setOpen(false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15px"
                    height="15px"
                    viewBox="0 0 20.252 20.252"
                    fill="#333A41"
                  >
                    <path d="M11.769 10.126l8.142 8.142a1.162 1.162 0 11-1.643 1.644l-8.143-8.143-8.142 8.143A1.162 1.162 0 01.34 18.268l8.142-8.142L.34 1.984A1.162 1.162 0 011.983.34l8.142 8.143 1.643 1.643zm8.142-8.142s-4.642 4.666-6.5 6.5a1.162 1.162 0 11-1.644-1.644c2.027-2.035 6.5-6.5 6.5-6.5a1.162 1.162 0 011.643 1.643z"></path>
                  </svg>
                </button>
                <div className="menu-options-container">
                  <div className="menu-options">
                    <ul>
                      <li className="mb-3">
                        <Link to="/" className="">
                          <span>{t("navHeader.sideBar.home")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link to="/parque" className="">
                          <span>{t("navHeader.sideBar.thePark")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link to="/lojas" className="">
                          <span>{t("navHeader.sideBar.stores")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link to="/termos-de-uso" className="">
                          <span>{t("navHeader.sideBar.termsOfUse")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link to="/aviso-de-privacidade" className="">
                          <span>{t("navHeader.sideBar.privacyPolice")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link to="/termos-de-compra" className="">
                          <span>
                            {t("navHeader.sideBar.termsOfPurchase")}
                          </span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link to="/cookies" className="">
                          <span>{t("navHeader.sideBar.cookiesPolicy")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link to={{ pathname: "https://sac.parquecaracol.com.br/hc/pt-br" }} target="_blank" className="text-decoration-none" rel="noopener noreferrer">
                          <span>{t("navHeader.sideBar.talkWithUs")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link
                          to='/contato'
                          //target="_blank"
                          className=""
                        >
                          <span>{t("footer.menu.events")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link to="/regras-de-acesso" className="">
                          <span>{t("navHeader.sideBar.acessRules")}</span>
                        </Link>
                      </li>
                      <li className="mb-3">
                        <Link
                          to={{
                            pathname:
                              "https://grupoiter.gupy.io/",
                          }}
                          target="_blank"
                          className="text-decoration-none"
                          rel="noopener noreferrer"
                        >
                          <span>{t("navHeader.sideBar.workUs")}</span>
                        </Link>
                      </li>
                      {/* <li className="mb-3">
                        <Link
                          to={{ pathname: "https://novocaracoltainhas.sharepoint.com/:f:/s/ContratodeConcessoeanexos/EgeNMheevUNHny5Ob4vEnM4BO2ivB2ZWMu_bW9drfynnNg?e=tMXi88" }}
                          target="_blank"
                          className="text-decoration-none"
                          rel="noopener noreferrer"
                        >
                          <span>{t("navHeader.sideBar.judicialFiles")}</span>
                        </Link>
                      </li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div id="shadow"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MenuSideBarCaracol;