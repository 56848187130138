import React from 'react';

import './Checkout.scss'

import Helmet from 'react-helmet';
import i18next from "i18next";

import CheckoutSteps from '../../components/CheckoutSteps/CheckoutSteps';
import PageDafault from '../../templates/PageDafault';

function Checkout() {

  return (
    <div >
      {
        process.env.REACT_APP_CLIENTE_NAME === "Bondinho"
          ?
          <Helmet>
            <html lang={i18next.language} />
            <title>Compre seu ingresso agora para visitar o Bondinho Pão de Açúcar</title>
            <meta name="description" content="Compre seu ingresso para visitar o  Bondinho Pão de Açúcar e reserve sua data. Descontos para cariocas e  facilidade para pagamento" />
            <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

            {/* Essential META Tags */}
            <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
            <meta property="og:type" content="TouristAttraction" />
            <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
            <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

            {/* Non-Essential, But Recommended */}
            <meta property="og:description" content="Compre ingressos para visitar o Bondinho Pão de Açúcar no Rio de Janeiro e conheça a melhor vista do Brasil. Garanta seu ingresso fast pass ou Carioca maravilha com desconto." />
            <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

            {/* Non-Essential, But Required for Analytics */}
            {/* <meta property="fb:app_id" content="your_app_id" /> */}

            <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
            <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
          </Helmet>
          :
          <Helmet>
            <html lang={i18next.language} />
            <title>Compre seu ingresso agora para visitar o Parque Caracol</title>
            <meta name="description" content="Compre seu ingresso para visitar o  Parque Caracol e reserve sua data. Descontos para cariocas e  facilidade para pagamento" />
            <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

            {/* Essential META Tags */}
            <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
            <meta property="og:type" content="TouristAttraction" />
            <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
            <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

            {/* Non-Essential, But Recommended */}
            <meta property="og:description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

            {/* Non-Essential, But Required for Analytics */}
            {/* <meta property="fb:app_id" content="your_app_id" /> */}

            <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
            <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
          </Helmet>
      }

      <PageDafault>
        <div className="container-fluid p-0">
          <CheckoutSteps></CheckoutSteps>
        </div>
      </PageDafault>
    </div>
  );
}


export default Checkout;