import React from 'react';
import { useTranslation } from "react-i18next";

import Newsletter from '../../components/Newsletter/Newsletter';

import bgPark from '../../assets/img/parque_caracol/banner-home-caracol.png';
//import bgMapPark from '../../assets/img/bondinho-pao-de-acucar-background_mapas-6f0cf62.jpg';

import './ThePark.scss';
//import AreaPark from '../../components/Sliders/AreaPark/AreaPark';
//import MapPark from '../../components/Sliders/MapPark/MapPark';
//import GalleryPark from '../../components/Sliders/GalleryPark/GalleryPark';

import MailchimpSubscribe from "react-mailchimp-subscribe";
import PageDafault from '../../templates/PageDafault';
import i18next from 'i18next';

import TheParkPT from './components/TheParkPT';
import TheParkEN from './components/TheParkEN';
import TheParkES from './components/TheParkES';

function ThePark() {
    const [t] = useTranslation();
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : "BR";

    const postUrl = process.env.REACT_APP_MAILCHIMP;

    return (
        <>
            <PageDafault>

                <div className="top-park mb-3" style={{ backgroundImage: `url(${bgPark})` }}>
                    <div className='shadow-park h-100'>
                        <h1>{t("thePark.title")}</h1>
                    </div>
                </div>

                <div className='bg-fundo'>
                    { lng === "BR" ? <TheParkPT /> : lng === "EN" ? <TheParkEN /> : lng === "ES" ? <TheParkES /> : <TheParkPT /> }

                    <MailchimpSubscribe
                        url={`${postUrl}`}
                        render={({ subscribe, status, message }: any) => (
                            <Newsletter
                                status={status}
                                message={message}
                                onValidated={(formData: any) => subscribe(formData)}
                            />
                        )}
                    />
                </div>
            </PageDafault>
        </>
    )
}

export default ThePark;