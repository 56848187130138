import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Helmet from 'react-helmet';

import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./HomeCaracol.scss";

import ProductsCarrousel from "../../components/Products/ProductsCarrousel/ProductsCarrousel";

//import Partners from "../../components/Partners/Partners";
import Newsletter from "../../components/Newsletter/Newsletter";

//import Explore from "../../components/Explore/Explore";

import PageDafault from "../../templates/PageDafault";

import BannerHomeCaracol from "../../components/Banners/BannerHomeCaracol/BannerHomeCaracol";
import HowToGetCaracol from "../HowToGetCaracol/HowToGetCaracol";
import CaracolSlider from "../CaracolSlider/CaracolSlider";

const HomeCaracol = () => {
  const [t] = useTranslation();
  const postUrl = process.env.REACT_APP_MAILCHIMP;

  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      {
        process.env.REACT_APP_CLIENT_NAME === "Bondinho"
          ?
          <Helmet>
            <html lang={i18next.language} />
            <title>{process.env.REACT_APP_SERVER_NAME}</title>
            <meta name="description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

            {/* Essential META Tags */}
            <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
            <meta property="og:type" content="TouristAttraction" />
            <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
            <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

            {/* Non-Essential, But Recommended */}
            <meta property="og:description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

            {/* Non-Essential, But Required for Analytics */}
            {/* <meta property="fb:app_id" content="your_app_id" /> */}

            <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
            <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
          </Helmet>
          :
          <Helmet>
            <html lang={i18next.language} />
            <title>{process.env.REACT_APP_SERVER_NAME}</title>
            <meta name="description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

            {/* Essential META Tags */}
            <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
            <meta property="og:type" content="TouristAttraction" />
            <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
            <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

            {/* Non-Essential, But Recommended */}
            <meta property="og:description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

            {/* Non-Essential, But Required for Analytics */}
            {/* <meta property="fb:app_id" content="your_app_id" /> */}

            <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
            <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />
          </Helmet>
      }

      <PageDafault>
        <div className="content-header">
          <BannerHomeCaracol />
        </div>
        <div
          id="buy-product"
          className="container container-atividades my-outside pb-5"
        >
          <div data-category="listCategorie" className="mb-3">

            <h1 className="text-primary text-center my-inside" style={{ fontWeight: 600 }}>
              {
                i18next.language === "pt"
                  ?
                  'Parque do Caracol'
                  :
                  i18next.language === "en"
                    ?
                    'Parque do Caracol'
                    :
                    i18next.language === "es"
                      ?
                      'Parque do Caracol'
                      :
                      'Parque do Caracol'
              }
            </h1>
            <ProductsCarrousel codCategory={process.env.REACT_APP_CATEGORY?.split(",")}></ProductsCarrousel>
            <div className="bg-home-main-text">
              <p className="text-primary text-center mb-0">{t('home.down01')}</p>
              <p className="text-primary text-center mb-0">{t('home.down02')}</p>
            </div>
          </div>
        </div>

        <div className="container-md my-outside mb-5 home-video pt-0 pb-3">
          <iframe src="https://www.youtube.com/embed/xcXYwo07vxI?si=TGYOZw2QVdIk6_Yi&amp;controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
        </div>

        <div className="pt-2">
          <h2 className="title-our-park text-primary text-center mb-5 px-3">{t("home.titleLive")}</h2>
          <div className="container-md my-outside mb-5 home-video pt-3 pb-4">
            <iframe src="https://parquedocaracol.panomax.com/" className="real-time-video" allowFullScreen title="Video Real Time"></iframe>
          </div>
        </div>

        {/* <div className="container-md my-outside my-outside-our-park my-3 my-md-5">
          <ParkType /> */}
        {/* <iframe className="bg-blog" src="https://blog.bondinho.com.br/pagina-de-iframe/" title="Blog Bondinho" data-cookieconsent="ignore"></iframe> */}
        {/* </div> */}

        {/* <div className="bannerCallToAction my-outside">
        <Link to="#">
          <div className="bannerCall" style={{ background: `url(${process.env.REACT_APP_SERVER_BANNERCALL_TOP})` }}></div>
        </Link>
      </div>

        {/* <BannerCall /> */}
        <CaracolSlider />

        {/* HowToGet */}
        <HowToGetCaracol />
        {/* HowToGet */}

        {/*         <div className="container-md my-outside">
          <h2
            className="text-primary text-center my-inside text-uppercase"
          >
            {t("home.explore.title")}
          </h2>

          <Explore />
        </div> */}

        {/* <div className="container-fluid bg-secondary pb-5 px-0">
        <div className="container container-atividades container-tour">
          <div data-category="listCategorie">
            <h2 className="text-tour text-center pb-3 pt-5 py-md-5">{t('home.tours')}</h2>

            <ProductsCarrousel codCategory="CAT-QM34882"></ProductsCarrousel>
          </div>
        </div>
      </div> */}
        {/* <Partners /> */}
        <MailchimpSubscribe
          url={`${postUrl}`}
          render={({ subscribe, status, message }: any) => (
            <Newsletter
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )}
        />

        {/* <TopSellerTours />
      <Newsletter />*/}
      </PageDafault>
    </div>
  );
}

export default HomeCaracol;