import React from "react";

function LocationCaracolPT() {
    return (
        <>
            <div className="p-3">
                <div className="top mb-2">
                    <p>
                        O <b>Parque do Caracol</b> fica a aproximadamente 8,7 km do centro da cidade de Canela/RS. Basta seguir em direção a Gramado/RS, pegando a RS 466.
                    </p>
                    <p>
                        Estacionamento cobrado no local.
                    </p>
                </div>
            </div>
        </>
    )
}

export default LocationCaracolPT;