import i18next from 'i18next';
import React from 'react';

import { useTranslation } from "react-i18next";
import DiscountRulesCaracolEN from './components/DiscountRulesCaracolEN/DiscountRulesCaracolEN';
import DiscountRulesCaracolES from './components/DiscountRulesCaracolES/DiscountRulesCaracolES';
import DiscountRulesCaracolPT from './components/DiscountRulesCaracolPT/DiscountRulesCaracolPT';

export interface props {
    tourResponse?: any;
}

const DiscountRulesCaracol: React.FC<props> = ({ tourResponse }: props) => {
    const { t } = useTranslation();

    return (
        <>
            <p className="my-2">{t("tour.rules.all")}</p>

            {
                i18next.language === "pt"
                    ?
                    <DiscountRulesCaracolPT tourResponse={tourResponse} />
                    :
                    i18next.language === "en"
                        ?
                        <DiscountRulesCaracolEN  tourResponse={tourResponse} />
                        :
                        i18next.language === "es"
                            ?
                            <DiscountRulesCaracolES  tourResponse={tourResponse} />
                            :
                            <DiscountRulesCaracolPT  tourResponse={tourResponse} />
            }
        </>
    )
}

export default DiscountRulesCaracol;