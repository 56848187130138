import React from 'react';

import FooterCaracol from '../components/FooterCaracol/FooterCaracol';
import HeaderCaracol from '../components/HeaderCaracol/HeaderCaracol';

function PageDafault(props: any) {
  return (
    <>
      {!props.removeHeader && <HeaderCaracol />}
      {props.children}
      <FooterCaracol />
    </>
  );
}

export default PageDafault;
