/* eslint-disable @typescript-eslint/no-redeclare */
import React, { useState, useEffect } from 'react';
import { Tab, Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TagManager from 'react-gtm-module';

//Steps
import StepBook from './Steps/StepBook';
import StepData from './Steps/StepData';
//import StepPayment from './Steps/StepPayment';
import StepPayment from './Steps/StepPayment';
import { useAppSelector } from '../../store/hooks';

declare global {
    interface Window {
        cart?: any;
    }
}

const tempCart = localStorage.getItem('cartBPA') || '{}';
window.cart = JSON.parse(tempCart)

function CheckoutSteps() {
    const { t } = useTranslation();
    const [stepCtrl, setStepCtrl] = useState<any>({ key: 'stepBook', dataKey: 'disabled' });
    const cartItem: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    useEffect(() => {
        if (stepCtrl.key === 'stepBook') {
            var products: any = [];
            
            window.cart.dados.forEach((elem: any) => {
                products.push({
                    name: elem.productName,
                    id: elem.productCode,
                    price: elem.price,
                    brand: 'Bondinho',
                    category: '',
                    quantity: (Number(elem.adults) + Number(elem.childs) + Number(elem.elders) + Number(elem.infants) + Number(elem.student)),
                    variant: `Adultos - ${elem.adults} | Crianças - ${elem.childs} | Idosos - ${elem.elders} | Infantos - ${elem.infants} | Estudantes - ${elem.student}`
                })
            });
            
            TagManager.dataLayer({
                dataLayer: {
                    event: 'reserve',
                    ecommerce: {
                        checkout: {
                            actionField: { step: 1 },
                            products: products
                        }
                    },
                    event_category: 'Compra',
                    event_label: 'ingresso',
                    value: window.cart.totalCart
                }
            });
        } else if (stepCtrl.key === 'stepData') {
            var products: any = [];

            window.cart.dados.forEach((elem: any) => {
                products.push({
                    name: elem.productName,
                    id: elem.productCode,
                    price: elem.price,
                    brand: 'Bondinho',
                    category: '',
                    quantity: (Number(elem.adults) + Number(elem.childs) + Number(elem.elders) + Number(elem.infants) + Number(elem.student)),
                    variant: `Adultos - ${elem.adults} | Crianças - ${elem.childs} | Idosos - ${elem.elders} | Infantos - ${elem.infants} | Estudantes - ${elem.student}`
                })
            });

            TagManager.dataLayer({
                dataLayer: {
                    event: 'dados',
                    ecommerce: {
                        checkout: {
                            actionField: { step: 2 },
                            products: products
                        }
                    },
                    event_category: 'Compra',
                    event_label: 'ingresso',
                    value: window.cart.totalCart
                }
            });
        } else if (stepCtrl.key === 'stepPayment') {
            var products: any = [];

            window.cart.dados.forEach((elem: any) => {
                products.push({
                    name: elem.productName,
                    id: elem.productCode,
                    price: elem.price,
                    brand: 'Bondinho',
                    category: '',
                    quantity: (Number(elem.adults) + Number(elem.childs) + Number(elem.elders) + Number(elem.infants) + Number(elem.student)),
                    variant: `Adultos - ${elem.adults} | Crianças - ${elem.childs} | Idosos - ${elem.elders} | Infantos - ${elem.infants} | Estudantes - ${elem.student}`
                })
            });

            TagManager.dataLayer({
                dataLayer: {
                    event: 'pagamento',
                    ecommerce: {
                        checkout: {
                            actionField: { step: 3 },
                            products: products
                        }
                    },
                    event_category: 'Compra',
                    event_label: 'ingresso',
                    value: window.cart.totalCart
                }
            });
        }
    }, [stepCtrl.key])

    const handler = () => {
        window.scrollTo(0, 0);

        setStepCtrl({
            key: 'stepData',
            dataKey: 'enabled'
        });
    }

    const handlerData = () => {
        window.scrollTo(0, 0);

        setStepCtrl({
            key: 'stepPayment',
            dataKey: 'enabled'
        });
    }

    const handlerBook = () => {
        window.scrollTo(0, 0);
        setStepCtrl({
            key: 'stepBook',
            dataKey: 'enabled'
        });
    }

    
    useEffect(() => {
        if ((cartItem.totalCart === 0 || cartItem.totalCart === '0') && (stepCtrl.key === 'stepData' || stepCtrl.key === 'stepPayment')) {
            window.location.href = "/checkout";
        }
    }, [cartItem.totalCart, stepCtrl.key])

    return (
        <div id="checkoutNav" >
            <div id="div-pills">
                <Tab.Container id="left-tabs-example" activeKey={stepCtrl.key} onSelect={stepCtrl.key}>
                    <div className='bg-custom-primary'>
                        <Nav variant="pills" className="container pt-4 pt-md-2 gap-1" style={{ flexWrap: "inherit" }}>
                            <Nav.Item className='bg-primary'>
                                <Nav.Link eventKey="stepBook"><span className="btn btn-circle">1</span>  <span className="">  {t("checkoutSteps.step01")}</span></Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='bg-primary'>
                                <Nav.Link eventKey="stepData" disabled={stepCtrl.dataKey} ><span className="btn btn-circle ">2</span>  {t("checkoutSteps.step02")}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item className='bg-primary'>
                                <Nav.Link eventKey="stepPayment" disabled={stepCtrl.dataKey} ><span className="btn btn-circle ">3</span>  {t("checkoutSteps.step03")}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </div>
                    <div>
                        <Tab.Content className="container pb-5">
                            <Tab.Pane eventKey="stepBook">
                                <StepBook action={handler}></StepBook>
                            </Tab.Pane>
                            <Tab.Pane eventKey="stepData" >
                                <StepData action={handlerData} back={handlerBook}></StepData>
                            </Tab.Pane>
                            <Tab.Pane eventKey="stepPayment" >
                                <StepPayment back={handler}></StepPayment>
                            </Tab.Pane>
                        </Tab.Content>
                    </div>
                </Tab.Container>
            </div>
        </div>
    );
}


export default CheckoutSteps;