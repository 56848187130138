import React, { useEffect, useState } from 'react';

import api from "../../services/api";

import { Row, Col } from 'react-bootstrap';

import { Container } from "react-bootstrap";

import Accordion from 'react-bootstrap/Accordion'

import { useTranslation } from "react-i18next";

import Form from "react-bootstrap/Form";

import './DownloadCenter.scss';

//import object from './locales/downloads.json';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageDafault from '../../templates/PageDafault';

function DownloadCenter() {
    const [t] = useTranslation();
    const [list, setList] = useState<any>(null);
    //const [file, setFile] = useState<any>(null);
    const [select, setSelect] = useState<any>(0);

    useEffect(() => {
        async function getList() {
            try {
                const { data } = await api.get(
                    process.env.REACT_APP_CLIENT_NAME === "Bondinho"
                        ?
                        `${process.env.REACT_APP_SERVER_URL_API}/api/RIOneDrive/GetListFileAsync`
                        :
                        `${process.env.REACT_APP_SERVER_URL_API}/api/RI/GetListFileAsync`
                );

                if (data.statusCode !== 400) {
                    if (process.env.REACT_APP_CLIENT_NAME === "Bondinho") {
                        setList(data.data.nivel);
                    } else {
                        setList(data.data.nivel.nivel);
                    }
                    if (data.statusCode === 204) {
                        setList(null);
                    } else {
                        if (process.env.REACT_APP_CLIENT_NAME === "Bondinho") {
                            setList(data.data.nivel);
                        } else {
                            setList(data.data.nivel.nivel);
                        }
                    }
                }
            } catch (error) { }
        }
        getList();
    }, []);

    function getVoucher(idItem: any, itemName: any) {
        const getVou = async () => {
            try {
                const res = await api.get(
                    process.env.REACT_APP_CLIENT_NAME === "Bondinho"
                        ?
                        `${process.env.REACT_APP_SERVER_URL_API}/api/RIOneDrive/GetFileAsync/${idItem}`
                        :
                        `${process.env.REACT_APP_SERVER_URL_API}/api/RI/GetFileAsync/${idItem}`
                );
                if (res.data.statusCode !== 400) {
                    if (res.data.statusCode !== 204) {
                        const link = document.createElement('a');
                        link.href = `data:text/plain;base64, ${res.data.data}`;
                        link.setAttribute(
                            'download',
                            `${itemName}`,
                        );
                        // Append to html link element page
                        document.body.appendChild(link);

                        // Start download
                        link.click();

                        // // Clean up and remove the link
                        link?.parentNode?.removeChild(link);
                    } else {
                    }
                }
            } catch (error: any) {
            }
        }
        getVou();
    }

    function HandleSelect(e: any) {
        setSelect(e.target.value);
    }

    const convertDate = (date: string) => {
        let [data, hora] = date.split(' ');
        let [mes, dia, ano] = data.split('/');
        let newDate = `${dia}/${mes}/${ano}`;
        let newTimeDate = `${newDate} ${hora}`;
        return newTimeDate;
    }

    if (list !== null) {
        return (
            <>
                <PageDafault>
                    <Container className="download-center py-5">
                        <h1 className="mb-5 text-center display-2">{t("downloadCenter.title")}</h1>
                        <div className='bg-white pb-4'>
                            <div className='d-flex justify-content-center py-4'>
                                <Form.Select
                                    onChange={HandleSelect}
                                >
                                    {
                                        list.map((item: any, index: any) => {
                                            return (
                                                <>
                                                    <option value={index}>{item.name}</option>
                                                </>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </div>
                            {
                                list[select].nivel.map((trimester: any, index: any) => {
                                    return (
                                        <div key={index}>
                                            <Accordion className="p-3" defaultActiveKey="1">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>{trimester.name}</Accordion.Header>
                                                    <Accordion.Body className="px-0 py-0">
                                                        {
                                                            trimester.files.map((accordion: any, index: any) => {
                                                                return (
                                                                    <>
                                                                        <Row className="py-3 mx-0">
                                                                            <Col sm={10} className="col-10 px-4 my-auto">
                                                                                <Row>
                                                                                    <Col sm={4} md={3} lg={2} className="my-auto">
                                                                                        <span><b>{convertDate(accordion.date)}</b></span>
                                                                                    </Col>
                                                                                    <Col sm={8} md={7} lg={8} className="my-auto">
                                                                                        <a
                                                                                            href={`#${accordion.name.split(".")[0]}`}
                                                                                            onClick={() => { getVoucher(accordion.id, accordion.name) }}
                                                                                        >
                                                                                            {accordion.description}
                                                                                        </a>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                            <Col sm={2} className="col-2 d-flex justify-content-end align-items-center px-3 px-md-4">
                                                                                <Row>
                                                                                    <Col sm={12} md={12} lg={12} className="d-flex justify-content-end px-3">
                                                                                        <a
                                                                                            href={`#${accordion.name.split(".")[0]}`}
                                                                                            onClick={() => { getVoucher(accordion.id, accordion.name) }}
                                                                                        >
                                                                                            <FontAwesomeIcon
                                                                                                icon={["fal", "file-pdf"]}
                                                                                                size="2x"
                                                                                                style={{ color: "#FF6600", width: "20px" }}
                                                                                            />
                                                                                        </a>
                                                                                    </Col>
                                                                                </Row>
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Container>
                </PageDafault>
            </>
        );
    } else {
        return (
            <>
                <PageDafault>
                    <Container className="download-center py-5">
                        <h1 className="mb-5 text-center display-2">{t("downloadCenter.title")}</h1>
                        <div className="bg-white">
                            <div className='d-flex justify-content-center pt-4 pb-5'>
                                <div className="animated-background w-25" style={{ height: "42px" }}></div>
                            </div>
                            <div className="p-3">
                                <div className="animated-background mx-auto" style={{ width: "100%", height: "52px", borderRadius: "10px" }}></div>
                            </div>
                            <div className="p-3">
                                <div className="animated-background mx-auto" style={{ width: "100%", height: "52px", borderRadius: "10px" }}></div>
                            </div>
                        </div>
                    </Container>
                </PageDafault>
            </>
        )
    }
}

export default DownloadCenter;