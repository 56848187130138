import React from "react";

function LocationCaracolEN() {
    return (
        <>
            <div className="p-3">
                <div className="top mb-2">
                    <p>
                        <b>Caracol Park</b> is approximately 8,7 km from the center of Canela, in Rio Grande do Sul (RS). Just head towards Gramado, and drive by RS 466 Road.
                    </p>
                    <p>
                        Caracol Park.
                    </p>
                </div>
            </div>
        </>
    )
}

export default LocationCaracolEN;