import React from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import Helmet from 'react-helmet';

import MailchimpSubscribe from "react-mailchimp-subscribe";

import "./HomeTrilha.scss";

import BannerHome from "./components/BannerHome/BannerHome";

import ProductsCarrousel from "./components/ProductsCarrousel/ProductsCarrousel";

import Partners from "../../components/Partners/Partners";
import Newsletter from "../../components/Newsletter/Newsletter";

import Explore from "../../components/Explore/Explore";
import OurPark from "../../components/OurPark/OurPark";
import PageDafault from "../../templates/PageDafault";

export default function HomeTrilha() {
  const [t] = useTranslation();
  const postUrl = process.env.REACT_APP_MAILCHIMP;

  return (
    <div style={{ backgroundColor: "#EEEEEE" }}>
      {
        process.env.REACT_APP_CLIENT_NAME === "Bondinho"
          ?
          <Helmet>
            <html lang={i18next.language} />
            <title>{process.env.REACT_APP_SERVER_NAME}</title>
            <meta name="description" content="Compre ingressos para visitar o Bondinho Pão de Açúcar no Rio de Janeiro e conheça a melhor vista do Brasil. Garanta seu ingresso fast pass ou Carioca maravilha com desconto." />
            <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

            {/* Essential META Tags */}
            <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
            <meta property="og:type" content="TouristAttraction" />
            <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
            <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

            {/* Non-Essential, But Recommended */}
            <meta property="og:description" content="Compre ingressos para visitar o Bondinho Pão de Açúcar no Rio de Janeiro e conheça a melhor vista do Brasil. Garanta seu ingresso fast pass ou Carioca maravilha com desconto." />
            <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          :
          <Helmet>
            <html lang={i18next.language} />
            <title>{process.env.REACT_APP_SERVER_NAME}</title>
            <meta name="description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta name="googlebot" content={process.env.REACT_APP_SERVER_ROBOTS} />

            {/* Essential META Tags */}
            <meta property="og:title" content={process.env.REACT_APP_SERVER_NAME} />
            <meta property="og:type" content="TouristAttraction" />
            <meta property="og:image" content={`${process.env.REACT_APP_SERVER_URL}${process.env.REACT_APP_SERVER_LOGO}`} />
            <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

            {/* Non-Essential, But Recommended */}
            <meta property="og:description" content="Compre ingressos para visitar o Parque Caracol em Gramado e conheça a melhor vista do Brasil." />
            <meta property="og:site_name" content={process.env.REACT_APP_SERVER_NAME} />

            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
      }

      <PageDafault>
        <div className="content-header">
          <BannerHome />

        </div>
        <div
          id="buy-product"
          className="container container-atividades my-outside"
        //style={{ marginBottom: "80px" }}
        >
          <div data-category="listCategorie" className="">
            <h2 className="text-primary text-center my-inside">
              {t("home.activities")}
            </h2>
            <ProductsCarrousel codCategory={['CAT-SN09146']}></ProductsCarrousel>
          </div>
        </div>

        <div className="container-md my-outside my-outside-our-park">
          <h2
            className="title-our-park text-primary text-center text-uppercase"
          //style={{ marginTop: "80px" }}
          >
            {t("home.ourPark.title")}
          </h2>
          <p className="subtitle-our-park text-center mt-3 mb-5 mx-auto">{t("home.ourPark.subtitle")}</p>
          <OurPark />
        </div>

        {/* <div className="bannerCallToAction my-outside">
        <Link to="#">
          <div className="bannerCall" style={{ background: `url(${process.env.REACT_APP_SERVER_BANNERCALL_TOP})` }}></div>
        </Link>
      </div> */}

        {/* <BannerCall /> */}

        <div className="container-md my-outside">
          <h2
            className="text-primary text-center my-inside text-uppercase"
          //style={{ marginTop: "80px" }}
          >
            {t("home.explore.title")}
          </h2>

          <Explore />
        </div>

        {/* <div className="container-fluid bg-secondary pb-5 px-0">
        <div className="container container-atividades container-tour">
          <div data-category="listCategorie">
            <h2 className="text-tour text-center pb-3 pt-5 py-md-5">{t('home.tours')}</h2>

            <ProductsCarrousel codCategory="CAT-QM34882"></ProductsCarrousel>
          </div>
        </div>
      </div> */}
        <Partners />
        <MailchimpSubscribe
          url={`${postUrl}`}
          render={({ subscribe, status, message }: any) => (
            <Newsletter
              status={status}
              message={message}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )}
        />

        {/* <TopSellerTours />
      <Newsletter />*/}
      </PageDafault>
    </div>
  );
}
