import React from 'react';
import '../../App.css';
import { Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import './Explore.scss';

function Explore() {
    const [t] = useTranslation();

    return (
        <>
            <Row className='explore-section'>
                <Col md={12} className="flex-column">
                    <Row className="d-flex justify-content-center">
                        <Col sm={6} lg={4} className='explore-unit'>
                            <Link to={"/lojas"}>
                                <p className="gradient">
                                
                                    <img src={process.env.REACT_APP_SERVER_EXPLORE_01} alt="Experiências Ecolóficas" />
                                    {/* <div className="px-2"> */}
                                        <span className="gradient-text text-uppercase h5">{t("home.explore.store")} <span style={{ fontFamily: "Arial" }}>&</span> {t("home.explore.service")}</span>
                                    {/* </div>   */}                              
                                </p>
                            </Link>
                        </Col>
                        <Col sm={6} lg={4} className='explore-unit'>
                            <Link to={"/lojas"}>
                                <p className="gradient">
                                    <img src={process.env.REACT_APP_SERVER_EXPLORE_02} alt="Bilhetes" />
                                    {/* <div className="px-2"> */}
                                        <span className="gradient-text text-uppercase h5">{t("home.explore.food")}</span>
                                    {/* </div> */}
                                </p>
                            </Link>
                        </Col>
                        {/*                         <Col sm={6} lg={3} className='explore-unit'>
                            <p className="gradient">
                                <img src={process.env.REACT_APP_SERVER_EXPLORE_03} alt="Experiências Históricas" />
                                <span className="gradient-text h5">EXPERIÊNCIAS HISTÓRICAS</span>
                            </p>
                        </Col> */}
                        <Col sm={6} lg={4} className='explore-unit'>
                            <Link to={"/lojas"}>
                                <p className="gradient">
                                    <img src={process.env.REACT_APP_SERVER_EXPLORE_04} alt="Shows e Atrações" />
                                    {/* <div className="px-2"> */}
                                        <span className="gradient-text text-uppercase h5">{t("home.explore.events")}</span>
                                    {/* </div> */}
                                </p>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
}

export default Explore;