/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';

import api from '../../services/api';
import apiGoogleMaps from "../../services/apiGoogleMaps";
import configData from "../../config/config.json";

import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
//import Button from "@restart/ui/esm/Button";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ModalDefault from '../../components/Modal/ModalDefault/ModalDefault';

import { useTranslation } from "react-i18next";
import { ErrorMessage } from '@hookform/error-message';

import '../../App.css';

import './ProfileNew.scss';
//import Modal from "react-bootstrap/Modal";
import ModalPrivacy from '../../components/Modal/ModalPrivacy/ModalPrivacy';
import PrivacyTerms from '../../components/PrivacyTermsNotice/PrivacyTerms';
import TermsUse from '../../components/TermsUse/TermsUse';
import PageDafault from '../../templates/PageDafault';
import ReCAPTCHA from 'react-google-recaptcha';
import GetCountrys from '../../components/C2Points/GetCountrys';
import { Link } from 'react-router-dom';
import SingleCalendar from '../../components/Calendar/SingleCalendar';
import TermsOfUseCaracolPT from '../TermsOfUse/TermsOfUseCaracol/components/TermsOfUseCaracolPT/TermsOfUseCaracolPT';
import i18next from 'i18next';
import PrivacyNoticeCaracolPT from '../PrivacyNotice/PrivacyNoticeCaracol/components/PrivacyNoticeCaracolPT/PrivacyNoticeCaracolPT';
import ApprovedTerms from '../../components/AppovedTerms/ApprovedTerms';
//import SimpleAlert from '../../components/Alert/SimpleAlert/SimpleAlert';

function ProfileNew() {
    const { t } = useTranslation();
    const [sucess, setSucess] = useState<any>("Preencher informações");
    const [alertImg, setAlertImg] = useState<any>(false);
    const [log, setLog] = useState<any>(1);

    const [modalContent, setModalContent] = useState<any>();
    const [nacionalityBr, setNacionalityBr] = useState<any>("checked");

    const [profileImg, setProfileImg] = useState<any>(null);
    const [profileNameImg, setProfileNameImg] = useState<any>('None');
    const validated = false;

    const [cpfValidation, setCpfValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" do cpf
    const [invalidCpf, setInvalidCpf] = useState<boolean>(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState<boolean>(false);
    const [passwordValidation, setPasswordValidation] = useState<boolean>(false);   ///usado nos atributos "isValid" e "isInvald" dos inputs
    const [passwordSize, setPasswordSize] = useState<number>(0);   ///usado para verificar se o tamanho da senha digitada é 8
    const [passwordText, setPasswordText] = useState<any>('');  ///usado para a verificação do passord durante a digitação para trocar a cor do feedback quando a condição for atendida
    const [regexValid, setRegexValid] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    const [state, setState] = useState('');
    const [city, setCity] = useState('');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('BR');
    const [number, setNumber] = useState('');
    const [complement, setComplement] = useState('');

    const [disableState, setDisableState] = useState(false);
    const [disableCity, setDisableCity] = useState(false);
    const [disableAddress, setDisableAddress] = useState(false);

    const [isRecaptcha, setIsRecaptcha] = useState<any>(null);

    const [passwordStr, setPasswordStr] = useState<any>(0);
    const [passwordStrColor, setPasswordStrColor] = useState<any>('#ccc');
    const [passwordStrText, setPasswordStrText] = useState<any>('');
    const [passwordErrorBlur, setPasswordErrorBlur] = useState<any>(false);
    const [emailErrorBlur, setEmailErrorBlur] = useState<any>(false);

    const [privacy, setPrivacy] = useState<any>(false);

    const [dateBirth, setDateBirth] = useState<any>();

    var regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{}[\]|;:'",.<>/?])\S{8,}$/;
    var regexLetter = /^(?=.*[A-Za-z]{1})/;
    var regexNumber = /^(?=.*\d)/;
    var regexSymble = /^(?=.*[@$!%*#?&"'-/])/;

    function CpfValidator(strCPF: any) {
        if (strCPF !== null) {
            // eslint-disable-next-line no-useless-escape
            strCPF = strCPF.replace(/\./g, '').replace(/\-/g, '').replace(/\_/g, '');  //remove ".", "-" e "-" que a máscara coloca
            var Soma;
            var Resto;
            Soma = 0;

            if (strCPF === "00000000000") {
                setCpfValidation(false)
                return false;
            }

            for (var i: any = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto === 10) || (Resto === 11)) Resto = 0;
            if (Resto !== parseInt(strCPF.substring(9, 10))) {
                setCpfValidation(false)
                return false;
            }

            Soma = 0;
            for (i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
            Resto = (Soma * 10) % 11;

            if ((Resto === 10) || (Resto === 11)) Resto = 0;
            if (Resto !== parseInt(strCPF.substring(10, 11))) {
                setCpfValidation(false)
                return false;
            }

            setCpfValidation(true)
            return true;
        } else {
            setCpfValidation(false)
        }
    }

    const showNacionality = (e: any) => {

        if (nacionalityBr === false) {
            setCpfValidation(false)
            setNacionalityBr("checked");
        } else {
            setCpfValidation(true)
            setNacionalityBr(false);
        }
    };

    function Confirmation() {
        var password = (document.getElementById('password') as HTMLInputElement).value;
        var passwordConfirmation = (document.getElementById('confirmPassword') as HTMLInputElement).value;

        if (passwordConfirmation !== password) {
            setPasswordConfirmation(false);
        }
        else {
            setPasswordConfirmation(true);
        }

        return true;
    }

    function Validation() {
        var strength: any = 0;
        var password = (document.getElementById('password') as HTMLInputElement).value;
        setPasswordSize(password.length);
        setPasswordText(password);

        if (regexNumber.exec(password)) {
            strength += 1;
        }

        if (regexSymble.exec(password)) {
            strength += 1;
        }

        if (regexLetter.exec(password)) {
            strength += 1;
        }

        if (!regex.exec(password)) {
            setPasswordValidation(false);
        }
        else {
            strength = 4;
            setPasswordValidation(true);
        }

        if (strength === 0) {
            setPasswordStrColor('#ccc');
            setPasswordStrText('');
        } else if (strength === 1) {
            setPasswordStrColor('red');
            setPasswordStrText(t("profileNew.weak"));
        } else if (strength === 2 || strength === 3) {
            setPasswordStrColor('#e0e00d');
            setPasswordStrText(t("profileNew.medium"));
        } else {
            setPasswordStrColor('green');
            setPasswordStrText(t("profileNew.strong"));
        }

        setPasswordStr(strength);

        return true;
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const imageHandler = (e: any) => {
        if (e.target.files[0].size <= 2097152) { //2MB -> 2097152
            const reader = new FileReader();
            reader.onload = () => {
                if (reader.readyState === 2) {
                    setProfileImg(reader.result)
                }
            }
            reader.readAsDataURL(e.target.files[0]);
            setProfileNameImg(e.target.files[0].name);
        } else {
            setAlertImg(true);
        }
    }

    const {
        control,
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
    } = useForm();

    const onSubmit = (objData: any) => {
        objData.address = address;
        objData.city = city;
        objData.state = state;
        objData.number = number;
        objData.complement = complement;
        objData.date = dateBirth?.split("/").reverse().join("-");

        if (objData.email === objData.confirmEmail /* && objData.date !== undefined */) {
            /* if (objData.cep === '' || objData.state === '' || objData.city === '' || objData.address === '') {
                setSucess('Favor preencher o endereço');
            } else { */

            if (nacionalityBr !== "checked") {
                setCpfValidation(true);
            }

            if (cpfValidation === true) {

                if (nacionalityBr === "checked") {
                    objData.BR = true;
                    objData.EST = false;
                    objData.passport = null;
                } else {
                    objData.BR = false;
                    objData.EST = true;
                    objData.cpf = null;
                }

                if (isRecaptcha !== null) {
                    setInvalidCpf(false);

                    setModalContent('loading');
                    const getAuthenticateAccount = async () => {
                        try {
                            const { data } = await api.post('api/LoginSite/AccountCreate', { ///LOGIN API
                                "firstName": objData.firstName,
                                "lastName": objData.lastName,
                                "cpf": objData.cpf === null ? objData.cpf : objData.cpf.split('.').join('').split('-').join(''),
                                "email": objData.email,
                                "cellphone": objData.cellphone,
                                "password": objData.password,
                                "Whatsapp": objData.whatsapp,
                                "photo": profileImg,
                                "origem": 0,
                                "foreign": nacionalityBr === "checked" ? 2 : 1,
                                "passport": objData.passport === null ? objData.passport : objData.passport.split('.').join('').split('-').join(''),
                                "userComplement": {
                                    "address": objData.address,
                                    "postCode": objData.cep,
                                    "city": objData.city,
                                    "state": objData.state,
                                    "country": nacionalityBr === "checked" ? 'BR' : country,
                                    "complement": objData.complement === "" ? null : objData.complement,
                                    "number": objData.number
                                },
                                "aceitePolitica": getValues('checkPolice'),
                                "aceiteComunicacao": getValues('checkNew') === undefined ? false : getValues('checkNew')
                            });
                            if (data.statusCode !== 400) {
                                if (data.data.log === 1) {
                                    setSucess(data.data.texto);
                                    setModalContent('error');
                                    setLog(data.data.log);
                                } else {
                                    setSucess(data.data);
                                    setLog(data.data.log)
                                    setModalContent('sucesso');
                                    
                                    if (data.data === "Já existe cadastro com essas credenciais") {
                                        setLoading(false);
                                        setIsRecaptcha(null);
                                    }
                                }
                            } else {
                            }

                        } catch (error: any) {
                            setModalContent('Erro');
                            setSucess("Error");
                            setLog(1);
                            setLoading(false);
                            if (error?.response?.status === 400) {
                                setModalContent('error');
                                setLog(1);
                                setSucess("Error");
                            }
                        }
                    }
                    if (passwordConfirmation === true) {
                        if (passwordSize >= 8 && passwordValidation === true) {
                            setLoading(true)
                            setRegexValid(false);
                            getAuthenticateAccount();
                        } else {
                            setRegexValid(true);
                            setSucess(t("profileNew.alert01"));
                            setModalContent('error');
                            setLog(1);
                        }
                    } else {
                        setSucess(t("profileNew.alert02"));
                        setModalContent('error');
                        setLog(1);
                    }

                } else {
                    setSucess(t("profileNew.alert06"));
                    setModalContent('error');
                    setLog(1);
                }
            } else {
                setInvalidCpf(true);
                setSucess(t("profileNew.alert03"));
                setModalContent('sucesso');
            }
            /* } */
        } else {
            setLoading(true);

            setTimeout(() => {
                setLoading(false);
            }, 250);

            setIsRecaptcha(null);

            setSucess(t("profileNew.alert08"));
            setLog(1);
            setModalContent('error');
        }
    };

    useEffect(() => {
        if (errors?.checkPolice !== undefined) {
            setSucess("Preencher informações");
            setModalContent('error');
            setLog(1);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errors.checkPolice])

    const searchCEP = async (term: any) => {
        setDisableState(false);
        setDisableCity(false);
        setDisableAddress(false);

        setValue("address", "");
        setAddress("");

        setValue("city", "");
        setCity("");

        setValue("state", "");
        setState("");

        const response = await apiGoogleMaps.get("/geocode/json", {
            params: {
                address: term,
                key: configData.GOOGLE_KEYS.GOOGLE_MAPS,
            },
        });

        if (response.data.status === "OK") {
            response.data.results[0].address_components.forEach(function (place: any) {
                switch (place.types[0]) {
                    case "route":
                        setValue("address", place.long_name);
                        setAddress(place.long_name);
                        (
                            document.getElementById(
                                "FormControlInput1Address"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Address"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        setDisableAddress(true);
                        break;

                    case "administrative_area_level_2":
                        setValue("city", place.long_name);
                        setCity(place.long_name);
                        (
                            document.getElementById(
                                "FormControlInput1Cidade"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Cidade"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        setDisableCity(true);
                        break;

                    case "locality":
                        (
                            document.getElementById(
                                "FormControlInput1Cidade"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Cidade"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        break;

                    case "administrative_area_level_1":
                        setValue("state", place.long_name);
                        setState(place.long_name);
                        (
                            document.getElementById(
                                "FormControlInput1Estado"
                            ) as HTMLInputElement
                        ).value = place.long_name;
                        (
                            document.querySelector(
                                '[for="FormControlInput1Estado"]'
                            ) as HTMLElement
                        ).classList.add("label-active");
                        setDisableState(true);
                        break;

                    default:
                }
            });
        } else {
            setDisableState(false);
            setDisableCity(false);
            setDisableAddress(false);
        }
    };

    const handleBlurCEP = (event: any) => {
        searchCEP(event.target.value);
    };

    const verifyBlurPassword = () => {
        var password = (document.getElementById('password') as HTMLInputElement).value;
        var passwordConfirmation = (document.getElementById('confirmPassword') as HTMLInputElement).value;

        if (passwordConfirmation !== password && passwordConfirmation !== '') {
            setPasswordErrorBlur(true);
        } else {
            setPasswordErrorBlur(false);
        }
    };

    const verifyBlurEmail = () => {
        var email = (document.getElementById('email') as HTMLInputElement).value;
        var emailConfirmation = (document.getElementById('confirmEmail') as HTMLInputElement).value;

        if (emailConfirmation !== email && emailConfirmation !== '') {
            setEmailErrorBlur(true);
        } else {
            setEmailErrorBlur(false);
        }
    };

    const BrChecked = () => {
        return (
            <>
                <Form.Group as={Col} md="6" data-nacion="cpf" controlId="formGridPassword" className="mb-3">
                    <Form.Label>
                        CPF*
                    </Form.Label>
                    <Controller
                        control={control}
                        name="cpf"
                        rules={{ required: { value: true, message: t("profileNew.errorCPF") } }}
                        render={({ field }: any) => (
                            <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange} onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()} onInput={(e: any) => { CpfValidator(e.target.value) }}>
                                {(inputProps: any) => (
                                    <Form.Control
                                        {...inputProps}
                                        aria-invalid={errors?.cpf ? "true" : ""}
                                        label="CPF"
                                        variant="standard"
                                        margin="normal"
                                        type="text"
                                        required
                                    />
                                )}
                            </InputMask>
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="cpf"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                    {invalidCpf === true ? <small style={{ color: "red" }}>{t("profileNew.alert05")}</small> : ''}
                </Form.Group>
            </>
        )
    }

    const EstChecked = () => {
        return (
            <>
                <Form.Group as={Col} md="6" controlId="formGridPassword" className="mb-3 mt-md-0">
                    <Form.Label>
                        {t("checkoutSteps.pass")}*
                    </Form.Label>
                    <Controller
                        control={control}
                        name="passport"
                        rules={{ required: { value: true, message: t("profileNew.errorPass") } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                {...register("passport")}
                                aria-invalid={errors?.passport ? "true" : ""}
                                type="text"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="passport"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
            </>
        )
    }

    function handleRacaptcha(value: any) {
        setIsRecaptcha(value);
    }

    return (
        <>
            <PageDafault>
                <div className='container'>

                    <Container className="profile-container" fluid>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Row className='mb-4'>
                                <div>
                                    <h1 className="display-5 mb-4">{t("profileNew.title")}</h1>
                                </div>
                            </Row>

                            <div className='d-flex justify-content-end mb-4'>
                                <small style={{ color: "red" }}>* {t("profileNew.requiredFields")}</small>
                            </div>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="6" className="mb-3" controlId="">
                                    <Form.Label>
                                        {t("profileNew.name")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="firstName"
                                        rules={{ required: { value: true, message: t("profileNew.errorName") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="firstName"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3" controlId="">
                                    <Form.Label>
                                        {t("profileNew.lastname")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="lastName"
                                        rules={{ required: { value: true, message: t("profileNew.errorLastName") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="lastName"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" className="mb-3" controlId="email">
                                    <Form.Label>
                                        {t("profileNew.email")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="email"
                                        rules={{ required: { value: true, message: t("profileNew.errorEmail") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="email"
                                                variant="standard"
                                                margin="normal"
                                                onInput={verifyBlurEmail}
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3" controlId="confirmEmail">
                                    <Form.Label>
                                        {t("profileNew.confirmEmail")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="confirmEmail"
                                        rules={{ required: { value: true, message: t("profileNew.confrimErrorEmail") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="email"
                                                variant="standard"
                                                margin="normal"
                                                onPaste={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                                onCopy={(e) => {
                                                    e.preventDefault()
                                                    return false;
                                                }}
                                                onInput={verifyBlurEmail}
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="confirmEmail"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                    {emailErrorBlur === true ? <small style={{ color: "red" }}>{t("profileNew.alert08")}</small> : <></>}
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="my-3 m-md-auto">
                                    <Row>
                                        <Form.Group as={Col} controlId="BR">
                                            <label htmlFor="radio1">
                                                <Form.Group>
                                                    <Controller
                                                        control={control}
                                                        name="BR"
                                                        render={({ field }: any) => (
                                                            <Form.Check
                                                                {...field}
                                                                {...register("BR")}
                                                                type="radio"
                                                                id="radio1"
                                                                name="radio"
                                                                label={t("checkoutSteps.br")}
                                                                value="1"
                                                                defaultChecked={true}
                                                                onChange={showNacionality}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </Form.Group>
                                            </label>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="EST">
                                            <label htmlFor="radio2">
                                                <Form.Group>
                                                    <Controller
                                                        control={control}
                                                        name="EST"
                                                        render={({ field }: any) => (
                                                            <Form.Check
                                                                {...field}
                                                                {...register("EST")}
                                                                type="radio"
                                                                id="radio2"
                                                                name="radio"
                                                                label={t("checkoutSteps.est")}
                                                                value="2"
                                                                onChange={showNacionality}
                                                                required
                                                            />
                                                        )}
                                                    />
                                                </Form.Group>
                                            </label>
                                        </Form.Group>
                                    </Row>
                                </Form.Group>

                                {nacionalityBr === "checked" ?
                                    <BrChecked />
                                    :
                                    <EstChecked />
                                }
                                <Form.Group as={Col} md="6" className="mb-3" controlId="">
                                    <Form.Label>
                                        {t("profileNew.phone")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="cellphone"
                                        rules={{ required: { value: true, message: t("profileNew.errorPhone") } }}
                                        render={({ field }: any) => (
                                            <InputMask mask={`(99) 9.9999-9999`} value={field?.value} onChange={field?.onChange} >
                                                {(inputProps: any) => (
                                                    <Form.Control
                                                        {...inputProps}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='new-password'
                                                        required
                                                    />
                                                )}
                                            </InputMask>

                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="cellphone"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3" controlId="">
                                    <Form.Label>
                                        {t("profileNew.wpp")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="whatsapp"
                                        //rules={{ required: { value: true, message: t("profileNew.errorWpp") } }}
                                        render={({ field }: any) => (
                                            <InputMask mask={`(99) 9.9999-9999`} value={field?.value} onChange={field?.onChange} >
                                                {(inputProps: any) => (
                                                    <Form.Control
                                                        {...inputProps}
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='new-password'
                                                        required
                                                    />
                                                )}
                                            </InputMask>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="whatsapp"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3" controlId="password">
                                    <Form.Label>
                                        {t("profileNew.password")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="password"
                                        rules={{ required: { value: true, message: t("profileNew.errorPassword") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="password"
                                                variant="standard"
                                                margin="normal"
                                                onInput={() => { Validation(); Confirmation(); verifyBlurPassword() }}
                                                maxLength={20}
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                    <div className="password-strength-container row d-flex justify-content-evenly">
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 1 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 2 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 3 ? passwordStrColor : ''}` }}></div>
                                        <div className="password-strength col-3" style={{ borderColor: `${passwordStr >= 4 ? passwordStrColor : ''}` }}></div>
                                    </div>
                                    <div className="d-flex justify-content-center" style={{ color: `${passwordStrColor}` }}>
                                        {passwordStrText}
                                    </div>
                                </Form.Group>
                                <Form.Group as={Col} md="6" className="mb-3" controlId="confirmPassword">
                                    <Form.Label>
                                        {t("profileNew.confirmPass")}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="confirmPassword"
                                        rules={{ required: { value: true, message: t("profileNew.errorConfirm") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="password"
                                                variant="standard"
                                                margin="normal"
                                                defaultValue=""
                                                onInput={() => { Confirmation(); verifyBlurPassword() }}
                                                maxLength={20}
                                                autoComplete='new-password'
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="confirmPassword"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                    {passwordErrorBlur === true ? <small style={{ color: "red" }}>{t("profileNew.alert02")}</small> : <></>}
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="">
                                    <small style={{ color: regexValid === true ? "red" : "black", fontSize: ".8rem", opacity: ".7" }}>{t("profileNew.textPass")}</small>
                                </Form.Group>
                            </Row>
                            <hr />

                            <div className="my-4">
                                <h5 className="mb-4">{t("profileNew.address")}</h5>
                                <Row className="mb-4">
                                    <Form.Group as={Col}>
                                        <Form.Label>
                                            {t("profileNew.cep")}*
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="cep"
                                            rules={{ required: { value: true, message: 'Por favor, informe o cep.' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    {...register("cep")}
                                                    onBlur={handleBlurCEP}
                                                    type="text"
                                                    onKeyPress={nacionalityBr === 'checked' ? (e: any) => !/[0-9]/.test(e.key) && e.preventDefault() : ""}
                                                    autoComplete="new-password"
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cep"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} htmlFor="FormControlInput1Estado">
                                        <Form.Label>
                                            {t("profileNew.state")}*
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="state"
                                            rules={{ required: { value: true, message: 'Por favor, informe o Estado.' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    {...register("state")}
                                                    id="FormControlInput1Estado"
                                                    type="text"
                                                    value={state}
                                                    onChange={(e: any) => { setState(e.target.value) }}
                                                    aria-invalid={errors?.state ? "true" : ""}
                                                    disabled={disableState}
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="state"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className="mb-4" htmlFor="FormControlInput1Cidade">
                                        <Form.Label>
                                            {t("profileNew.city")}*
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="city"
                                            rules={{ required: { value: true, message: 'Por favor, informe a Cidade.' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    {...register("city")}
                                                    id="FormControlInput1Cidade"
                                                    autoComplete="new-password"
                                                    value={city}
                                                    onChange={(e: any) => { setCity(e.target.value) }}
                                                    aria-invalid={errors?.city ? "true" : ""}
                                                    disabled={disableCity}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="city"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-4" htmlFor="FormControlInput1Address">
                                        <Form.Label>
                                            {t("profileNew.address")}:*
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="address"
                                            rules={{ required: { value: true, message: 'Por favor, informe o Endereço.' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    {...register("address")}
                                                    id="FormControlInput1Address"
                                                    value={address}
                                                    onChange={(e: any) => { setAddress(e.target.value) }}
                                                    disabled={disableAddress}
                                                    autoComplete="new-password"
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="address"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row>
                                    <Form.Group as={Col} className="mb-4" htmlFor="FormControlInput1Number">
                                        <Form.Label>
                                            {t("profileNew.number")}:*
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="number"
                                            rules={{ required: { value: true, message: 'Por favor, informe o Número.' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    {...register("number")}
                                                    id="FormControlInput1Number"
                                                    autoComplete="new-password"
                                                    value={number}
                                                    onKeyPress={(e: any) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    onChange={(e: any) => { setNumber(e.target.value) }}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="number"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} className="mb-4" htmlFor="FormControlInput1Complement">
                                        <Form.Label>
                                            {t("profileNew.complement")}:*
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="complement"
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    {...register("complement")}
                                                    id="FormControlInput1Complement"
                                                    value={complement}
                                                    onChange={(e: any) => { setComplement(e.target.value) }}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Row>

                                {nacionalityBr !== 'checked'
                                    ?
                                    <Row>
                                        <Form.Group as={Col} md="6" htmlFor="FormControlInput1Country" className='mb-3'>
                                            <Form.Label>
                                                {t("profileNew.country")}
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="country"
                                                render={({ field }: any) => (
                                                    <GetCountrys propsField={field} propsLabel={t("checkoutSteps.nacionality")} propsErrors={errors} getTheValue={true} defaultCountry={'BR'} setCountry={setCountry} />
                                                )}
                                            />
                                        </Form.Group>
                                    </Row>
                                    :
                                    <>
                                    </>
                                }

                                {/* <ApprovedTerms
                                    control={control}
                                    setValue={setValue}
                                    privacy={privacy}
                                    setPrivacy={setPrivacy}
                                    errors={errors}
                                /> */}

                            </div>

                            <div className="d-flex flex-column align-items-end">
                                <div className="mb-3 bg-profile-recaptcha">
                                    {
                                        loading === false
                                            ?
                                            <ReCAPTCHA
                                                sitekey={`${process.env.REACT_APP_SERVER_SITEKEY}`}
                                                //sitekey="6LcPv5ogAAAAALl93I2Mlj0robC4vgG8jV72abwP" //Key Test Matheus
                                                // sitekey="6LcEIb4UAAAAAJP0ES_uICrmE4jYnBESJjmK-OQw" //Key C2
                                                onChange={handleRacaptcha}
                                            />
                                            :
                                            <></>
                                    }
                                </div>
                            </div>

                            <Row className="d-flex justify-content-end mx-0 mb-4">

                                <ModalDefault
                                    name={`${t("profileNew.btn")}`}
                                    header="no"
                                    class="modal-confirm"
                                    btnLeave="yes"
                                    modalContent={modalContent}
                                    sucess={sucess}
                                >
                                    <div className='modal-body text-center'>
                                        {
                                            modalContent !== "loading"
                                                ?
                                                <>
                                                    <div>
                                                        {
                                                            log === 1 || log === "1"
                                                                ?
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "times-circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: "7.5em" }}
                                                                />
                                                                :
                                                                <FontAwesomeIcon
                                                                    icon={["fal", "check-circle"]}
                                                                    size="5x"
                                                                    style={{ fontSize: "7.5em" }}
                                                                />
                                                        }
                                                    </div>
                                                    <div>
                                                        {sucess}
                                                    </div>
                                                    <div></div>
                                                </>
                                                :
                                                <>
                                                    <div className="text-center">
                                                        <div className="load"></div>
                                                    </div>
                                                    <div>
                                                        <span>{t("profileNew.loading")}</span>
                                                    </div>
                                                    <div></div>
                                                </>
                                        }
                                    </div>
                                </ModalDefault>

                                <div className='d-flex justify-content-end mt-3 px-0'>
                                    {t("profileNew.readyRegistration")} <Link to="/login" className='ml-2'>{t("profileNew.enter")}</Link>
                                </div>
                                {/* <Button type="submit" className="form-button w-auto px-4 py-2" >{loading === true ? <div className="load small white"></div> : "Cadastrar"}</Button> */}
                            </Row>
                        </Form>
                    </Container>
                </div>
            </PageDafault>
        </>
    );
}

export default ProfileNew;