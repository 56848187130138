import React from 'react';

function RegulationES() {
    return (
        <>
            {/* <h1 className="mb-5 text-center display-2">Reglamento de la Promoción “Black Friday <br /> Parque Bondinho Pão de Açúcar® 2022”</h1> */}

            <div className="card py-4 px-3">
                <p>
                    1) La Promoción “Black Friday Parque Bondinho Pão de Açúcar® 2022” es válida para compras realizadas exclusivamente a través de nuestro sitio oficial (<a href='/'>www.parquebondinho.com.br</a>) desde el 23 de noviembre de 2022 hasta el 05 de diciembre de 2022, o hasta agotar existencias.
                </p>

                <p>
                    2) Los boletos de la promoción Black Friday Parque Bondinho son válidos para visitar desde el 23 de noviembre de 2022 hasta el 16 de diciembre de 2022.
                </p>

                <p>
                    3) La Promoción es válida para todos los visitantes que compren el producto: Entradas al Pan de Azúcar Black Friday y Billete Carioca Maravilha Black Friday. Limitado a 12 entradas por inicio de sesión.
                </p>

                <p>
                    4) El valor del Entradas al Pan de Azúcar Black Friday es de R$99,90 y el valor del Billete Carioca Maravilha Black Friday es de R$49,90.
                </p>

                <p>
                    5) Los descuentos promocionales ya se aplican sobre el valor del producto, no siendo acumulables con ninguna otra promoción vigente ni media entrada ya existentes.
                </p>

                <p>
                    6) El valor promocional no es válido para Billete Acceso Rápido o Tours (Paseo Histórico, Ecológico y Bastidores)
                </p>

                <p>
                    7) El descuento es válido solo para el paseo completo (4 tramos).
                </p>

                <p>
                    8) La promoción es válida para todas las visitantes, a condición que se respeten los demás prerrequisitos dispuestos en este reglamento.
                </p>

                <p>
                    9) La empresa se reserva el derecho de suspender las promociones en los días de evento en el parque, en caso de entradas agotadas y por motivo de mantenimiento o fuerza mayor.
                </p>

                <p>
                    10) El Parque Bondinho Pão de Açúcar se compromete a cumplir las leyes, reglas y reglamentos aplicables a los datos personales tratados debido a esta campaña “Black Friday Parque Bondinho Pão de Açúcar® 2022”, incluyendo, sin limitación, la Ley n.º 13.709/2018 (Ley General de Protección de Datos Personales).
                </p>
            </div>
        </>
    )
}

export default RegulationES;