import React from "react";

import { Row, Col } from "react-bootstrap";

import "./OurPark.scss"

function OurPark() {
    return (
        <>
            <Row className='our-park d-flex justify-content-center'>
                <Col md={9} className="flex-column">
                    <div className="embed-responsive embed-responsive-16by9">
                    <iframe src="https://rio.panomax.com/sugarloaf" className="real-time-video" allowFullScreen title="Video Real Time"></iframe>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default OurPark;