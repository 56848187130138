import React from 'react';

import i18next from 'i18next';

import { useTranslation } from "react-i18next";
import PageDafault from '../../../templates/PageDafault';

import TermsOfPurchaseCaracolPT from './components/TermsOfPurchaseCaracolPT/TermsOfPurchaseCaracolPT';
import TermsOfPurchaseCaracolEN from './components/TermsOfPurchaseCaracolEN/TermsOfPurchaseCaracolEN';
import TermsOfPurchaseCaracolES from './components/TermsOfPurchaseCaracolES/TermsOfPurchaseCaracolES';

import '../TermsOfPurchase.scss';

function TermsOfPurchaseCaracol() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = useTranslation();

    return (
        <>
            <PageDafault>
                <div className="container py-5">
                    <h1 className="mb-5 text-center display-2">{t('termsOfPurchase.title')}</h1>
                    <div className="card py-4 px-3">
                        {
                            i18next.language === "pt"
                                ?
                                <TermsOfPurchaseCaracolPT />
                                :
                                i18next.language === "en"
                                    ?
                                    <TermsOfPurchaseCaracolEN />
                                    :
                                    i18next.language === "es"
                                        ?
                                        <TermsOfPurchaseCaracolES />
                                        :
                                        <TermsOfPurchaseCaracolPT />
                        }
                    </div>
                </div>
            </PageDafault>
        </>
    );
}

export default TermsOfPurchaseCaracol;