import React from "react";

import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export interface propAction {
    transfers: any;
}

const CheckoutCardTransfer: React.FC<propAction> = ({
    transfers,
}: propAction) => {
    const { t } = useTranslation();
    let totalCart = 0;
    return (
        <div>
            {Array.from(transfers).length > 0
                ? Array.from(transfers).map((transfer: any, index) => {
                    totalCart = Number(totalCart) + (
                        (Number(transfer.adults) * Number(transfer.priceAdults))
                        + (Number(transfer.childs) * Number(transfer.priceChilds))
                        + (Number(transfer.infants) * Number(transfer.priceInfants))
                        + (Number(transfer.elders) * Number(transfer.priceElders))
                        + (Number(transfer.student) * Number(transfer.priceStudent))
                        + (Number(transfer.priceGlobalPeople))
                    );
                    return (
                        <div
                            key={index}
                            className="card my-4 rounded"
                            data-product="product"
                            id={transfer.prodCodigo}
                        >
                            <div className="row p-3 pb-0">
                                <div className="col-12 col-md-3">
                                    <div className="">
                                        <img
                                            src={process.env.REACT_APP_SERVER_URL_IMG + transfer.imgCart}
                                            className="card-img"
                                            alt="..."
                                        />
                                    </div>
                                </div>
                                <div className="col-12 col-md-9">
                                    <div className="row ">
                                        <div className="col-12 col-md-11 order-2 order-md-1">
                                            <h5 className="text-uppercase">{transfer.prodType}</h5>
                                            <h6 className="text-uppercase">{transfer.productName}</h6>
                                            <p>
                                                <small>
                                                    {t("checkoutCards.cardTransfer.data") +
                                                        " " +
                                                        transfer.date.split("-").reverse().join("/")}
                                                    <span className="ml-3">
                                                        {t("checkoutCards.cardTransfer.embarque") +
                                                            " " +
                                                            transfer.time}
                                                    </span>
                                                </small>
                                            </p>
                                        </div>
                                        <div className="col-12 col-md-1 order-1 order-md-2 py-2 py-md-0">
                                            <div className="row text-center">
                                                <div className="col order-2 order-md-1  mb-0 mb-md-2">
                                                    <Link
                                                        to="#"
                                                        className="p-0 m-0 text-muted btn-excluiM"
                                                        style={{ cursor: "pointer" }}
                                                    >
                                                        <i
                                                            className="fas fa-times btn p-2 btn-circle bg-light text-dark btn-exclui"
                                                            data-btn="exclui"
                                                            id={transfer.idDel}
                                                        ></i>{" "}
                                                        <span className="small d-md-none">
                                                            ' + transRemove + '
                                                        </span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="m-0">
                                            <i
                                                className="fal fa-map-marked-alt text-primary"
                                                aria-hidden="true"
                                            ></i>
                                            {t("checkoutCards.cardTransfer.inform")}
                                        </p>
                                        <div>
                                            <small>
                                                <strong>
                                                    {t("checkoutCards.cardTransfer.origem")}:
                                                </strong>{" "}
                                                <span data-info="local">{transfer.commingSource}</span>
                                            </small>
                                        </div>
                                        <div>
                                            <small>
                                                <strong>
                                                    {t("checkoutCards.cardTransfer.destino")}:
                                                </strong>{" "}
                                                <span data-info="local">{transfer.goingSource}</span>
                                            </small>
                                        </div>
                                    </div>
                                    <div className="row d-inline-flex w-100 mt-2 py-2 border-top">
                                        <div className="col-4 text-center">
                                            <i
                                                className="fal fa-users mr-2 pb-2 text-primary d-flex justify-content-center fa-lg"
                                                aria-hidden="true"
                                            ></i>
                                            <small className="d-block d-md-inline-block text-center">
                                                {" "}
                                                {transfer.globalPeople +
                                                    " " +
                                                    t("checkoutCards.cardTransfer.pessoas_veiculos")}{" "}
                                            </small>
                                        </div>
                                        <div className="col-4 text-center">
                                            <i
                                                className="fal fa-suitcase mr-2 pb-2 text-primary d-flex justify-content-center fa-lg"
                                                aria-hidden="true"
                                            ></i>
                                            <small className="d-block d-md-inline-block text-center">
                                                {" "}
                                                {transfer.bigSuitcase +
                                                    " " +
                                                    t("checkoutCards.cardTransfer.mala_pessoa")}{" "}
                                            </small>{" "}
                                        </div>
                                        <div className="col-4 text-center">
                                            <i
                                                className="fal fa-suitcase-rolling mr-2 pb-2 text-primary d-flex justify-content-center fa-lg"
                                                aria-hidden="true"
                                            ></i>
                                            <small className="d-block d-md-inline-block text-center">
                                                {" "}
                                                {transfer.smallSuitcase +
                                                    " " +
                                                    t("checkoutCards.cardTransfer.mala_mao_pessoa")}{" "}
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-bottom bg-black-10 px-3 py-3">
                                <div className="row preco align-items-center">
                                    <div className="col">
                                        <small>
                                            <strong>
                                                {" "}
                                                {t("checkoutCards.cardTransfer.total_pessoas") +
                                                    " " +
                                                    transfer.globalPeople}
                                            </strong>
                                        </small>
                                    </div>
                                    <div className="col text-right">
                                        <span className="h6 text-muted">R$</span>
                                        <span className="pl-1 h5 text-primary font-weight-bold">
                                            {String(transfer.price.toFixed(2))
                                                .split(".")
                                                .join(",")}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
                : ""}
        </div>
    );
};

export default CheckoutCardTransfer;
