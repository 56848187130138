import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";

import "./DropdownHours.scss";
import { Col, Row } from "react-bootstrap";
import api from "../../services/api";
import { useTranslation } from "react-i18next";

export interface propsR {
    changePriceDateTour: any,
    dateTour: any,
    index?: any,
    setPeopleDisponible?: any,
    edit?: any,
    item?: any,
    info?: any,
    isIntegration?: any,
    isDetailsProduct?: any
}

const DropdownHours: React.FC<propsR> = ({
    changePriceDateTour, dateTour, index = 0, setPeopleDisponible, edit = false, item, info, isIntegration, isDetailsProduct = false
}: propsR) => {
    const url = window.location.href;
    const codeUrl = url.split("/");
    const isTrilha = codeUrl[3] === "compra-rapida" ? codeUrl[4].split("?")[2] : codeUrl[4].split("?")[1];

    const { t } = useTranslation();

    const [isOpen, setIsOpen] = useState<any>(false);
    const [hours, setHours] = useState<any>(null);
    const [hoursParam, setHoursParam] = useState<any>([]);
    const [select, setSelect] = useState<any>(null);
    const [loading, setLoading] = useState<any>(true);

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    const handleClickHours = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    function handleSelectHours(hour: any, price: any, hours: any) {
        setSelect(hour);

        var aux: any = dateTour;
        //aux[index].data = dateTour.data;
        //aux[index].idSellingType = objCalendar.idSellingType;
        aux[index].time = hour;

        if (isIntegration === false) {
            aux[index].adult = price.sellingTarifAdultFinal;
            aux[index].child = price.sellingTarifChildFinal;
            aux[index].infant = price.sellingTarifInfantFinal;
            aux[index].student = price.sellingTarifStudentFinal;
            aux[index].elders = price.sellingTarifEldersFinal;
            aux[index].global = price.sellingTarifGlobalFinal;
            aux[index].maxStockNumber = price.balanceAvailableByChannel;
            aux[index].hours = hours;
            aux[index].idTarifario = price.idTarifario;
        } else {
            aux[index].maxStockNumber = price.capacity;
            aux[index].hours = hours;
        }

        setIsOpen(false);
        if (edit !== true) {
            setPeopleDisponible(true);
        }
        changePriceDateTour(aux, hour);
    }

    useEffect(() => {
        let aux: any = [];
        let idChannel: number = 1;
        let date: any = new Date();
        let dateActual: any = date.toLocaleString().replaceAll(",", "").split(" ")[0].split("/").reverse().join("");

        if (edit !== true) {
            setSelect(null);
            setPeopleDisponible(false);
        } else {
            if (dateTour[0].time === undefined) {
                dateTour[0].time = item.startTimeLocal.split("T")[1];
            }
        }
        setLoading(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` }, "ngrok-skip-browser-warning": "69420"
        };

        async function listHours(info: any) {
            try {
                const { data } = await api.post(`api/Products/GetTicketAndTourHoursStockAsync`,
                    {
                        "page": 1,
                        "rowsPerPage": 10,
                        "dateStart": info.selectedDate.split("T")[0],
                        "tarUniqueCode": info.tarUniqueCode,
                        "prodModUniqueCode": info.prodModUniqueCode/* 'MOA5357' */,
                        "daysUniqueTarCode": info.daysUniqueTarCode,
                        "idCanal": isTrilha === "trilha" ? 7 : idChannel
                    }, config);
                if (data.status !== 400) {
                    aux.push(...data.data);
                    setLoading(false);

                    if (dateActual === dateTour[index].data.replaceAll("-", "")) {
                        setHoursParam(aux);
                    } else {
                        setHours(aux);

                        if (aux.length === 1) {
                            handleSelectHours(data.data[0].hora, data.data[0], data.data);
                        }
                    }
                }
            } catch (error: any) {
                /* if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                } */
            }
        }

        async function listHoursIntegration() {
            try {
                const { data } = await api.post(`api/Products/GetTicketActivitiesIntegrationAsync`,
                    {
                        "dateStart": dateTour[index].data,
                        "productCode": info.productCode,
                        "ProdModCode": info.modalities[index].prodModCode,
                    }, config);
                if (data.status !== 400) {
                    setLoading(false);
                    setHours(data.data);

                    if (data.data.length === 1 && data.data[0].startTime === null) {
                        handleSelectHours("00:00:00", data.data[0], data.data);
                    }
                }
            } catch (error: any) {
                /* if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                } */
            }
        }

        const config2 = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        async function getIdChannel(prod: any) {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config2);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;
                    listHours(prod);
                }
            } catch (error) { }
        }
        if (isIntegration === false) {
            for (let i = 0; i < dateTour[index]?.auxTarif?.length; i++) {            
                getIdChannel(dateTour[index]?.auxTarif[i]);
            }
        } else {
            listHoursIntegration();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTour[index]?.data]);

    console.log(dateTour[index])

    useEffect(() => {
        let auxHours: any = [];
        let date: any = new Date();
        let timeActual: any = date.toLocaleString().replaceAll(",", "").split(" ")[1].replaceAll(":", "");

        if (hoursParam?.length > 0) {
            for (let index = 0; index < hoursParam?.length; index++) {
                if (hoursParam[index]?.hora.replaceAll(":", "") > timeActual) {
                    auxHours.push(hoursParam[index]);
                }
            }

            setHours(auxHours);

            //caso só exista um horário, já seleciona automático
            if (auxHours.length === 1) {
                handleSelectHours(auxHours[0].hora, auxHours[0], auxHours);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hoursParam])

    useEffect(() => {
        if (edit === true) {
            setSelect(item?.startTimeLocal.split("T")[1]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (dateTour[index]?.hours?.length !== 1 || info.reservationSystem === 99) { // Produto SIG x SIG aparecer o horário
        if (isOpen === true && isIntegration === false) {
            return (
                <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                    <div className="mb-5 bg-hours">
                        <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                            <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                            <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours">
                                <div id="wrapper" ref={wrapperRef}>
                                    <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                                        <FontAwesomeIcon
                                            icon={["fal", "clock"]}
                                            className="size-icon-hours mr-2"
                                        />
                                        <span style={{ marginLeft: "40px", fontSize: "18px" }}>
                                            {select === null ? `${t("slowBuy.ticket.chooseHours")}` : select.split(":")[0] + ":" + select.split(":")[1]}
                                        </span>
                                    </div>

                                    <div className="panel-dropdown-content panel-hours right active">
                                        <Row className="mx-0">
                                            {hours?.map((x: any, index: any) => {
                                                if (x?.balanceAvailableByChannel <= 5) {
                                                    return (
                                                        <>
                                                            {
                                                                index === 0
                                                                    ?
                                                                    <h6 className="hours-title">
                                                                        {t("slowBuy.ticket.lastVagas")}
                                                                    </h6>
                                                                    :
                                                                    ""
                                                            }
                                                            <Col
                                                                xs={4}
                                                                lg={3}
                                                                key={index}
                                                                /* value={x.startTime} */
                                                                data-capacity={x?.balanceAvailableByChannel}
                                                                className="p-1 mb-2"
                                                            >
                                                                <div className="hours-options" onClick={() => handleSelectHours(x.hora, x, hours)}>
                                                                    <p>{`${x.hora.split(":")[0]}:${x.hora.split(":")[1]}`}</p>
                                                                    {
                                                                        dateTour[0]?.idSellingType === 1
                                                                            ?
                                                                            <small>R$ {x?.sellingTarifGlobalFinal?.toFixed(2).replace(".", ",")}</small>
                                                                            :
                                                                            <small>R$ {x?.sellingTarifAdultFinal?.toFixed(2).replace(".", ",")}</small>
                                                                    }
                                                                    <span>{`${x?.balanceAvailableByChannel} vagas`}</span>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <></>
                                                    )
                                                }
                                            })}
                                        </Row>
                                        <h6 className="hours-title">
                                            {hours?.length > 0 ? `${t("slowBuy.ticket.disponibleHours")}` : `${t("slowBuy.ticket.noDisponibleHours")}`}
                                        </h6>
                                        <Row className="mx-0">
                                            {hours?.map((x: any, index: any) => {
                                                if (x.balanceAvailableByChannel > 5) {
                                                    return (
                                                        <>
                                                            <Col
                                                                xs={4}
                                                                lg={3}
                                                                key={index}
                                                                /* value={x.startTime} */
                                                                data-capacity={x?.balanceAvailableByChannel}
                                                                className="p-1"
                                                            >
                                                                <div className="hours-options" onClick={() => handleSelectHours(x.hora, x, hours)}>
                                                                    <p>{`${x.hora.split(":")[0]}:${x.hora.split(":")[1]}`}</p>
                                                                    {
                                                                        dateTour[0]?.idSellingType === 1
                                                                            ?
                                                                            <small>R$ {x?.sellingTarifGlobalFinal?.toFixed(2).replace(".", ",")}</small>
                                                                            :
                                                                            <small>R$ {x?.sellingTarifAdultFinal?.toFixed(2).replace(".", ",")}</small>
                                                                    }
                                                                </div>
                                                            </Col>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <></>
                                                    )
                                                }
                                            })}
                                        </Row>
                                        {/* <p id="alertNumber"></p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (isOpen === true && isIntegration === true && (hours?.length > 0 && hours[0]?.startTime !== null)) {
            return (
                <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                    <div className="mb-5 bg-hours">
                        <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                            <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                            <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours">
                                <div id="wrapper" ref={wrapperRef}>
                                    <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                                        <FontAwesomeIcon
                                            icon={["fal", "clock"]}
                                            className="size-icon-hours mr-2"
                                        />
                                        <span style={{ marginLeft: "40px", fontSize: "18px" }}>
                                            {select === null ? `${t("slowBuy.ticket.chooseHours")}` : select.split(":")[0] + ":" + select.split(":")[1]}
                                        </span>
                                    </div>

                                    <div className="panel-dropdown-content panel-hours right active">
                                        <Row className="mx-0">
                                            {hours?.map((x: any, index: any) => {
                                                if (x.balanceAvailableByChannel <= 5) {
                                                    return (
                                                        <>
                                                            {
                                                                index === 0
                                                                    ?
                                                                    <h6 className="hours-title">
                                                                        {t("slowBuy.ticket.lastVagas")}
                                                                    </h6>
                                                                    :
                                                                    ""
                                                            }
                                                            <Col
                                                                xs={4}
                                                                lg={3}
                                                                key={index}
                                                                /* value={x.startTime} */
                                                                data-capacity={x.balanceAvailableByChannel}
                                                                className="p-1 mb-2"
                                                            >
                                                                <div className="hours-options" onClick={() => handleSelectHours(x.hora, x, hours)}>
                                                                    <p>{`${x.hora.split(":")[0]}:${x.hora.split(":")[1]}`}</p>
                                                                    {
                                                                        dateTour[0]?.idSellingType === 1
                                                                            ?
                                                                            <small>R$ {x?.sellingTarifGlobalFinal?.toFixed(2).replace(".", ",")}</small>
                                                                            :
                                                                            <small>R$ {x?.sellingTarifAdultFinal?.toFixed(2).replace(".", ",")}</small>
                                                                    }
                                                                    <span>{`${x.balanceAvailableByChannel} vagas`}</span>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <></>
                                                    )
                                                }
                                            })}
                                        </Row>
                                        <h6 className="hours-title">
                                            {hours?.length > 0 ? `${t("slowBuy.ticket.disponibleHours")}` : `${t("slowBuy.ticket.noDisponibleHours")}`}
                                        </h6>
                                        <Row className="mx-0">
                                            {hours?.map((x: any, index: any) => {
                                                if (x?.capacity > 5) {
                                                    return (
                                                        <>
                                                            <Col
                                                                xs={4}
                                                                lg={3}
                                                                key={index}
                                                                /* value={x.startTime} */
                                                                data-capacity={x?.capacity}
                                                                className="p-1"
                                                            >
                                                                <div className="hours-options" onClick={() => handleSelectHours(x?.startTime, x, hours)}>
                                                                    <p>{`${x?.startTime.split(":")[0]}:${x?.startTime.split(":")[1]}`}</p>
                                                                    <small>{x?.capacity} vagas</small>
                                                                </div>
                                                            </Col>
                                                        </>
                                                    );
                                                } else {
                                                    return (
                                                        <></>
                                                    )
                                                }
                                            })}
                                        </Row>
                                        {/* <p id="alertNumber"></p> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (hours?.length <= 0 && isIntegration === true) {
            dateTour[index].maxStockNumber = 99990;
            setPeopleDisponible(true);
            return (
                <></>
            )
        } else {
            if (loading !== true) {
                return (
                    <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                        <div className="mb-5 bg-hours">
                            <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                                <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                                <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours">
                                    <div className="d-flex align-items-center" style={{ cursor: "pointer", color: "#666" }} onClick={() => handleClickHours()}>
                                        <FontAwesomeIcon
                                            icon={["fal", "clock"]}
                                            className="size-icon-hours mr-2"
                                            style={{ fontSize: "22px" }}
                                        />
                                        <span style={{ marginLeft: "40px", fontSize: "18px" }}>{select === null ? `${t("slowBuy.ticket.chooseHours")}` : select.split(":")[0] + ":" + select.split(":")[1]}</span>
                                        <span
                                            className="qtyTotal"
                                            data-min="2"
                                            data-max="19"
                                        >
                                            {/* {dateTour[actual]?.idSellingType === 2
                                ? numberTotal
                                : dateTour[actual]?.idSellingType === 1
                                    ? numberTotalPeople
                                    : 0} */}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                        <div className="mb-5 bg-hours">
                            <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                                <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                                <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours"></div>
                                <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer", color: "#666", position: "relative" }} onClick={() => handleClickHours()}>
                                    <div className="load" style={{ position: "absolute", bottom: "10px" }}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    } else {
        if (loading === true) {
            return (
                <div className={isDetailsProduct === true ? "panel-dropdown bg-white bg-option-hours" : ""}>
                    <div className="mb-5 bg-hours">
                        <div style={{ borderBottom: "1px solid #707070" }} className="bg-border-hours">
                            <p style={{ fontSize: "18px", color: "#707070" }}><strong>{t("slowBuy.ticket.passo03")} </strong> {t("slowBuy.ticket.selectHours")}</p>

                            <div style={{ padding: "0.5rem 0 0.375rem 0", height: "42px" }} className="bg-height-hours"></div>
                            <div className="d-flex align-items-center justify-content-start" style={{ cursor: "pointer", color: "#666", position: "relative" }} onClick={() => handleClickHours()}>
                                <div className="load" style={{ position: "absolute", bottom: "10px" }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <></>
            )
        }
    }
}

export default DropdownHours;