import React from 'react';

function ModalAlertPT() {
  return (
    <>
      <p>
        <strong>
          O Parque do Caracol tem uma informação importante para você! Fique
          ligado no nosso horário de funcionamento especial de final de ano:
        </strong>
      </p>
      <p>
        <strong>31/12 (Véspera de Ano Novo): 09h às 15h</strong>
      </p>
      <p>
        <strong>01/01 (Ano Novo): 11h às 17:30h</strong>
      </p>
      <p>
        <strong>
          Nos demais dias, o Parque funcionará normalmente das 09h às 17:30h.
        </strong>
      </p>
      <p>
        <strong>Até breve!</strong>
      </p>
    </>
  );
}

export default ModalAlertPT;
