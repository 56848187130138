import { useEffect, useState } from 'react';

import i18next from 'i18next';

import { Row, Col } from 'react-bootstrap';
import Button from '@restart/ui/esm/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import Helmet from 'react-helmet';
import Accordion from 'react-bootstrap/Accordion';
import { Link as LinkRoll } from 'react-scroll';
import './TicketCaracol.scss';
import api from '../../services/api';

import ProductSlider from '../../components/Sliders/ProductSlider/ProductSlider';
import CardTourSide from '../../components/CardTourSide/CardTourSide';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { addItemCart } from '../../store/cart';
import PageDafault from '../../templates/PageDafault';

import TagManager from 'react-gtm-module';
import TicketPromotionCaracol from './TicketPromotionCaracol';
import DiscountRulesCaracol from '../../components/DiscountRules/DiscountRulesCaracol';
import LocationCaracol from '../../components/Location/LocationCaracol';
import AccessRulesCaracol from '../../components/AccessRules/AccessRulesCaracol';
import TicketOptionsCaracol from '../../components/ProductOptions/TicketOptionsCaracol';
import NotFound from '../NotFound/NotFound';

const TicketCaracol = () => {
  const { t } = useTranslation();
  const lng =
    i18next.language === 'pt'
      ? 'BR'
      : i18next.language === 'en'
        ? 'EN'
        : i18next.language === 'es'
          ? 'ES'
          : 'BR';
  const [tourResponse, setTourResponse] = useState<any>('null');
  const [optionTour, setOptionTour] = useState<any>([]);
  const [cardTourSide, setCardTourSide] = useState<any>([]);
  const [alert, setAlert] = useState<any>(false);

  const cartBPA: any = JSON.parse(
    JSON.stringify(useAppSelector((state: any) => state.cart)),
  );

  const dispatch = useAppDispatch();

  const url = window.location.href;
  const codeUrl = url.split('/');
  const slugUrl = codeUrl[codeUrl.length - 1].split('?')[0];

  const addToCart = (itemOption: any, productCode: any) => {
    var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

    cartBPA.dados.forEach((elem: any) => {
      if (elem.productCode === productCode) {
        repeatedItem = true;
      }
    });

    if (repeatedItem === true) {
      setAlert(true);
    } else {
      const item = {
        productName: tourResponse.productName,
        productNameBR: tourResponse.productNameBR,
        productNameEN: tourResponse.productNameEN,
        productNameES: tourResponse.productNameES,
        imgBaseUrl: tourResponse.imagesBaseUrl,
        imgCart: tourResponse.images[0],
        price: itemOption?.tarif?.price,

        idTarif: itemOption.idTarif,
        idPickup: itemOption.idPickup,

        priceAdults: itemOption.priceAdults,
        priceChilds: itemOption.priceChilds,
        priceInfants: itemOption.priceInfants,
        priceElders: itemOption.priceElders,
        priceStudent: itemOption.priceStudent,
        priceGlobalPeople: itemOption.priceGlobalPeople,
        reservationSystem: tourResponse.reservationSystem,

        ranges: itemOption.ranges,
        hours: itemOption.hours,

        sellingType: tourResponse.idSellingType === 1 ? 2 : 1,
        adults: itemOption.adults,
        childs: itemOption.childs,
        infants: itemOption.infants,
        elders: itemOption.elders,
        student: itemOption.student,

        globalPeople: itemOption.globalPeople,
        totalPeople: itemOption.totalPeople,

        productType: 4,
        productCode: tourResponse.productCode,
        productSlug: slugUrl,
        slugBR: tourResponse.slugBR,
        slugEN: tourResponse.slugEN,
        slugES: tourResponse.slugES,
        time: itemOption.time,
        date: itemOption.date,
        supplier: tourResponse.supplier,
        typePickup: itemOption.embarkingType,
        meetingPoint:
          itemOption.embarkingType === '0'
            ? tourResponse.eventAddress
            : tourResponse.eventAddress,
        pickupListId:
          itemOption.embarkingType === '0' ? 0 : itemOption.pickupListId,
        pickup: 0,

        discount: 0,
        customValueNet: 0,
        customValueSell: 0,
        goingSource: 'null',
        commingSource: 'null',
        latOrigem: 'null',
        lngOrigem: 'null',
        latDestino: 'null',
        lngDestino: 'null',
        cia: 'null',
        voo: 'null',
        smallSuitcase: 0,
        bigSuitcase: 0,
        internalNotes: ' ',
        idVeiculo: 0,
        passengers: [],
      };

      //SetCartItem(item);
      dispatch(addItemCart(item));
      window.location.href = window.location.origin + '/checkout';
    }
  };

  useEffect(() => {
    let idChannel: number = 1;

    async function getItems() {
      try {
        const { data } = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTicketAsync`,
          {
            productCode: "",
            lang: lng,
            Slug: `${slugUrl.replaceAll("%20", " ").replaceAll("%C3%A9", "é")}`,
            idCanal: idChannel
          },
        );
        if (data.status !== 400) {
          // console.log(data)
          if (data.statusCode === 204) {
            // window.location.href = "/404";
          } else {
            setTourResponse(data.data);
            setOptionTour(data.data.modalities);
            setCardTourSide(data.data.modalities);
          }
        }
      } catch (error) { }
    }

    const config = {
      headers: { "ngrok-skip-browser-warning": "69420" },
    };

    async function getIdChannel() {
      try {
        const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config);
        if (data.statusCode === 200) {
          idChannel = data.data.data;
          getItems()
        }
      } catch (error) { }
    }

    getIdChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newSlug =
      i18next.language === 'pt'
        ? tourResponse.slugBR
        : i18next.language === 'en'
          ? tourResponse.slugEN
          : i18next.language === 'es'
            ? tourResponse.slugES
            : tourResponse.slugBR;
    if (slugUrl !== newSlug && newSlug !== undefined) {
      window.location.href = window.location.origin + '/ticket/' + newSlug;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  useEffect(() => {
    if (tourResponse !== 'null') {
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: {
            detail: {
              products: [
                {
                  name: tourResponse.productName, // Name or ID is required.
                  id: tourResponse.productCode,
                  price: tourResponse?.modalities[0]?.tarif.price,
                  brand: 'Bondinho',
                },
              ],
            },
          },
        },
      });
    }
  }, [tourResponse]);

  if (tourResponse !== 'null') {
    const ldJson = {
      '@context': 'http://schema.org/',
      '@type': 'TouristAttraction',
      description: tourResponse.productInfo.replace(/<[^>]*>?/gm, ''),
      image: tourResponse.imagesBaseUrl + '/medium_' + tourResponse.images[0],
      name: process.env.REACT_APP_SERVER_NAME,
      url: process.env.REACT_APP_SERVER_URL,
    };

    return (
      <>
        <Helmet>
          <html lang={i18next.language} />
          <title>
            {tourResponse.productName +
              ' | ' +
              process.env.REACT_APP_SERVER_NAME}
          </title>
          <meta
            name="description"
            content={tourResponse.productInfo.replace(/<[^>]*>?/gm, '')}
          />
          <meta
            name="googlebot"
            content={process.env.REACT_APP_SERVER_ROBOTS}
          />

          {/* Essential META Tags */}
          <meta
            property="og:title"
            content={
              tourResponse.productName +
              ' | ' +
              process.env.REACT_APP_SERVER_NAME
            }
          />
          <meta property="og:type" content="TouristAttraction" />
          <meta
            property="og:image"
            content={`${tourResponse.imagesBaseUrl}/medium_${tourResponse.images[0]}`}
          />
          <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />

          {/* Non-Essential, But Recommended */}
          <meta
            property="og:description"
            content={tourResponse.productInfo.replace(/<[^>]*>?/gm, '')}
          />
          <meta
            property="og:site_name"
            content={process.env.REACT_APP_SERVER_NAME}
          />

          {/* Non-Essential, But Required for Analytics */}
          {/* <meta property="fb:app_id" content="your_app_id" /> */}

          {tourResponse.productCode === 'PXY4929' ||
            tourResponse.productCode === 'PXC8157' ||
            tourResponse.productCode === 'PXE9656' ? (
            <meta name="robots" content="noindex, nofollow" />
          ) : (
            <meta name="robots" content={process.env.REACT_APP_SERVER_ROBOTS} />
          )}

          <link rel="canonical" href={process.env.REACT_APP_SERVER_URL} />

          <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
        </Helmet>

        {tourResponse.productCode !== 'TKT-ES99985' ? (
          <PageDafault>
            <div className="bg-light">
              <div className="container destino text-dark bg-light px-0">
                <div className="row w-100 m-0">
                  <div
                    className="col-12 col-lg-9 order-2 order-lg-1 px-0"
                    data-spy="scroll"
                    data-target="#navbar-destino"
                    data-offset="0"
                  >
                    <div className="col-12 p-4">
                      {/* Carrosel de imagens */}
                      <section>
                        <div id="carousel-thumb" className="caracol">
                          <ProductSlider
                            arrayImages={tourResponse.images}
                            imagesBaseUrl={tourResponse.imagesBaseUrl}
                            alts={tourResponse.alts}
                          />
                        </div>
                      </section>
                      {/* Carrosel de imagens */}
                      <div className="conteudo">
                        <nav
                          id="navbar-destino"
                          className="navbar navbar-light bg-white mb-5"
                        >
                          <div
                            className="container text-center p-0 m-0"
                            style={{ display: 'block' }}
                          >
                            <ul className="nav-pills scroll-nav">
                              <Row>
                                <Col md={9} className="flex-column">
                                  <Row>
                                    <Col md={3}>
                                      <li className="nav-item">
                                        <LinkRoll
                                          activeClass="active"
                                          to="experiencia"
                                          spy={true}
                                          smooth={true}
                                          offset={-80}
                                          duration={500}
                                        >
                                          <span className="nav-link link-tour nav-tour">
                                            {' '}
                                            <p className="d-md-inline d-lg-inline text-md-uppercase">
                                              <FontAwesomeIcon
                                                icon={['fal', 'grin-wink']}
                                                size="1x"
                                                style={{ marginRight: '5px' }}
                                              />
                                              <span>
                                                {t('tour.theExperience')}
                                              </span>
                                            </p>
                                          </span>
                                        </LinkRoll>
                                      </li>
                                    </Col>
                                    <Col md={3}>
                                      <li className="nav-item">
                                        <LinkRoll
                                          activeClass="active"
                                          to="modalidades"
                                          spy={true}
                                          smooth={true}
                                          offset={-80}
                                          duration={500}
                                        >
                                          <span className="nav-link link-tour nav-tour">
                                            {' '}
                                            <p className="d-lg-inline text-md-uppercase">
                                              <FontAwesomeIcon
                                                icon={['fal', 'list']}
                                                size="1x"
                                                style={{ marginRight: '5px' }}
                                              />
                                              <span>{t('tour.options')}</span>
                                            </p>
                                          </span>
                                        </LinkRoll>
                                      </li>
                                    </Col>
                                    <Col md={3}>
                                      <li className="nav-item">
                                        <LinkRoll
                                          activeClass="active"
                                          to="info"
                                          spy={true}
                                          smooth={true}
                                          offset={-140}
                                          duration={500}
                                        >
                                          <span className="nav-link link-tour nav-tour">
                                            {' '}
                                            <p className="d-lg-inline text-md-uppercase">
                                              <FontAwesomeIcon
                                                icon={['fal', 'info-circle']}
                                                size="1x"
                                                style={{ marginRight: '5px' }}
                                              />
                                              <span>
                                                {t('tour.Information')}
                                              </span>
                                            </p>
                                          </span>
                                        </LinkRoll>
                                      </li>
                                    </Col>
                                    <Col md={3}>
                                      <li className="nav-item">
                                        <LinkRoll
                                          activeClass="active"
                                          to="product-code"
                                          spy={true}
                                          smooth={true}
                                          offset={-80}
                                          duration={500}
                                        >
                                          <span className="nav-link link-tour nav-tour">
                                            {' '}
                                            <p className="d-lg-inline text-md-uppercase">
                                              <FontAwesomeIcon
                                                icon={['fal', 'phone-rotary']}
                                                size="1x"
                                                style={{ marginRight: '5px' }}
                                              />
                                              <span>{t('tour.contact')}</span>
                                            </p>
                                          </span>
                                        </LinkRoll>
                                      </li>
                                    </Col>
                                  </Row>
                                </Col>
                                <Col md={3}>
                                  <li className="nav-item">
                                    <LinkRoll
                                      className="nav-link link-tour nav-tour btn-see-options"
                                      activeClass="active"
                                      to="modalidades"
                                      spy={true}
                                      smooth={true}
                                      offset={-80}
                                      duration={500}
                                      style={{
                                        backgroundColor: '#1F6A5D',
                                        width: '135px',
                                        marginLeft: 'auto',
                                      }}
                                    >
                                      {t('tour.seeOptions')}
                                    </LinkRoll>
                                  </li>
                                </Col>
                              </Row>
                            </ul>
                          </div>
                        </nav>
                        {/* Navegador Tour */}

                        <div className="row d-flex align-content-end">
                          <div className="col-md-12 py-2">
                            <h1
                              className="h1 font-weight-bold pb-2 text-primary"
                              id="name-product"
                            >
                              {tourResponse.productName}
                            </h1>
                          </div>
                        </div>

                        {/* Conteúdo do passeio */}
                        <h5
                          className="text-uppercase text-primary"
                          id="experiencia"
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'smile']}
                            size="1x"
                            style={{ marginRight: '5px' }}
                          />
                          {t('tour.theExperience')}
                        </h5>
                        <span
                          id="description"
                          dangerouslySetInnerHTML={{
                            __html: tourResponse.productDescription,
                          }}
                        ></span>

                        <h5 className="text-uppercase text-primary mt-2">
                          <FontAwesomeIcon
                            icon={['fal', 'info-circle']}
                            size="1x"
                            style={{ marginRight: '5px' }}
                          />
                          {t('tour.informations')}
                        </h5>
                        <span
                          id="infos-product"
                          dangerouslySetInnerHTML={{
                            __html: tourResponse.productInfo,
                          }}
                        ></span>

                        {/*                     <h5 className="text-uppercase text-primary mt-2">
                      <FontAwesomeIcon
                        icon={["fal", "star"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("tour.highlights")}{" "}
                    </h5>
                    <h5 className="text-uppercase text-primary mt-2">
                      <FontAwesomeIcon
                        icon={["fal", "star"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("tour.details")}
                    </h5> */}

                        <section
                          id="modalidades"
                          className=" my-3"
                          data-destine="modalidades"
                        >
                          <h5 className="text-uppercase text-primary pb-4">
                            <FontAwesomeIcon
                              icon={['fal', 'list']}
                              size="1x"
                              style={{ marginRight: '5px' }}
                            />
                            {t('tour.options')}
                          </h5>
                          <TicketOptionsCaracol
                            options={optionTour}
                            response={tourResponse}
                            action={addToCart}
                          ></TicketOptionsCaracol>
                        </section>
                        {/* Opções do passeio */}

                        <div className="accordion accordion-rules" id="info">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <span
                                  className="h4 text-primary font-weight-normal text-uppercase"
                                  id="infos"
                                >
                                  {t('tour.discountRules')}
                                </span>
                                <i className="fal float-right p-0"></i>
                              </Accordion.Header>
                              <Accordion.Body>
                                <DiscountRulesCaracol
                                  tourResponse={tourResponse}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          <Accordion defaultActiveKey="0" className="mt-4">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <span
                                  className="h4 text-primary font-weight-normal text-uppercase"
                                  id="politica"
                                >
                                  {t('navHeader.sideBar.howtoget')}
                                </span>
                                <i className="fal float-right p-0"></i>
                              </Accordion.Header>
                              <Accordion.Body>
                                <LocationCaracol />

                                <div className="embed-responsive embed-responsive-16by9 h-100 w-100">
                                  <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3478.9900151897073!2d-50.85569252499975!3d-29.311968547697074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951ecd5b1eff3555%3A0x8f7d9481de645462!2sParque%20do%20Caracol!5e0!3m2!1spt-BR!2sbr!4v1672078196497!5m2!1spt-BR!2sbr"
                                    width="100%"
                                    height="100%"
                                    frameBorder="0"
                                    title="how to get"
                                    allow="autoplay; fullscreen; picture-in-picture"
                                    allowFullScreen
                                  ></iframe>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          <Accordion defaultActiveKey="0" className="mt-4">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <span
                                  className="h4 text-primary font-weight-normal text-uppercase"
                                  id="politica"
                                >
                                  {t('navHeader.sideBar.acessRules')}
                                </span>
                                <i className="fal float-right p-0"></i>
                              </Accordion.Header>
                              <Accordion.Body>
                                <AccessRulesCaracol />
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          {/* <Accordion defaultActiveKey="0" className="mt-4">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>
                                <span
                                  className="h4 text-primary font-weight-normal text-uppercase"
                                  id="politica"
                                >
                                  {t('tour.cancel')}
                                </span>
                                <i className="fal float-right p-0"></i>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="pt-3">
                                  {i18next.language === 'pt' ? (
                                    <>
                                      <p>
                                        Fique tranquilo, pois seu ingresso tem
                                        validade de 7 dias a partir da data
                                        escolhida.
                                      </p>
                                      <p className="mb-0">
                                        Caso, ainda assim, deseje fazer o
                                        cancelamento, ele é gratuito se
                                        realizado em até 7 dias da data de
                                        confirmação do pagamento por meio do seu
                                        login e de sua senha de acesso no site.
                                      </p>
                                    </>
                                  ) : i18next.language === 'en' ? (
                                    <>
                                      <p>
                                        Don’t worry, your ticket is valid for 7
                                        days from the chosen date.
                                      </p>
                                      <p className="mb-0">
                                        If you still wish to cancel, it is free
                                        of charge within 7 days of the payment
                                        confirmation date through your login and
                                        password on the site.
                                      </p>
                                    </>
                                  ) : i18next.language === 'es' ? (
                                    <>
                                      <p>
                                        Quédese tranquilo porque su boleto tiene
                                        una validez de 7 días a partir de la
                                        fecha elegida.
                                      </p>
                                      <p className="mb-0">
                                        Si a pesar de esto, aún desea cancelar,
                                        es gratis si es realizada dentro de 7
                                        días a partir de la fecha de
                                        confirmación del pago, a través de su
                                        login y contraseña de acceso en la
                                        página web.
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p>
                                        Fique tranquilo, pois seu ingresso tem
                                        validade de 7 dias a partir da data
                                        escolhida.
                                      </p>
                                      <p className="mb-0">
                                        Caso, ainda assim, deseje fazer o
                                        cancelamento, ele é gratuito se
                                        realizado em até 7 dias da data de
                                        confirmação do pagamento por meio do seu
                                        login e de sua senha de acesso no site.
                                      </p>
                                    </>
                                  )}
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion> */}
                        </div>

                        <div className="my-4">
                          <div className="row m-0 overflow-hidden">
                            <div className="col-12 col-sm-6 col-md- col-lg- col-xl-8 align-items-center d-flex bg-white px-3 py-2">
                              <h4 id="questoes" className="py-0">
                                {' '}
                                {t('tour.code')}?
                              </h4>
                            </div>

                            <div className="col-12 col-sm-6 col-md- col-lg- col-xl-4 p-0 bg-primary text-white align-items-center justify-content-center d-flex">
                              <span id="product-code">
                                {t('tour.code')}: {tourResponse.productCode}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-4">
                            <div className="col-12 text-center pb-2">
                              <p>{t('tour.emergencyContacts')}</p>
                            </div>
                          </div>
                          <div className="row text-center bg-contact justify-content-evenly">
                            <div className="col-12 col-sm-4">
                              <FontAwesomeIcon
                                icon={['fal', 'envelope']}
                                size="2x"
                                style={{
                                  color: '#000',
                                  width: '32px',
                                  opacity: '0.6',
                                }}
                              />
                              <span
                                className="d-block lead pt-1 pb-2"
                                id="mail-info"
                              >
                                {' '}
                                {process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}
                              </span>
                              <a
                                href={`mailto:${process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}`}
                                title=""
                                className="btn btn-sm btn-outline-primary btn-contact"
                              >
                                {t('tour.sendEmail')}
                              </a>
                            </div>
                            <div className="col-12 col-sm-4 pb-3 pb-sm-0">
                              <FontAwesomeIcon
                                icon={['fab', 'whatsapp']}
                                size="2x"
                                style={{
                                  color: '#000',
                                  width: '32px',
                                  opacity: '0.6',
                                }}
                              />
                              <span
                                className="d-block lead pt-1 pb-2"
                                id="whats-info"
                              >
                                {process.env.REACT_APP_INFO_WHATSAPP}
                              </span>
                              <a
                                href={`https://api.whatsapp.com/send?phone=55${process.env.REACT_APP_INFO_WHATSAPP?.replaceAll(
                                  '(54) ',
                                  '54',
                                ).replaceAll('-', '')}`}
                                title=""
                                className="btn btn-sm btn-outline-primary btn-contact"
                              >
                                {t('tour.clackChat')}
                              </a>
                              <small
                                className="d-block mt-1"
                                style={{ fontSize: '.75rem' }}
                              >
                                {t('tour.belowMessageWpp')}
                              </small>
                            </div>
                            <div className="col-12 col-sm-4 pb-3 pb-sm-0">
                              <FontAwesomeIcon
                                icon={['fal', 'phone']}
                                size="2x"
                                style={{
                                  color: '#000',
                                  width: '32px',
                                  opacity: '0.6',
                                }}
                              />
                              <span
                                className="d-block lead pt-1 pb-2"
                                id="tel-info"
                              >
                                {process.env.REACT_APP_INFO_PHONE}
                              </span>
                              <a
                                href={`tel:${process.env.REACT_APP_INFO_PHONE}`}
                                title=""
                                className="btn btn-sm btn-outline-primary btn-contact"
                              >
                                {t('tour.clickCall')}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3 order-1 order-lg-2">
                    <CardTourSide options={cardTourSide} />
                  </div>
                </div>
              </div>
            </div>
            <Modal
              className="modal-confirm"
              show={alert}
              onHide={() => {
                setAlert(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <div className="modal-body text-center">
                <div>
                  <FontAwesomeIcon
                    icon={['fal', 'times-circle']}
                    size="5x"
                    style={{ fontSize: '7.5em' }}
                  />
                </div>
                <div className="px-3 px-md-5">
                  {t('tour.alertRepeatedItem')}
                </div>
                <div>
                  <Button
                    type="submit"
                    className="btn btn-primary form-button px-4 py-2"
                    onClick={() => setAlert(false)}
                  >
                    Fechar
                  </Button>
                </div>
              </div>
            </Modal>
          </PageDafault>
        ) : (
          <TicketPromotionCaracol />
        )}
      </>
    );
  } else {
    return (
      <PageDafault>
        <div className="bg-light">
          <div className="container destino text-dark bg-light px-0">
            <div className="row w-100 m-0">
              <div
                className="col-12 col-lg-9 order-2 order-lg-1 px-0"
              >
                <div className="col-12 p-4">
                  <div className="animated-background w-100" style={{ height: "clamp(350px, 35vw, 700px)" }}></div>

                  <div>
                    <nav
                      id="navbar-destino"
                      className="navbar navbar-light bg-white mb-5"
                    >
                      <div
                        className="container text-center p-0 m-0"
                        style={{ display: "block" }}
                      >
                        <ul className="nav-pills scroll-nav">
                          <Row>
                            <Col md={9} className="flex-column">
                              <Row>
                                <Col md={3}>
                                  <li className="nav-item animated-background mb-2 mb-lg-0" style={{ height: "40px" }}>
                                    <span style={{ height: "40px" }}></span>
                                  </li>
                                </Col>
                                <Col md={3}>
                                  <li className="nav-item animated-background mb-2 mb-lg-0" style={{ height: "40px" }}>
                                    <span style={{ height: "40px" }}></span>
                                  </li>
                                </Col>
                                <Col md={3}>
                                  <li className="nav-item animated-background mb-2 mb-lg-0" style={{ height: "40px" }}>
                                    <span style={{ height: "40px" }}></span>
                                  </li>
                                </Col>
                                <Col md={3}>
                                  <li className="nav-item animated-background mb-2 mb-lg-0" style={{ height: "40px" }}>
                                    <span style={{ height: "40px" }}></span>
                                  </li>
                                </Col>
                              </Row>
                            </Col>
                            <Col md={3}>
                              <li className="nav-item animated-background" style={{ height: "40px" }}>
                                <span style={{ height: "40px" }}></span>
                              </li>
                            </Col>
                          </Row>
                        </ul>
                      </div>
                    </nav>
                    {/* Navegador Tour */}

                    <div className="row d-flex align-content-end mt-5 mb-4">
                      <div className="animated-background w-50" style={{ height: "56px" }}></div>
                    </div>

                    {/* Conteúdo do passeio */}
                    <h5 className="text-uppercase text-primary" id="experiencia">
                      <FontAwesomeIcon
                        icon={["fal", "smile"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("tour.theExperience")}
                    </h5>
                    <span
                      className="animated-background"
                      style={{ height: "200px", display: "block" }}
                    ></span>

                    <h5 className="text-uppercase text-primary mt-2">
                      <FontAwesomeIcon
                        icon={["fal", "info-circle"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t("tour.informations")}
                    </h5>
                    <span
                      className="animated-background"
                      style={{ height: "200px", display: "block" }}
                    ></span>
                    <section
                      id="modalidades"
                      className=" my-3"
                      data-destine="modalidades"
                    >
                      <h5 className="text-uppercase text-primary pb-4">
                        <FontAwesomeIcon
                          icon={["fal", "list"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        />
                        {t("tour.options")}
                      </h5>
                      <TicketOptionsCaracol
                        options={optionTour}
                        response={tourResponse}
                        action={addToCart}
                      ></TicketOptionsCaracol>
                    </section>
                    {/* Opções do passeio */}

                    <div className="accordion accordion-rules" id="info">
                      <div className="animated-background" style={{ height: "44.8px" }}></div>
                      <div className="animated-background mt-4" style={{ height: "44.8px" }}></div>
                      <div className="animated-background mt-4" style={{ height: "44.8px" }}></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-3 order-1 order-lg-2">
                <CardTourSide options={cardTourSide} />
              </div>
            </div>
          </div>
        </div>
      </PageDafault>
    );
  }
};

export default TicketCaracol;
