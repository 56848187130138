import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TagManager from 'react-gtm-module';

import * as signalR from "@microsoft/signalr";

import './ModalContentPayment.scss';
import { useAppSelector } from '../../../store/hooks';

export interface propContent {
    modalContent: any,
    checkoutResponse: any;
    resetCart: any;
    setModalResponse: any;
    pix: boolean;
}


const ModalContentPayment: React.FC<propContent> = ({
    modalContent, checkoutResponse, resetCart, setModalResponse, pix
}: propContent) => {
    const { t } = useTranslation();

    const [texts, setTexts] = useState<any>();
    const [scanResponse, setScanResponse] = useState<any>(null); // pixSuccess / pixFailed / pixExpired
    const [pixResponse, setPixResponse] = useState<any>();

    const [close, setClose] = useState<boolean>(false);

    const [minutes, setMinutes] = useState<any>(10);
    const [seconds, setSeconds] = useState<any>(0);

    const [isLeave, setIsLeave] = useState<any>(false);

    const cartBPA: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    useEffect(() => {
        if (checkoutResponse?.data?.log === 0 && !pix) {
            var products: any = [];

            window.cart.dados.forEach((elem: any) => {
                products.push({
                    name: elem.productName,
                    id: elem.productCode,
                    price: elem.price,
                    brand: 'Bondinho',
                    quantity: (Number(elem.adults) + Number(elem.childs) + Number(elem.elders) + Number(elem.infants) + Number(elem.student))//(elem.adults + elem.childs + elem.elders)
                })
            });
            TagManager.dataLayer({
                dataLayer: {
                    event: 'cartaodecredito',
                    ecommerce: {
                        checkout: {
                            actionField: { step: 3 },
                            products: products
                        }
                    },
                    event_category: 'Compra',
                    event_label: 'ingresso',
                    value: window.cart.totalCart
                }
            });
        } else if (checkoutResponse?.data?.log === 1 || checkoutResponse?.data?.log === '1') {
            // eslint-disable-next-line @typescript-eslint/no-redeclare
            var products: any = [];

            window.cart.dados.forEach((elem: any) => {
                products.push({
                    name: elem.productName,
                    id: elem.productCode,
                    price: elem.price,
                    brand: 'Bondinho',
                    quantity: (Number(elem.adults) + Number(elem.childs) + Number(elem.elders) + Number(elem.infants) + Number(elem.student))//(elem.adults + elem.childs + elem.elders)
                })
            });
            TagManager.dataLayer({
                dataLayer: {
                    event: 'cartaodecredito',
                    ecommerce: {
                        checkout: {
                            actionField: { step: 3 },
                            products: products
                        }
                    },
                    event_category: 'Compra',
                    event_label: 'ingresso',
                    value: window.cart.totalCart
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutResponse])

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval)
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000)
        return () => {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        let timer1 = setTimeout(() => setTexts(t("modal.loading01")), 1000);
        let timer2 = setTimeout(() => setTexts(t("modal.loading02")), 2000);
        let timer3 = setTimeout(() => setTexts(t("modal.loading03")), 3000);
        let timer4 = setTimeout(() => setTexts(t("modal.loading04")), 4000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (modalContent === 'success' && pix === true) {
            if (!(checkoutResponse.data.log === 1 || checkoutResponse.data.log === '1')) {
                let connection = new signalR.HubConnectionBuilder()
                    .withUrl(process.env.REACT_APP_SERVER_URL_API + '/streaming/')
                    .build();

                connection.on(checkoutResponse.data.data[0].data.channelPix, data => {
                    if (data.log === 0) { //success
                        setPixResponse(data);
                        setScanResponse("pixSuccess");
                    } else if (data.log === 1) { //error
                        setScanResponse("pixFailed");
                    }
                });

                connection.start().then(function () {
                    connection.invoke('JoinToGroup', checkoutResponse.data.data[0].data.channelPix);
                }).catch(function (err) {
                    return console.error(err.toString());
                });
                let closeChannel = setTimeout(() => {
                    if (scanResponse !== 'pixSuccess' || scanResponse !== 'pixFailed') {
                        setScanResponse("pixExpired");
                    }
                    connection.stop();
                    // setScanResponse("pixEmail");
                }, 600000);
                return () => {
                    clearTimeout(closeChannel);
                };
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent])

    function copyText() {
        var text_to_copy = (document.getElementById("qrcodelink") as HTMLElement).innerHTML;

        if (!navigator.clipboard) {

        } else {
            navigator.clipboard.writeText(text_to_copy);
        }
    }

    useEffect(() => {
        if (modalContent === 'success') {
            let booking: any = pix ? checkoutResponse?.data?.data[0]?.data?.bookingLoc : checkoutResponse?.data?.data?.reservationLoc;
            checkoutGA(booking, pix);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutResponse, modalContent]);

    const checkoutGA = useCallback((id: any, isPix: any) => {
        var products: any = [];

        window.cart.dados.forEach((elem: any) => {
            products.push({
                name: elem.productName,
                id: elem.productCode,
                price: elem.price,
                brand: isPix === false ? window.cart.payments.brand : 'Pix',
                category: '',
                quantity: (Number(elem.adults) + Number(elem.childs) + Number(elem.elders) + Number(elem.infants) + Number(elem.student)),
                variant: `Adultos - ${elem.adults} | Crianças - ${elem.childs} | Idosos - ${elem.elders} | Infantos - ${elem.infants} | Estudantes - ${elem.student}`
            })
        });

        TagManager.dataLayer({
            dataLayer: {
                event: 'transaction',
                ecommerce: {
                    purchase: {
                        actionField: { id: id, revenue: window.cart.totalCart },
                        email: cartBPA?.dados[0]?.passengers[0]?.email,
                        products: products
                    }
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent]);

    if (modalContent === 'loading') {
        return (
            <div className="sucess-pos" id="wrapper" >
                <div className="text-center">
                    <div className="load"></div>
                    <h5>{t("modal.finalizingPurchase")}</h5>
                    <p>{texts}</p>
                </div>
            </div>
        );
    } else if (modalContent === 'success') {
        if (checkoutResponse.data.log === 0 && !pix) { //sucesso cartão

            // checkoutGA(checkoutResponse.data.data.reservationLoc);

            return (
                <div className="sucess-pos" id="wrapper" >
                    <div className="text-center">
                        {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
                        <FontAwesomeIcon
                            icon={["fal", "check-circle"]}
                            size="5x"
                        //className='color-primary'
                        />
                        <h4 className='mt-4'>{t("modal.creditCard.success.newOrder")}</h4>
                        <p className="color-success">{t("modal.creditCard.success.purchaseSuccess")}</p>
                        <p>{t("modal.creditCard.success.locNumber")} <strong>{checkoutResponse.data.data.reservationLoc}</strong></p>
                        <p className="mb-0"><small>{t("modal.creditCard.success.voucher")}</small></p>
                        <p><small>{t("modal.creditCard.success.contact")}</small></p>
                        <a href={`mailto:${process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}`}>{process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}</a>

                        <p><small><em>{t("modal.creditCard.success.spam")}</em></small></p>

                    </div>
                    <div className='modal-btn-controller'>
                        {/* {
                            checkoutResponse.data.data.voucherFile === null
                                ?
                                <></>
                                :
                                <a
                                    className='btn btn-primary'
                                    href={`data:${checkoutResponse.data.data.voucherFile.contentType};base64, ${checkoutResponse.data.data.voucherFile.content}`}
                                    download={checkoutResponse.data.data.voucherFile.fileName}
                                >
                                    Download de bilhete
                                </a>
                        } */}
                        <Button variant="outline-primary" className="my-3 mx-auto" onClick={() => { resetCart() }}>
                            {t("modal.creditCard.success.closeBtn")}
                        </Button>
                    </div>
                </div>
            );
        } else if (checkoutResponse.data.log === 2 && !pix) {
            return (
                <div className="sucess-pos" id="wrapper" >
                    <div className="text-center">
                        {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
                        <FontAwesomeIcon
                            icon={["fal", "check-circle"]}
                            size="5x"
                        //className='color-primary'
                        />
                        <h4 className='mt-4'>{t("modal.creditCard.success.newOrderLog2")}</h4>
                        <p className="color-success">{t("modal.creditCard.success.purchaseSuccessLog2")}</p>
                        <p><strong>{checkoutResponse.data.texto}</strong></p>
                        <p className="mb-0"><small>{t("modal.creditCard.success.voucherLog2")}</small></p>
                        <p><small>{t("modal.creditCard.success.contactLog2")}</small></p>
                        <a href={`mailto:${process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}`}>{process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}</a>

                        <p><small><em>{t("modal.creditCard.success.spam")}</em></small></p>

                    </div>
                    <div className='modal-btn-controller'>
                        {
                            close === false
                                ?
                                <Button variant="outline-primary" className="px-3 my-3 mx-auto"/*  onClick={() => { resetCart() }} */ onClick={() => setClose(true)}>
                                    {t("modal.creditCard.success.closeBtn")}
                                </Button>
                                :
                                <div>
                                    <small>{t("modal.creditCard.success.valBtn")}</small>
                                    <div className='d-flex'>
                                        <Button variant="outline-primary" className="px-4 my-3 mx-auto"/*  onClick={() => { resetCart() }} */ onClick={() => setClose(false)}>
                                            {t("modal.creditCard.success.no")}
                                        </Button>
                                        <Button variant="outline-primary" className="px-4 my-3 mx-auto"/*  onClick={() => { resetCart() }} */ onClick={() => {
                                            resetCart()
                                            setClose(false)
                                        }}>
                                            {t("modal.creditCard.success.yes")}
                                        </Button>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            )
        } else if (checkoutResponse.data.log === 1 || checkoutResponse.data.log === '1') { // Erro
            return (

                <div className="sucess-pos" id="wrapper" >s
                    <div className="text-center">
                        <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                        />
                        <h4 className='mt-4'>{t("modal.creditCard.error")}</h4>
                        <p>{checkoutResponse.data.texto}</p>
                    </div>
                    <div className='modal-btn-controller'>
                        <Button className="my-5 mx-auto" onClick={() => { setModalResponse(null) }}>
                            {t("modal.creditCard.success.closeBtn")}
                        </Button>
                    </div>
                </div>
            );
        } else if (pix === true) { //sucesso pix
            const pixInfo = checkoutResponse.data.data[0].data;

            if (scanResponse === null) {
                return (
                    <>
                        <div className="pix-container">
                            <img src="" alt="" />
                            <h6>{t("modal.pix.pixText01")}</h6>
                            <p>{t("modal.pix.pixText02")}</p>
                            <h4>{t("modal.pix.qrCode")}</h4>
                            <img
                                src={("data:image/png;base64," + pixInfo.urlQrCode).toString()}
                                className="qr-code"
                                alt="QR Code"
                            />
                            <div className="pix-separator">
                                <hr />
                                <p>{t("modal.pix.or")}</p>
                            </div>
                            {/* <h4>{t("modal.pix.pixCopy")} {"&"} {t("modal.pix.pixPaste")}</h4> */}
                            <div>
                                <p id="qrcodelink" className='d-none'>{pixInfo.urlCopyAndPaste}</p>
                                <button className='btn text-uppercase btn-pix' onClick={copyText}><strong>COPIAR CÓDIGO PIX</strong></button>
                            </div>
                            <div>
                                <small>Dir. Creditório dado em garantia p/Vórtx</small>
                            </div>

                            <p className='text-align-center mb-0'>{t("modal.pix.expire")}<br />
                                {minutes === 0 && seconds === 0
                                    ? null
                                    : <h2> {minutes}:{seconds < 10 ? `0${seconds}` : seconds}</h2>
                                }
                            </p>

                            <div className='pix-copy-paste mt-1 mb-4'>
                                <p className='text-align-center my-1'>
                                    Por favor aguarde, PIX em processamento.
                                </p>
                            </div>

                            {
                                /* Validar o fechamento do modal */
                                isLeave === false
                                    ?
                                    <Button variant="outline-primary" className="mb-3 mx-auto" onClick={() => { setIsLeave(true) }}>
                                        {t("modal.creditCard.success.closeBtn")}
                                    </Button>
                                    :
                                    <div>
                                        <p className='mt-0'>Tem certeza que deseja fechar?</p>
                                        <div className='d-flex justify-content-center gap-3 '>
                                            <Button variant="outline-primary" onClick={() => { setIsLeave(false) }}>
                                                Não
                                            </Button>
                                            <Button variant="outline-primary" onClick={() => { resetCart() }}>
                                                Sim
                                            </Button>
                                        </div>
                                    </div>
                                /* END - Validar o fechamento do modal */
                            }
                        </div>
                    </>
                );
            } else if (scanResponse === "pixSuccess") {
                // checkoutGA(checkoutResponse.data.data.reservationLoc, true);
                return (
                    <>
                        <div className="sucess-pos" id="wrapper" >
                            <div className="text-center">
                                {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                //className='color-primary'
                                />
                                <h4 className='mt-4'>{t("modal.creditCard.success.newOrder")}</h4>
                                <p className="color-success">{t("modal.creditCard.success.purchaseSuccess")}</p>
                                <p>{t("modal.creditCard.success.locNumber")} <strong>{pixResponse?.data?.reservationLoc}</strong></p>
                                <p className="mb-0"><small>{t("modal.creditCard.success.voucher")}</small></p>
                                <p><small>{t("modal.creditCard.success.contact")}</small></p>
                                <a href={`mailto:${process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}`}>{process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}</a>


                                <p><small><em>{t("modal.creditCard.success.spam")}</em></small></p>

                            </div>
                            <div className='modal-btn-controller'>
                                {/* {
                                    pixResponse.data.voucherFile === null
                                        ?
                                        <></>
                                        :
                                        <a
                                            className='btn btn-primary'
                                            href={`data:${pixResponse.data.voucherFile.contentType};base64, ${pixResponse.data.voucherFile.content}`}
                                            download={pixResponse.data.voucherFile.fileName}
                                        >
                                            Download de bilhete
                                        </a>
                                } */}
                                <Button variant="outline-primary" className="my-3 mx-auto" onClick={() => { resetCart() }}>
                                    {t("modal.creditCard.success.closeBtn")}
                                </Button>
                                {/* {
                                    close === false
                                        ?
                                        <Button variant="outline-primary" className="px-3 my-3 mx-auto" onClick={() => setClose(true)}>
                                            {t("modal.creditCard.success.closeBtn")}
                                        </Button>
                                        :
                                        <div>
                                            <small>Tem certeza que deseja sair?</small>
                                            <div className='d-flex'>
                                                <Button variant="outline-primary" className="px-4 my-3 mx-auto" onClick={() => setClose(false)}>
                                                    Não
                                                </Button>
                                                <Button variant="outline-primary" className="px-4 my-3 mx-auto" onClick={() => {
                                                    resetCart()
                                                    setClose(false)
                                                }}>
                                                    Sim
                                                </Button>
                                            </div>
                                        </div>
                                } */}
                            </div>
                        </div>
                    </>
                );
            } else if (scanResponse === "pixFailed") {
                return (
                    <>
                        <div className="sucess-pos" id="wrapper" >
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                //className='color-danger'
                                />
                                <h4 className='mt-4'>{t("modal.pix.pixFailed.text01")}</h4>
                                <p>{t("modal.pix.pixFailed.text02")}<br />
                                    {t("modal.pix.pixFailed.text03")} <a href={`mailto:${process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}`}>{process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}</a></p>
                            </div>
                            <div className='modal-btn-controller'>
                                <Button className="my-5 mx-auto" onClick={() => { setModalResponse(null) }}>
                                    {t("modal.creditCard.success.closeBtn")}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            } else if (scanResponse === "pixExpired") {
                return (
                    <>
                        <div className="sucess-pos" id="wrapper" >
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={["fal", "exclamation-circle"]}
                                    size="5x"
                                //className='color-info'
                                />
                                <h4 className='mt-4'>{t("modal.pix.expired")}</h4>
                                <p className="mb-0"><small>{t("modal.pix.expiredWarning")}</small></p>
                            </div>
                            <div className='modal-btn-controller'>
                                <Button className="my-5 mx-auto" onClick={() => { resetCart() }}>
                                    {t("modal.creditCard.success.closeBtn")}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            } else if (scanResponse === "pixEmail") {
                return (
                    <>
                        <div className="sucess-pos" id="wrapper" >
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={["fal", "exclamation-circle"]}
                                    size="5x"
                                // className='color-info'
                                />
                                <h4 className='mt-4'>Pagamento Pix</h4>
                                <p className="mb-0">Os dados do pagamento foram enviados para o email da compra.</p>
                                <br />
                                <br />
                                <p className="mb-0"><small>Não esqueça de verificar a caixa de spam </small></p>
                            </div>
                            <div className='modal-btn-controller'>
                                <Button className="my-5 mx-auto" onClick={() => { resetCart() }}>
                                    {t("modal.creditCard.success.closeBtn")}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            } else {
                return (<></>);
            }
        } else {
            return (<></>);
        }

    } else if (modalContent === 'error') {
        if (checkoutResponse.data.log === 1 || checkoutResponse.data.log === '1') { // Erro
            return (
                <div className="sucess-pos" id="wrapper" >
                    <div className="text-center">
                        <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                        />
                        <h4 className='mt-4'>{t("modal.creditCard.error")}</h4>
                        <p>{checkoutResponse.data.texto}</p>
                    </div>
                    <div className='modal-btn-controller'>
                        <Button className="my-5 mx-auto" onClick={() => { setModalResponse(null) }}>
                            {t("modal.creditCard.success.closeBtn")}
                        </Button>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="sucess-pos" id="wrapper" >
                    <div className="text-center">
                        <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                        //className='color-danger'
                        />
                        <h4 className='mt-4'>{t("modal.pix.pixFailed.text01")}</h4>
                        <p>{t("modal.pix.pixFailed.text02")}<br />
                            {t("modal.pix.pixFailed.text03")} <a href={`mailto:${process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}`}>{process.env.REACT_APP_INFO_EMAIL_ATENDIMENTO}</a></p>
                    </div>
                    <div className='modal-btn-controller'>
                        <Button className="my-5 mx-auto" onClick={() => { setModalResponse(null) }}>
                            {t("modal.creditCard.success.closeBtn")}
                        </Button>
                    </div>
                </div>
            );
        }

    } else {
        return (<></>)
    }
}

export default ModalContentPayment;