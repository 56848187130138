import React from 'react';

function TermsOfUseCaracolPT() {
  return (
    <>
      <p>
        Bem-vindo(a)! Obrigado por utilizar o site do <b>“PARQUE DO CARACOL”</b>
        !
      </p>

      <p>
        Esta aplicação e este Termos e Condições de Uso (“Termo”) rege seu
        acesso e uso do site, dentro do Brasil, disponibilizados, controlados e
        operados pelo{' '}
        <b>
          PARQUE CARACOL - NOVO CARACOL E TAINHAS S/A. (“CARACOL” ou “PARQUE DO
          CARACOL”)
        </b>
        , com sede na Rua Batista Luzardo, 167 – Loja 02, Centro – Canela/RS,
        CEP: 95.680-000.
      </p>

      <p>
        Este Termo tem por objeto definir as regras a serem seguidas para a
        utilização da aplicação/site do PARQUE DO CARACOL”, sem prejuízo da
        aplicação da legislação vigente.
      </p>

      <p>
        <b>
          IMPORTANTE LEMBRAR QUE, AO UTILIZAR A APLICAÇÃO PARQUE DO CARACOL”,
          VOCÊ TERÁ A OPÇÃO DE ACEITAÇÃO E CIÊNCIA DESTE TERMO, ATRAVÉS DE UM
          “CHECKBOX”, PREVIAMENTE DESMARCADO, PARA QUE VOCÊ O MARQUE OU NÃO,
          LIVREMENTE. EM CASO DE OPTAR PELA MARCAÇÃO, VOCÊ ESTÁ CIENTE DESTE
          TERMO E SUAS REGRAS, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E
          QUAISQUER ATOS PRATICADOS POR VOCÊ NA APLICAÇÃO. CASO VOCÊ NÃO ACEITE
          E/OU NÃO CONCORDE COM ESTE TERMO, POR FAVOR, NÃO ACESSE/UTILIZE ESTA
          APLICAÇÃO.
        </b>
      </p>

      <p>
        Ressaltamos que as disposições contidas neste termo podem ser
        atualizadas a qualquer momento, sendo responsabilidade do usuário
        consultá-las sempre que for interagir com a aplicação.
      </p>
      <br />

      <strong>1. QUEM SOMOS?</strong>
      <br />

      <p>
        O Parque Estadual do Caracol é uma unidade de conservação de 25,10
        hectares, no Estado do Rio Grande do Sul, no Município de Canela.
      </p>

      <p>
        Sua principal atração é um verdadeiro espetáculo da natureza: a Cascata
        do Caracol, com uma queda livre de 131 metros, pode ser apreciada em
        mirantes e no observatório, que proporcionam uma vista panorâmica
        privilegiada.
      </p>

      <p>
        O Parque foi oficialmente fundado em 1973, porém, desde 1954, quando o
        Governo do Estado do Rio Grande do Sul decretou de utilidade pública as
        terras onde se encontra a Cascata do Caracol, houve um aumento gradativo
        do número de visitantes ao local, o que motivou a implementação de uma
        infraestrutura como a portaria, restaurante, os sanitários públicos, o
        estacionamento interno e o mirante da cascata. Desde dezembro de 2022, o
        Parque está sob concessão do Consórcio Novo Caracol e Tainhas.
      </p>

      <p>
        Além dos serviços principais, o Parque do Caracol proporciona caminhadas
        em trilhas ecológicas, passeio de trenzinho pela Estação do Sonho Vivo,
        visitação ao Observatório – um mirante que fica a uma altura de 27m, com
        uma visão 360º do parque, além do Centro Histórico, playground, quadras
        esportivas e lojas.
      </p>
      <br />

      <strong>2. O QUE FAZEMOS?</strong>
      <br />

      <p className="px-3">
        <b>a)</b> Venda de bilhetes de acesso ao <b>“PARQUE DO CARACOL”</b>;
      </p>

      <p className="px-3">
        <b>b)</b> Venda de tours dentro do <b>“PARQUE DO CARACOL”</b>;
      </p>

      <p className="px-3">
        <b>c)</b> Informações e histórias sobre o Parque;
      </p>

      <p className="px-3">
        <b>d)</b> Acesso a vagas da empresa;
      </p>

      <p className="px-3">
        <b>e)</b> Candidatura Candidatura a vagas;
      </p>

      <p className="px-3">
        <b>f)</b> Canal de contato com a empresa;
      </p>

      <p className="px-3">
        <b>g)</b> Canal de contato sobre locações para eventos, mídia, ações
        comerciais.
      </p>
      <br />

      <strong>3. COMO VOCÊ ACESSA O SITE DO PARQUE DO CARACOL”?</strong>
      <br />

      <p className="px-3">
        <b>3.1.</b> A navegação na aplicação do <b>“PARQUE DO CARACOL”</b>, com
        status de Usuário, se aplica a usuários que desejam comprar bilhetes de
        acesso ao Parque, além de outros produtos e serviços oferecidos, como
        aos que desejam se candidatar a vagas na empresa desejam saber sobre
        mídia, eventos e locações no Parque.
      </p>

      <p className="px-3">
        <b>3.2.</b> Para acessar o site do <b>“PARQUE DO CARACOL”</b> e utilizar
        suas funcionalidades, é necessário efetuar o cadastro do usuário para
        criação de login e senha de acesso. Para se cadastrar, o usuário deverá
        se direcionar a área de cadastro e preencher o formulário informando:
        e-mail, nome completo, país, estado, CPF, passaporte (caso seja
        estrangeiro) celular e telefone fixo. Após o preenchimento dos campos,
        basta clicar no botão cadastrar.
      </p>

      <p className="px-3">
        <b>3.3.</b> Para o seu acesso, poderemos solicitar informações sobre os
        seus dados pessoais, conforme descrito em nossa{' '}
        <a href="/aviso-de-privacidade" target="_blank">
          Aviso de privacidade
        </a>
        .
      </p>

      <p className="px-3">
        <b>3.4.</b> Para efetuar compra na aplicação/site do{' '}
        <b>“PARQUE DO CARACOL”</b>, você deverá ter pelo menos 18 (dezoito)
        anos. O acesso deverá ser realizado somente por você, titular de uma
        conta. Caso seja verificado irregularidade ou informações falsas pelo
        usuário, o acesso ou conta poderá ser bloqueado.
      </p>

      <p className="px-3">
        <b>3.5.</b> Os conteúdos fornecidos dentro do site pelo usuário serão ou
        poderão estar protegidos por criptografia.
      </p>

      <p className="px-3">
        <b>3.6.</b> Em caso de suspeita de fraude, obtenção de benefício ou
        vantagem de forma ilícita, ou pelo não cumprimento de quaisquer
        condições previstas nestes Termos de Uso <b>“PARQUE DO CARACOL”</b>, se
        reserva do direito de suspender ou cancelar, a qualquer momento, o seu
        acesso à aplicação, não sendo devido qualquer indenização a você.
        Cabendo o <b>“PARQUE DO CARACOL”</b> o direito de promover ação de
        regresso, se necessário, pelo não cumprimento de quaisquer condições
        previstas nestes Termos de Uso.
      </p>
      <br />

      <strong>4. RESPONSABILIDADES DO USUÁRIO</strong>
      <br />

      <p className="px-3">
        <b>4.1.</b> O usuário é exclusivamente responsável pelo uso da aplicação
        e deverá respeitar as regras deste Termo, bem como a legislação
        aplicável.
      </p>

      <p className="px-3">
        <b>4.2.</b> O usuário garante a veracidade de todos os dados informados,
        sendo responsável por manter as suas informações pessoais atualizadas.
      </p>

      <p className="px-3">
        <b>4.3.</b> O <b>“PARQUE DO CARACOL”</b> não será responsabilizado por
        danos diretos ou indiretos que resultem de, ou que tenham relação com o
        acesso, uso ou a incapacidade de acessar ou utilizar a aplicação.
      </p>

      <p className="px-3">
        <b>4.4.</b> É responsabilidade do usuário manter o seu login e senha
        seguros e fora do alcance de terceiros. Dessa forma, o usuário
        responsabiliza-se por todas as ações realizadas em sua conta.
      </p>

      <p className="px-3">
        <b>4.5.</b> Tendo em vista as características inerentes ao ambiente da
        internet, a aplicação não se responsabiliza por interrupções ou
        suspensões de conexão, transmissões de computador incompletas ou que
        falhem, bem como por falha técnica de qualquer tipo, incluindo, mas não
        se limitando, ao mau funcionamento eletrônico de qualquer rede, hardware
        ou software.
      </p>

      <p className="px-3">
        <b>4.6.</b> É responsabilidade do usuário manter o ambiente de seu
        dispositivo (computador, celular, tablet, entre outros) seguro, com o
        uso de ferramentas disponíveis para garantir segurança.
      </p>

      <p className="px-3">
        <b>4.7.</b> O usuário declara que é civilmente capaz. Em caso de
        eventual acesso de usuário menor de 18 (dezoito) anos, com ou sem
        autorização do responsável legal, este será responsável por todos os
        atos por aquele praticados, como consta em lei.
      </p>
      <br />

      <strong>5. PRIVACIDADE DO USUÁRIO</strong>
      <br />

      <p className="px-3">
        <b>5.1.</b> O <b>“PARQUE DO CARACOL”</b>” se compromete a processar as
        suas informações e os seus dados pessoais em conformidade com a Lei
        Geral de Proteção de dados (LGPD) e de acordo com a nossa{' '}
        <a href="/aviso-de-privacidade" target="_blank">
          Aviso de privacidade
        </a>
        .
      </p>

      <p className="px-3">
        <b>5.2.</b> O{' '}
        <a href="/aviso-de-privacidade" target="_blank">
          Aviso de privacidade
        </a>{' '}
        é parte integrante e inseparável deste Termo de Uso.
      </p>
      <br />

      <strong>6. COMO SÃO REALIZADOS OS PAGAMENTOS</strong>
      <br />

      <p className="px-3">
        <b>6.1.</b> Os pagamentos efetuados para a compra on-line dos bilhetes
        no <b>“PARQUE DO CARACOL”</b>, deverão ser realizados dentro da
        aplicação, por meio de cartão de crédito ou PIX, sendo coletados os
        seguintes dados pessoais: E-mail, CPF, Passaporte, País, Estado,
        telefone, dados do cartão de crédito, no caso do pagamento em cartão.
      </p>

      <p className="px-3">
        <b>6.2.</b> A confirmação do pagamento por meio da aplicação dependerá
        de cada operadora de cartão de crédito.
      </p>

      <p className="px-3">
        <b>6.3.</b> O <b>“PARQUE DO CARACOL”</b> reserva-se o direito de
        estabelecer, remover e/ou revisar o preço relativo a todos os serviços
        ou bens obtidos por meio do uso da aplicação a qualquer momento.
      </p>
      <br />

      <strong>
        7. PROPRIEDADE INTELECTUAL E DEMAIS DIREITOS DO{' '}
        <b>“PARQUE DO CARACOL”</b>
      </strong>
      <br />

      <p className="px-3">
        <b>7.1.</b> Todos os direitos relativos ao <b>“PARQUE DO CARACOL”</b> e
        suas funcionalidades, produzidas direta ou indiretamente, são de
        propriedade exclusiva da aplicação.
      </p>

      <p className="px-3">
        <b>7.2.</b> O <b>“PARQUE DO CARACOL”</b> garante que todos os direitos,
        títulos e interesses (incluindo, mas não se limitando, aos direitos
        autorais, e outros de propriedade intelectual) sobre os serviços
        disponibilizados permanecerão sob a titularidade da aplicação.
      </p>

      <p className="px-3">
        <b>7.3.</b> O usuário não adquirirá nenhum direito de propriedade sobre
        os serviços e conteúdo do <b>“PARQUE DO CARACOL”</b>.
      </p>

      <p className="px-3">
        <b>7.4.</b> É proibido usar, copiar, reproduzir, modificar, traduzir,
        publicar, transmitir, distribuir, executar, fazer o upload, exibir,
        licenciar, vender ou explorar e fazer engenharia reversa do Conteúdo do{' '}
        <b>“PARQUE DO CARACOL”</b> sem o consentimento prévio e expresso da
        aplicação. Qualquer uso não autorizado do Conteúdo do{' '}
        <b>“PARQUE DO CARACOL”</b> será considerado como violação dos direitos
        autorais e de propriedade intelectual.
      </p>

      <p className="px-3">
        <b>7.5.</b> É proibido que o usuário faça o download de nosso conteúdo
        com o intuito de armazená-lo em banco de dados para oferecer para
        terceiro. Veda-se, também, que o conteúdo disponibilizado por nós seja
        usado para criar uma base de dados ou um serviço que possa concorrer de
        qualquer maneira com o nosso negócio.
      </p>
      <br />

      <strong>8. VIOLAÇÃO DE DIREITO AUTORAL</strong>
      <br />

      <p className="px-3">
        <b>8.1.</b> Alegações de infringência de direito autoral de qualquer
        conteúdo disponível na aplicação devem ser encaminhadas por meio do
        e-mail:{' '}
        <a href="mailto: dpo@novocaracoltainhas.com.br">
          dpo@novocaracoltainhas.com.br
        </a>
      </p>
      <br />

      <strong>9. MONITORAMENTO E SEGURANÇA</strong>
      <br />

      <p className="px-3">
        <b>9.1.</b> O <b>“PARQUE DO CARACOL”</b> não mede esforços para garantir
        a privacidade e segurança da sua aplicação no ambiente da internet.
      </p>

      <p className="px-3">
        <b>9.2.</b> Nosso site conta com medidas de segurança comercialmente
        disponíveis no setor. Além disso, o processo de pagamento funciona
        através de um servidor seguro, utilizando-se o protocolo SSL (Secure
        Socket Layer). O servidor seguro estabelece uma conexão de modo que a
        informação transmitida é criptografada, assegurando que somente seja
        inteligível para o seu computador e o servidor do{' '}
        <b>“PARQUE DO CARACOL”</b>.
      </p>

      <p className="px-3">
        <b>9.3.</b> Ao aceitar o presente Termo, você aceita submeter-se ao
        procedimento de detecção de possíveis operações fraudulentas e, da mesma
        forma, autoriza o <b>“PARQUE DO CARACOL”</b> para que, uma vez fornecida
        a informação e documentos adicionais antes referidos, estes sejam
        utilizados para transações futuras.
      </p>

      <p className="px-3">
        <b>9.4.</b> Para mais informações acerca do armazenamento e tratamento
        de informações verifique nossa{' '}
        <a href="/aviso-de-privacidade" target="_blank">
          Aviso de privacidade
        </a>
        .
      </p>
      <br />

      <strong>
        10. AVALIAÇÕES, COMENTÁRIOS, COMUNICAÇÕES E OUTRO CONTEÚDO
      </strong>
      <br />

      <p className="px-3">
        <b>10.1.</b> Os visitantes da Plataforma do <b>“PARQUE DO CARACOL”</b>{' '}
        poderão postar avaliações, comentários, fotos e outros conteúdos, enviar
        comunicações, sugestões, ideias, comentários, perguntas ou outras
        informações, desde que o conteúdo não seja ilegal, obsceno, ameaçador,
        difamatório, invada a privacidade, infrinja direitos autorais ou de
        propriedade intelectual ou que de outro modo seja prejudicial a
        terceiros ou passível de questionamentos, e desde que não consista em ou
        contenha vírus de software, campanhas políticas, solicitações
        comerciais, correntes, correio em massa ou qualquer outra forma de
        "spam".
      </p>

      <p className="px-3">
        <b>10.2.</b> Você não poderá utilizar um endereço de e-mail falso,
        fingir ser outra pessoa física ou jurídica ou de qualquer outro modo
        prestar informações falsas sobre a origem de conteúdo. O{' '}
        <b>“PARQUE DO CARACOL”</b> reserva-se o direito (mas não a obrigação) de
        remover ou editar esse conteúdo.
      </p>

      <p className="px-3">
        <b>10.3.</b> Nós não nos responsabilizamos por qualquer dano (indiretos,
        emergentes, punitivos, lucros cessantes ou outros prejuízos), seja de
        qualquer natureza, que possa advir a terceiros em decorrência de conduta
        imprópria, ofensiva ou contrária à legislação vigente e às orientações
        de uso e manuseio dos produtos/serviços pelo usuário.
      </p>
      <br />

      <strong>11. CANAL DE COMUNICAÇÃO</strong>
      <br />

      <p className="px-3">
        <b>11.1.</b> Caso você tenha alguma dúvida, comentário ou sugestão, por
        favor, entre em contato conosco por meio do e-mail:{' '}
        <a href="mailto:sac@cnct.com.br">sac@cnct.com.br</a>
      </p>

      <p className="px-3">
        <b>11.2.</b> E caso de dúvidas, para exercer os seus direitos de titular
        dos dados ou qualquer comunicação, O “PARQUE DO CARACOL”, em cumprimento
        ao artigo 41 da Lei Geral de Proteção de Dados, indica o contato da
        Encarregada de Dados Pessoais, Louana Costa através do e- mail:{' '}
        <a href="mailto:dpo@novocaracoltainhas.com.br">
          dpo@novocaracoltainhas.com.br
        </a>
      </p>
      <br />

      <strong>12. DISPOSIÇÕES GERAIS</strong>
      <br />

      <p className="px-3">
        <b>12.1.</b> O <b>“PARQUE DO CARACOL”</b> é titular das plataformas
        www.parquecaracol.com.br e detém todos os direitos sobre a aparência,
        design, funcionalidade e todos os demais aspectos das plataformas, sendo
        que tais direitos não poderão ser utilizados, reproduzidos e/ou copiados
        sem sua expressa autorização.
      </p>

      <p className="px-3">
        <b>12.2.</b> Ocasionalmente a plataforma do <b>“PARQUE DO CARACOL”</b>{' '}
        poderá disponibilizar links de acesso a sites de empresas terceiras,
        relacionadas ou não ao <b>“PARQUE DO CARACOL”</b>. Destacamos que não
        somos responsáveis por examinar o conteúdo desses sites e nem por
        eventuais ofertas de quaisquer dessas empresas. Cabe ao Usuário
        analisar, sempre, as políticas de privacidade e condições de uso destes
        terceiros.
      </p>

      <p className="px-3">
        <b>12.3.</b> As Partes declaram e concordam que o aceite deste Termo
        será efetuado em formato eletrônico. As Partes reconhecem a veracidade,
        autenticidade, integridade, validade e eficácia deste Contrato e seus
        termos, incluindo seus anexos, nos termos do art. 219 do Código Civil,
        em formato eletrônico e/ou assinado pelas Partes por meio de
        certificados eletrônicos, ainda que certificados eletrônicos não
        emitidos pela ICP-Brasil, nos termos do art. 10, § 2º, da Medida
        Provisória nº 2.200-2, de 24 de agosto de 2001 (“MP nº 2.200-2”).
      </p>

      <p className="px-3">
        <b>12.4.</b> Eventual omissão ou atraso de qualquer das Partes em exigir
        o cumprimento de qualquer termo ou condição do presente Termo pela outra
        Parte, ou em exercer qualquer direito, prerrogativa ou recurso aqui
        previsto, não constituirá novação nem implicará renúncia da
        possibilidade futura de exigir o cumprimento de tal termo, condição,
        direito, prerrogativa ou recurso.
      </p>

      <p className="px-3">
        <b>12.5.</b> Cada cláusula, item, alínea, período e frase deste Termo
        constitui um compromisso ou disposição independente e distinto. Sempre
        que possível, cada cláusula deste Termo deverá ser interpretada de modo
        a se tornar válida e eficaz à luz da lei aplicável. Caso alguma das
        cláusulas deste Termo seja considerada ilícita, dita cláusula deverá ser
        julgada separadamente do restante do Termo, e substituída por cláusula
        lícita e similar, que reflita as intenções originais, observando-se os
        limites da lei, e todas as demais cláusulas continuarão em pleno vigor.
      </p>

      <p className="px-3">
        <b>12.6.</b> O <b>“PARQUE DO CARACOL”</b> se reserva no direito de, a
        qualquer momento e a seu exclusivo critério, modiﬁcar as cláusulas do
        presente Termo, cabendo ao Usuário verificar seu conteúdo sempre que
        acessar às plataformas.
      </p>

      <p className="px-3">
        <b>12.7.</b> De igual maneira, o <b>“PARQUE DO CARACOL”</b> se reserva
        no direito de, a qualquer momento e a seu exclusivo critério,
        descontinuar, suspender, terminar ou alterar a forma de acesso ao seu
        endereço eletrônico com relação a qualquer conteúdo, período de
        disponibilidade e equipamentos necessários ao acesso e/ou utilização do
        mesmo, sem necessidade de prévia notificação ao usuário.
      </p>
      <br />

      <strong>13. LEI APLICÁVEL / FORO</strong>
      <br />

      <p className="px-3">
        <b>13.1.</b> Este Termo, é regido e interpretado de acordo com as Leis
        da República Federativa do Brasil.
      </p>

      <p className="px-3">
        <b>13.2.</b> Fica eleito o Foro da Comarca do Rio Grande do Sul, como o
        competente para dirimir quaisquer questões porventura oriundas do
        presente documento, com expressa renúncia a qualquer outro, por mais
        privilegiado que seja.
      </p>
      <br />

      <strong>14. ATUALIZAÇÕES DESTA POLÍTICA</strong>
      <br />

      <p className="px-3">
        <b>14.1.</b> O <b>“PARQUE DO CARACOL”</b> se reserva no direito de
        alterar este Termo de Uso sempre que for necessário, para garantir a
        funcionalidade, aprimoramentos e atualizações dos serviços oferecidos,
        não ficando obrigado a manter uma estrutura da aplicação.
      </p>
      <br />

      <p className="pb-2">
        Nosso Termo de Uso sempre contará a indicação da data de atualização ou
        versão atual.
      </p>

      <dl>
        <dd>
          <table className="table-page" width="100%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="55"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Versão</strong>
                    </span>
                  </p>
                </td>
                <td width="156" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Item modificado</strong>
                    </span>
                  </p>
                </td>
                <td width="299" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Motivo</strong>
                    </span>
                  </p>
                </td>
                <td width="113" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Data</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>01</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Versão original</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>N/A</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>18/01/2023</span>
                  </p>
                </td>
              </tr>
              {/* <tr>
                                <td width='55' height='3'>
                                    <br />
                                </td>
                                <td width='116'>
                                    <br />
                                </td>
                                <td valign='top' width='299'>
                                    <br />
                                </td>
                                <td valign='top' width='113'>
                                    <br />
                                </td>
                            </tr> */}
            </tbody>
          </table>
        </dd>
      </dl>
    </>
  );
}

export default TermsOfUseCaracolPT;
