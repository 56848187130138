import i18next from 'i18next';
import React from 'react';

import { useTranslation } from "react-i18next";

import PageDafault from '../../../templates/PageDafault';
import TermsOfUseCaracolPT from './components/TermsOfUseCaracolPT/TermsOfUseCaracolPT';

import '../TermsOfUse.scss';
import TermsOfUseCaracolEN from './components/TermsOfUseCaracolEN/TermsOfUseCaracolEN';
import TermsOfUseCaracolES from './components/TermsOfUseCaracolES/TermsOfUseCaracolES';

function TermsOfUseCaracol() {
    const { t } = useTranslation();

    return (
        <>
            <PageDafault>
                <div className="container py-5">
                    <h1 className="mb-5 text-center display-2">{t('termsOfUse.title')}</h1>
                    <div className="card py-4 px-3">
                        {
                            i18next.language === "pt"
                                ?
                                <TermsOfUseCaracolPT />
                                :
                                i18next.language === "en"
                                    ?
                                    <TermsOfUseCaracolEN />
                                    :
                                    i18next.language === "es"
                                        ?
                                        <TermsOfUseCaracolES />
                                        :
                                        <TermsOfUseCaracolPT />
                        }
                    </div>
                </div>
            </PageDafault >
        </>
    );
}

export default TermsOfUseCaracol;