import React from 'react';

function TermsOfUseCaracolEN() {
  return (
    <>
      <p>
        Welcome! Thank you for using <b>“CARACOL PARK”</b> website!
      </p>

      <p>
        This application and this Terms and Conditions of Use (“Term”) rules
        your access and use of the website, within Brazil, made available,
        controlled and operated by{' '}
        <b>
          CARACOL PARK - NOVO CARACOL E TAINHAS S/A. (“CARACOL” or “CARACOL
          PARK”)
        </b>
        , headquartered at Rua Batista Luzardo, 167 – Loja 02, Centro –
        Canela/RS, CEP: 95.680-000.
      </p>

      <p>
        The purpose of this Term is to define the rules to be followed for the
        use of the <b>“CARACOL PARK”</b>, application/website without
        compromising the application of current legislation.
      </p>

      <p>
        <b>
          IT IS IMPORTANT TO REMEMBER THAT WHEN USING “CARACOL PARK"
          APPLICATION, YOU WILL HAVE THE OPTION OF ACCEPTANCE AND AWARENESS OF
          THIS AGREEMENT, THROUGH A PREVIOUSLY UNCHECKED "CHECKBOX", SO THAT YOU
          CAN CHECK IT OR NOT. IN CASE OF CHOOSING TO CHECK IT, YOU ARE AWARE OF
          THIS AGREEMENT AND ITS RULES, BEING FULLY RESPONSIBLE FOR ANY AND ALL
          ACTS PERFORMED BY YOU IN THE APPLICATION. IF YOU DO NOT ACCEPT AND/OR
          DO NOT AGREE WITH THESE TERMS, PLEASE DO NOT ACCESS/USE THIS
          APPLICATION.
        </b>
      </p>

      <p>
        We emphasize that the details contained in this term can be updated at
        any time, being the user's responsibility to consult them whenever
        interacting with the application.
      </p>
      <br />

      <strong>1. WHO WE ARE?</strong>
      <br />

      <p>
        Caracol State Park is a 25.10 hectare conservation unit in the state of
        Rio Grande do Sul, in the municipality of Canela.
      </p>

      <p>
        Its main attraction is a true spectacle of nature: the Caracol
        Waterfall, with a free fall of 131 meters, that can be enjoyed from
        lookouts and the observatory, which provide a privileged panoramic view.
      </p>

      <p>
        The Park was officially founded in 1973, however, since 1954, when the
        Government of the State of Rio Grande do Sul declared public utility the
        land where Caracol Waterfall is located, there has been a gradual
        increase in the number of visitors to the site, which motivated the
        implementation of an infrastructure such as an entrance, restaurant,
        public toilets, indoor parking, and the waterfall observation deck.
        Since December 2022, the Park has been under concession by Consórcio
        Novo Caracol e Tainhas.
      </p>

      <p>
        In addition to the main services, CARACOL PARK offers walks on
        ecological trails, a train ride through the Estação do Sonho Vivo,
        visits to the Observatory – a lookout point at a height of 27m, with a
        360º view of the park, in addition to the Historic Center, playground,
        sports courts and shops.
      </p>
      <br />

      <strong>2. WHAT DO WE DO?</strong>
      <br />

      <p className="px-3">
        <b>a)</b> Sale of access tickets to the <b>“CARACOL PARK”</b>;
      </p>

      <p className="px-3">
        <b>b)</b> Sales of tours inside the <b>“CARACOL PARK”</b>;
      </p>

      <p className="px-3">
        <b>c)</b> Information and stories about the Park;
      </p>

      <p className="px-3">
        <b>d)</b> Access to company job positions;
      </p>

      <p className="px-3">
        <b>e)</b> Applications for open job positions;
      </p>

      <p className="px-3">
        <b>f)</b> Contact channel with the company;
      </p>

      <p className="px-3">
        <b>g)</b> Contact channel about rentals for events, media, commercial
        actions.
      </p>
      <br />

      <strong>3. HOW DO YOU ACCESS THE “CARACOL PARK” WEBSITE?</strong>
      <br />

      <p className="px-3">
        <b>3.1.</b> Navigation in the application <b>“CARACOL PARK”</b>, with
        User status, applies to users who wish to buy access tickets to the
        Park, in addition to other products and offered services, such as those
        who wish to apply for job vacancies in the company and want to know
        about media, events and locations in the Park.
      </p>

      <p className="px-3">
        <b>3.2.</b> To access the <b>“CARACOL PARK”</b> website and use its
        functionalities, it is necessary to register to create a login and
        access password. To register, the user must go to the registration area
        and fill out the form stating: e-mail, full name, country, state, CPF,
        passport (if foreign), cell phone and landline. After filling in the
        fields, just click on the register button.
      </p>

      <p className="px-3">
        <b>3.3.</b> For your access, we may request information about your
        personal data, as described in our{' '}
        <a href="/aviso-de-privacidade" target="_blank">
          Privacy Notice
        </a>
        .
      </p>

      <p className="px-3">
        <b>3.4.</b> To make a purchase on <b>“CARACOL PARK”</b>{' '}
        application/website, you must be at least eighteen (18) years old.
        Access must be carried out only by you, holder of an account. If
        irregularity or false information is verified by the user, access or
        account may be blocked.
      </p>

      <p className="px-3">
        <b>3.5.</b> The contents provided within the website by the user will be
        or may be protected by encryption.
      </p>

      <p className="px-3">
        <b>3.6.</b> In case of suspected fraud, illicitly obtaining a benefit or
        advantage, or for non-compliance with any of the conditions set forth in
        these Terms of Use, "CARACOL PARK” saves the right to suspend or cancel,
        at any time, your access to the application, without any compensation
        being due to you. Fitting the <b>"CARACOL PARK”</b> the right to promote
        a return action, if necessary, for non-compliance with any conditions
        set forth in these Terms of Use.
      </p>
      <br />

      <strong>4. USER RESPONSIBILITIES</strong>
      <br />

      <p className="px-3">
        <b>4.1.</b> The user is exclusively responsible for the use of the
        application and must respect the rules of this Term, as well as the
        applicable legislation.
      </p>

      <p className="px-3">
        <b>4.2.</b> The user guarantees the authenticity of all data provided,
        being responsible for keeping your personal information up to date.
      </p>

      <p className="px-3">
        <b>4.3.</b> <b>“CARACOL PARK”</b> shall not be liable for direct or
        indirect damages resulting from or in connection with access, use or the
        inability to access or use the application.
      </p>

      <p className="px-3">
        <b>4.4.</b> It is the user's responsibility to keep their login and
        password safe and out of reach of third parties. In this way, the user
        is responsible for all actions performed on his account.
      </p>

      <p className="px-3">
        <b>4.5.</b> In view of the inherent characteristics of the internet
        environment, the application is not responsible for connection
        interruptions or suspensions, incomplete or failed computer
        transmissions, as well as for technical failure of any kind, including,
        but not limited to, malfunctions of any network, hardware or software.
      </p>

      <p className="px-3">
        <b>4.6.</b> It is the user's responsibility to keep the environment of
        their device (computer, cell phone, tablet, among others) safe, using
        the tools available to ensure security.
      </p>

      <p className="px-3">
        <b>4.7.</b> The user declares that they are civilly capable. In case of
        possible access by any user under 18 (eighteen) years old, with or
        without authorization from the legal guardian, the latter will be
        responsible for all acts performed by that person, as stated in law.
      </p>
      <br />

      <strong>5. USER PRIVACY</strong>
      <br />

      <p className="px-3">
        <b>5.1.</b> <b>“CARACOL PARK”</b> undertakes to process your information
        and personal data in accordance with the General Data Protection Law
        (LGPD) and according to our{' '}
        <a href="/aviso-de-privacidade" target="_blank">
          Privacy Notice
        </a>
        .
      </p>

      <p className="px-3">
        <b>5.2.</b> The{' '}
        <a href="/aviso-de-privacidade" target="_blank">
          Privacy Notice
        </a>{' '}
        is an inseparable part of this <b>Term of Use</b>.
      </p>
      <br />

      <strong>6. HOW PAYMENTS ARE MADE</strong>
      <br />

      <p className="px-3">
        <b>6.1.</b> Payments made for the online purchase of tickets in the
        “CARACOL PARK”, must be carried out within the application, via credit
        card or PIX, the following personal data being collected: E-mail, CPF,
        Passport, Country, State, telephone, credit card data, in case of
        payment by card.
      </p>

      <p className="px-3">
        <b>6.2.</b> Confirmation of payment through the application will depend
        on each credit card company.
      </p>

      <p className="px-3">
        <b>6.3.</b> <b>“CARACOL PARK”</b> saves the right to establish, remove
        and/or revise the price relating to all services or goods obtained
        through the use of the application at any time.
      </p>
      <br />

      <strong>7. INTELLECTUAL PROPERTY AND OTHER “CARACOL PARK” RIGHTS</strong>
      <br />

      <p className="px-3">
        <b>7.1.</b> All rights relating to <b>“CARACOL PARK”</b> and its
        functionalities, produced directly or indirectly, are exclusive property
        of the application.
      </p>

      <p className="px-3">
        <b>7.2.</b> <b>“CARACOL PARK”</b> guarantees that all rights, titles,
        and interests (including, but not limited to, copyright and other
        intellectual property rights) over provided services, will remain under
        the ownership of the application.
      </p>

      <p className="px-3">
        <b>7.3.</b> The user will not acquire any ownership rights over{' '}
        <b>“CARACOL PARK”</b> services and content.
      </p>

      <p className="px-3">
        <b>7.4.</b>It is forbidden to use, copy, reproduce, modify, translate,
        publish, transmit, distribute, perform, upload, display, license, sell
        or exploit and reverse engineer any <b>“CARACOL PARK”</b> content,
        without prior and express consent of the application. Any unauthorized
        use of <b>“CARACOL PARK”</b> Content will be considered as a violation
        of copyright and intellectual property.
      </p>

      <p className="px-3">
        <b>7.5.</b> It is forbidden for the user to download our content in
        order to store it in a database to offer to third parties. It is also
        forbidden that the available content is used to create a database or a
        service that may compete in any way with our business.
      </p>
      <br />

      <strong>8. COPYRIGHT INFRINGEMENT</strong>
      <br />

      <p className="px-3">
        <b>8.1.</b> Claims of copyright infringement of any content available on
        the app, must be forwarded via email:{' '}
        <a href="mailto: dpo@novocaracoltainhas.com.br">
          dpo@novocaracoltainhas.com.br
        </a>
      </p>
      <br />

      <strong>9. MONITORING AND SECURITY</strong>
      <br />

      <p className="px-3">
        <b>9.1.</b> O <b>“CARACOL PARK”</b> spares no effort to ensure the
        privacy and security of your application in the internet environment.
      </p>

      <p className="px-3">
        <b>9.2.</b> Our website relies on industry-commercially available
        security measures. In addition, the payment process works through a
        secure server, using the SSL (Secure Socket Layer) protocol. The secure
        server establishes a connection so that transmitted information is
        encrypted, ensuring that it is only intelligible to your computer and{' '}
        <b>“CARACOL PARK”</b>.
      </p>

      <p className="px-3">
        <b>9.3.</b> By accepting this Term, you agree to submit to the procedure
        for detecting possible fraud operations and, at the same time, authorize
        “CARACOL PARK” so that, once the aforementioned information and
        additional documents have been provided, they will be used for future
        transactions.
      </p>

      <p className="px-3">
        <b>9.4.</b> For more information about the storage and processing of
        information, check our{' '}
        <b>
          <a href="/aviso-de-privacidade" target="_blank">
            Privacy Notice
          </a>
        </b>
        .
      </p>
      <br />

      <strong>10. RATINGS, COMMENTS, COMMUNICATIONS AND OTHER CONTENT</strong>
      <br />

      <p className="px-3">
        <b>10.1.</b> Visitors to the Platform of <b>“CARACOL PARK”</b> may post
        ratings, comments, photos and other content, send communications,
        suggestions, ideas, questions or other information, provided that the
        content is not illegal, obscene, threatening, defamatory, invades
        privacy, infringes copyrights or intellectual property or is
        objectionable or harmful to others, and that it does not contain
        software viruses, political campaigns, commercial requests, chain
        letters, mass mailings or any other form of "spam".
      </p>

      <p className="px-3">
        <b>10.2.</b> You may not use a false email address, pretend to be
        another person or entity, or provide any false information about the
        origin of content. <b>“CARACOL PARK”</b> saves the right (but not the
        obligation) to remove or edit such content.
      </p>

      <p className="px-3">
        <b>10.3.</b> We are not responsible for any damage (indirect,
        consequential, punitive, lost profits or other losses), of any nature,
        that may arise to third parties as a result of improper conduct,
        offensive or contrary to current legislation and guidelines for use and
        handling of our products/services by the user.
      </p>
      <br />

      <strong>11. COMMUNICATION CHANNEL</strong>
      <br />

      <p className="px-3">
        <b>11.1.</b> If you have any questions, comments, or suggestions, please
        contact us by e-mail:{' '}
        <a href="mailto:sac@cnct.com.br">sac@cnct.com.br</a>
      </p>

      <p className="px-3">
        <b>11.2.</b> In case of doubts, to claim your rights or any
        communication, CARACOL PARK, in compliance with article 41 of the
        General Data Protection Law, indicates to contact the Data Protection
        Officer, Louana Costa, through the following email:{' '}
        <a href="mailto:dpo@novocaracoltainhas.com.br">
          dpo@novocaracoltainhas.com.br
        </a>
      </p>
      <br />

      <strong>12. GENERAL COMMENTS</strong>
      <br />

      <p className="px-3">
        <b>12.1.</b> <b>“CARACOL PARK”</b> owns the platforms
        www.parquecaracol.com.br and holds all rights to the appearance, design,
        functionality and all other aspects of the platforms, and such rights
        may not be used, reproduced and/or copied without their express
        authorization.
      </p>

      <p className="px-3">
        <b>12.2.</b> Occasionally the <b>“CARACOL PARK”</b> platform may provide
        access links to websites of third parties, related or not to “CARACOL
        PARK”. We emphasize that we are not responsible for examining the
        content of these sites or any offers from any of these companies. It is
        up to the User to always analyze the privacy policies and conditions of
        use of these third parties.
      </p>

      <p className="px-3">
        <b>12.3.</b> The Parties declare and agree that the acceptance of this
        Term will be made in electronic format. The Parties acknowledge the
        veracity, authenticity, integrity, validity and effectiveness of this
        Agreement and its terms, including its annexes, pursuant to article 219
        of the Civil Code, in electronic format and/or signed by the Parties
        through electronic certificates, even if electronic certificates are not
        issued by ICP-Brazil, pursuant to article 10, § 2, of Provisional
        Measure No. 2200-2, of August 24, 2001 (“MP No. 2200-2”).
      </p>

      <p className="px-3">
        <b>12.4.</b> Any omission or delay by either Party in demanding
        compliance with any term or condition of this Agreement by the other
        Party, or in exercising any right, prerogative or remedy provided
        herein, will not constitute novation nor will it imply waiver of the
        future possibility of demanding compliance with such term, condition,
        right, entitlement or remedy.
      </p>

      <p className="px-3">
        <b>12.5.</b> Each clause, item, period, and sentence of this Agreement
        constitutes an independent and distinct commitment or provision.
        Whenever possible, each clause of this Agreement shall be interpreted in
        such a way as to become valid and effective under the applicable law. If
        any of the clauses of this Term is considered illegal, the clause shall
        be judged separately from the rest of the Term, and replaced by a lawful
        and similar clause, which reflects the original intentions, observing
        the law limits, and all other clauses will remain in full force.
      </p>

      <p className="px-3">
        <b>12.6.</b> <b>“CARACOL PARK”</b> saves the right, at any time and at
        its sole discretion, to modify the clauses of this Term, leaving the
        User to verify its content whenever accessing the platforms.
      </p>

      <p className="px-3">
        <b>12.7.</b> Likewise, <b>“CARACOL PARK”</b> saves the right, at any
        time and at its sole discretion, to discontinue, suspend, end, or change
        the access form to its electronic address in relation to any content,
        period of availability and equipment necessary for accessing and/or
        using it, without prior notification to the user.
      </p>
      <br />

      <strong>13. APPLICABLE LAW / JURISDICTION</strong>
      <br />

      <p className="px-3">
        <b>13.1.</b> This Term is established and interpreted in accordance with
        the Laws of the Federative Republic of Brazil.
      </p>

      <p className="px-3">
        <b>13.2.</b> The Jurisdiction of the Comarca of Rio Grande do Sul is
        elected as the competent one to solve any issues that may arise from
        this document, with express waiver of any other, however privileged it
        may be.
      </p>
      <br />

      <strong>14. UPDATES TO THIS POLICY</strong>
      <br />

      <p className="px-3">
        <b>14.1.</b> <b>“CARACOL PARK”</b> saves the right to change these Terms
        of Use whenever necessary, to guarantee the functionality, improvements,
        and updates of the offered services, not being obliged to maintain an
        application structure.
      </p>
      <br />

      <p className="pb-2">
        Our Terms of Use will always show the last update date or current
        version.
      </p>

      <dl>
        <dd>
          <table className="table-page" width="100%" cellSpacing="0">
            <tbody>
              <tr>
                <td
                  width="55"
                  height="14"
                  style={{ backgroundColor: '#c0c0c0' }}
                >
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Version</strong>
                    </span>
                  </p>
                </td>
                <td width="156" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Modified item</strong>
                    </span>
                  </p>
                </td>
                <td width="299" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Reason</strong>
                    </span>
                  </p>
                </td>
                <td width="113" style={{ backgroundColor: '#c0c0c0' }}>
                  <p className="mb-0 px-2">
                    <span>
                      <strong>Data</strong>
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="55" height="4">
                  <p className="my-2 px-2">
                    <span>01</span>
                  </p>
                </td>
                <td width="116">
                  <p className="my-2 px-2">
                    <span>Original version</span>
                  </p>
                </td>
                <td valign="top" width="299">
                  <p className="my-2 px-2">
                    <span>N/A</span>
                  </p>
                </td>
                <td valign="top" width="113">
                  <p className="my-2 px-2">
                    <span>01/18/2023</span>
                  </p>
                </td>
              </tr>
              {/* <tr>
                                <td width='55' height='3'>
                                    <br />
                                </td>
                                <td width='116'>
                                    <br />
                                </td>
                                <td valign='top' width='299'>
                                    <br />
                                </td>
                                <td valign='top' width='113'>
                                    <br />
                                </td>
                            </tr> */}
            </tbody>
          </table>
        </dd>
      </dl>
    </>
  );
}

export default TermsOfUseCaracolEN;
