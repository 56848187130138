import React from 'react';

function RegulationPT() {
    return (
        <>
            {/* <h1 className="mb-5 text-center display-2">Regulamento Promoção Black Friday <br /> Parque Bondinho Pão de Açúcar® 2022</h1> */}

            <div className="card py-4 px-3">
                <p>
                    1) A Promoção Black Friday é válida somente para as compras realizadas no site oficial do Parque Bondinho Pão de Açúcar® (<a href='/'>www.parquebondinho.com.br</a>) a partir de 23 de novembro de 2022 até 05 de dezembro de 2022, ou até os estoques se esgotarem.
                </p>

                <p>
                    2) Os bilhetes da Promoção Black Friday são válidos para visitação entre os dias de 23 de novembro de 2022 até 16 de dezembro de 2022.
                </p>

                <p>
                    3) A Promoção é válida para todos os visitantes que comprarem o produto: Bilhete Bondinho Black Friday e Bilhete Carioca Maravilha Black Friday. Limitados a 12 bilhetes por login.
                </p>

                <p>
                    4) Valor do Bilhete Bondinho Black Friday é de R$ 99,90 e o valor do Bilhete Carioca Maravilha Black Friday é de R$49,90.
                </p>

                <p>
                    5) Os descontos promocionais já estão aplicados ao valor do produto, não sendo cumulativo com nenhuma outra promoção vigente ou meia entrada.
                </p>

                <p>
                    6) O valor promocional não é válido para os bilhetes Premium como “Tours” ou Acesso Rápido.
                </p>

                <p>
                    7) O desconto é válido somente para o passeio completo (4 trechos).
                </p>

                <p>
                    8) A promoção é válida para todos os visitantes, desde que atendendo aos demais pré-requisitos dispostos neste regulamento.
                </p>

                <p>
                    9) A empresa se reserva no direito de suspender as promoções em dias de evento no parque, em caso de lotação esgotada e por motivos de manutenção ou força maior.
                </p>

                <p>
                    10) O Parque Bondinho Pão de Açúcar® se compromete a cumprir as leis, regras e regulamentos aplicáveis aos dados pessoais tratados em razão desta campanha “Promoção Black Friday Parque Bondinho Pão de Açúcar® 2022”, incluindo, mas não se limitando à Lei nº 13.709/2018 (Lei Geral de Proteção de Dados Pessoais).
                </p>
            </div>
        </>
    )
}

export default RegulationPT;