import { useEffect } from "react";
import TopHeader from "../TopHeader/TopHeader";
import NavHeaderCaracol from "./NavHeaderCaracol/NavHeaderCaracol";


import "./HeaderCaracol.scss";
//import HeaderBottomCaracol from "./HeaderBottomCaracol/HeaderBottomCaracol";


export default function HeaderCaracol() {
  ///Remover header quando scroll chega na altura do footer
  useEffect(() => {
    const header: any = document.getElementById('header');
    const footer: any = document.getElementById('content-footer');
    const body: any = document.querySelector('body');
    const headerClassList: any = header?.classList;
    const footerPosition: any = footer.getBoundingClientRect();

    let timer1 = setTimeout(() => {
      if (headerClassList.contains('hide')) {
        headerClassList.remove('hide');
      }
    },
      50);

    window.addEventListener('scroll', () => {
      if (window.scrollY >= (body.offsetHeight - (footerPosition.height + 300))) {
        if (!headerClassList.contains('hide')) {
          headerClassList.add('hide');
        }
      } else {
        if (headerClassList.contains('hide')) {
          headerClassList.remove('hide');
        }
      }
    })

    return () => {
      clearTimeout(timer1);
    };
  }, [])
  ///***Remover header quando scroll chega na altura do footer

  if (process.env.NODE_ENV === 'development') {
    return (
      <>
        <div className="header-sticky" id="header">
          <TopHeader />
          <header id="main-header">
            <div className="header-content">
              <NavHeaderCaracol />
            </div>
          </header>
              {/* <HeaderBottomCaracol/> */}
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <div className="header-sticky" id="header">
          <TopHeader />
          <header id="main-header">
            <div className="header-content">
              <NavHeaderCaracol />
            </div>
          </header>
        </div>
      </>
    )
  }
}
