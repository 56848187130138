import React from "react";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { Card } from "react-bootstrap";
import LocationCaracolPT from "./components/LocationPT/LocationCaracolPT";
import LocationCaracolES from "./components/LocationES/LocationCaracolES";
import LocationCaracolEN from "./components/LocationEN/LocationCaracolEN";

function LocationCaracol() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t] = useTranslation();

    return (
        <>
            <Card className="py-4 px-3">
                {
                    i18next.language === "pt"
                        ?
                        <LocationCaracolPT />
                        :
                        i18next.language === "en"
                            ?
                            <LocationCaracolEN />
                            :
                            i18next.language === "es"
                                ?
                                <LocationCaracolES />
                                :
                                <LocationCaracolPT />
                }
            </Card>
        </>
    )
}

export default LocationCaracol;