import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { pt, en, es } from './locales';

const setLng = window.localStorage.i18nextLng === undefined ? 'pt-BR' : window.localStorage.i18nextLng === 'pt' ? 'pt-BR' : window.localStorage.i18nextLng;
i18n
  .use(LanguageDetector)
  .init({
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format(value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      },
    },

    debug: true,

    lng: setLng === undefined ? 'pt-BR' : setLng,

    resources: {
      pt: {
        common: pt['pt-BR'],
      },
      en: {
        common: en.en,
      },
      es: {
        common: es.es,
      },
    },

    fallbackLng: 'pt-BR',

    ns: ['common'],

    defaultNS: 'common',

    react: {
      defaultTransParent: 'div',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  })

export default i18n;