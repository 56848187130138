import React from "react";

function LocationEN() {
    return (
        <>
            <div className="p-3">
            
                <div className="top">
                    <p>Check below the several transport options to get to Bondinho Park and choose the one that best suits your itinerary!</p>
                    <p>Our address is <b>Av. Pasteur, 520 – Urca, Rio de Janeiro.</b></p>
                </div>

                <h6>Subway</h6>
                <p>Disembark in Botafogo station, through exit E – Mena Barreto. Walk to the final stop of the bus 513, in Rua Voluntários da Pátria, to take it and get off in UNIRIO University. From there, walk 260 meters until you reach Bondinho Park.</p>

                <h6>Bicycle</h6>

                <p>Bondinho Park does not offer bike racks, but you can leave your bicycle near the newsstand opposite to Árabe da Urca restaurant, quite close to Bondinho Park. Bring your padlock. Some meters before, in Av. Pasteur, there is also an Itaú Bike station available.</p>

                <h6>Car</h6>

                <p>Bondinho Park does not have a rotative parking, but we have separated some nearby parking sites for you:</p>
                <ul>
                    <li>Rio Sul Shopping – Rua Lauro Müller, 116 – Botafogo</li>
                    <li>Casa & Gourmet Shopping – Rua Gen. Severiano, 97 – Botafogo</li>
                </ul>

                <p>Check some rotative parking places throughout Urca district:</p>

                <ul>
                    <li>Rua Odílio Bacelar</li>
                    <li>Rua Osório de Almeida</li>
                    <li>Rua Urbano Santos</li>
                    <li>Avn. Portugal</li>
                    <li>Rua Mal. Cantuária</li>
                </ul>

                <h6>Ferry</h6>

                <p>The visitors from the city of Niterói or from Governador and Paquetá Islands may take a ferry to Praça XV. Then, they either walk to Largo da Carioca (740 meters) or catch Line 2 of VLT (Light Rail Vehicle) until Cristiano Ottoni - Pequena África station to take the bus 107 (Central). Get off in UNIRIO University and walk 180 meters until Bondinho Park.</p>

                <div className="bottom">
                    <h6>Bus</h6>

                    <p>The main lines that lead to Bondinho Park are:</p>
                    <ul>
                        <li>107 – Central – Urca (Bus Rapid System – BRS – 1, through Flamengo Beach)</li>
                        <li>513SP – Urca – Botafogo Subway Station (Through Rio Sul Shopping – Integration with the subway)</li>
                        <li>518 – Urca - Botafogo (Circular) – through Copacabana</li>
                        <li>519 – Urca - Copacabana – through Botafogo</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LocationEN;