import React, { useState } from "react";

import { Container, Row, Col } from "react-bootstrap";

import FilterStores from "../../components/Filter/FilterStores/FilterStores";
import StoresList from "../../components/StoresList/StoresList";

import dataCaracol from "../../components/StoresList/locales/storesCaracol.json";

import "./Stores.scss";
import PageDafault from "../../templates/PageDafault";

function Stores() {
    const [filterType, setFilterType] = useState<any>([]);
    const [filterLocation, setFilterLocation] = useState<any>([]);
    const [pagination, setPagination] = useState<any>(5);

    return (
        <>
            <PageDafault>
                <Container className="py-5">
                    <div className="store-title">
                        <h1 className="text-center pb-4 display-2">Lojas</h1>
                    </div>
                    <Row>
                        <Col md={3}>
                            <div className="card-filter">
                                <FilterStores
                                    stores={dataCaracol.stores.default}
                                    setFilterLocation={setFilterLocation}
                                    setFilterType={setFilterType}
                                    setPagination={setPagination}
                                />
                            </div>
                        </Col>
                        <Col md={9}>
                            <StoresList
                                stores={dataCaracol.stores.default}
                                filterLocation={filterLocation}
                                filterType={filterType}
                                pagination={pagination}
                                setPagination={setPagination}
                            />
                        </Col>
                    </Row>
                </Container>
            </PageDafault>
        </>
    )
}

export default Stores;