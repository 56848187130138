import React from 'react';

function TermsOfPurchaseCaracolPT() {
  return (
    <>
      <p>
        Antes de finalizar sua compra conheça as políticas e condições de venda
        de ingresso:
      </p>

      <p className="mb-5">
        Dúvidas sobre a política e termo de compra podem ser esclarecidas com o{' '}
        <b>PARQUE DO CARACOL:</b>{' '}
        <a href="mailto: sac@cnct.com.br">sac@cnct.com.br</a>.
      </p>

      <p className="mb-4">
        <b>1.</b> A compra estará sujeita à disponibilidade de ingressos e à
        aprovação da operadora de seu cartão de crédito, que poderá levar até 5
        (cinco) dias ou compensação do pagamento via PIX. O cliente somente
        receberá a confirmação do agendamento do passeio após a confirmação do
        pagamento.
      </p>

      <p className="mb-4">
        <b>2.</b> Ao comprar em nosso site, sua compra poderá ficar sujeita a
        análise e aprovação de nosso departamento de segurança, que poderá
        solicitar documentos de comprovação de cadastro para finalização do seu
        pedido.
      </p>

      <p className="mb-4">
        <b>3</b>. Confira atentamente os dados de seu pedido (agendamento, dia,
        período, quantidades) antes da confirmação de sua compra. Não será
        permitida a troca do seu ingresso para outra data agendada. O seu
        ingresso é um produto único, ou seja, após sua compra ele não estará
        mais disponível para venda.
      </p>

      <p className="mb-4">
        <b>4.</b> Para compras com cartão de crédito de terceiros, é
        indispensável a apresentação de uma cópia do documento oficial de
        identificação com foto do titular do cartão de crédito utilizado na
        compra e uma cópia somente da frente do cartão. A não apresentação dos
        documentos fica sob pena de não ser autorizada a entrada.
      </p>

      <p className="mb-4">
        <b>5.</b> Conforme o Código de Defesa do Consumidor (Artigo 49), a
        solicitação de cancelamento deverá ser realizada dentro do prazo de 7
        (sete) dias da data da compra e em até 24 (vinte e quatro) horas antes
        da data e hora inicial do passeio, o reembolso do valor do ingresso será
        efetuado em sua integralidade. Após este período, não será possível a
        solicitação de cancelamento.
      </p>

      <p className="mb-4">
        <b>6.</b> O não comparecimento na data do passeio acarretará o “no
        show”. Lembrando que o ingresso tem flexibilidade de 7 dias de
        visitação.
      </p>

      <p className="mb-4">
        <b>7.</b> Somente o Usuário, titular da compra, pode efetuar a
        solicitação de cancelamento do pedido.
      </p>

      <p className="mb-4">
        <b>8.</b> A solicitação de cancelamento está sujeita a análise; logo, o
        simples fato de o usuário promover a solicitação não garante que seu
        pedido será cancelado.
      </p>

      <p className="mb-4">
        <b>9.</b> O estorno do valor será efetuado no site em link próprio que,
        após confirmar a sua autenticidade, efetuará a solicitação do estorno de
        valores junto a administradora de cartão de crédito. O estorno na fatura
        do cartão seguirá as normas de cada operadora/banco emissor, podendo ser
        creditado na fatura seguinte ou na subsequente, de acordo com a data de
        fechamento da fatura. Em casos de PIX, será solicitado os dados
        bancários para depósito.
      </p>

      <p className="mb-4">
        <b>10.</b> Em casos de cancelamento da visita por parte do Parque do
        Caracol, os valores relativos aos ingressos serão devolvidos.
      </p>

      <p className="mb-4">
        <b>11.</b> Nas hipóteses de compra de ingressos via internet, você pode
        apresentar seu ingresso em formato de papel (imprimindo o mesmo) ou em
        formato digital (pelo telefone celular ou tablet, por exemplo). O
        ingresso contém um 'QR Code' que será identificado nas catracas.
      </p>

      <p className="mb-4">
        <b>12.</b> No caso de extravio do ingresso, o Parque do Caracol não se
        responsabiliza pela emissão de novo ingresso.
      </p>

      <p className="mb-4">
        <b>13.</b> Meia-Entrada: as pessoas que adquirirem ingressos do tipo
        meia entrada deverão comprovar através de documentos
        originais/impressos, que validem o direito ao benefício e receberão
        pulseiras de identificação.
      </p>

      <p className="mb-4">
        <b>14.</b> Ingressos obtidos de fontes não autorizadas podem ser
        inválidos, perdidos, roubados ou falsificados; o Parque do Caracol, em
        verificando tal prática poderá revogá-los, anulá-los ou cancelá-los, sem
        direito a qualquer reembolso.
      </p>

      <p className="mb-4">
        <b>15.</b> O Parque do Caracol não se responsabiliza por ingressos
        adquiridos em canais de venda não oficiais, seja através de pessoa
        física, pessoa jurídica, outros sites e/ou comunidades na internet, com
        quem o Parque do Caracol não possui qualquer vínculo.
      </p>

      <p className="mb-4">
        <b>16.</b> O Usuário é responsável e deve conferir sempre, atentamente,
        os dados de seu pedido (quantidade de bilhetes, data da visita, tipo do
        bilhete), antes da confirmação de sua compra. O seu ingresso é um
        produto único, ou seja, após sua compra ele não estará mais disponível
        para venda.
      </p>

      <p className="mb-4">
        <b>17.</b> O Parque do Caracol atenderá e/ou prestará suporte apenas ao
        Usuário, ou seja, a pessoa física ou jurídica devidamente cadastrada, e
        exclusivamente em aquisições que comprovadamente tenham ocorrido através
        da conta daquele Usuário junto ao site de venda de ingressos.
      </p>

      <p>
        <b>18.</b> As regras de compra e cancelamento são estabelecidas pelo
        Parque do Caracol.
      </p>
    </>
  );
}

export default TermsOfPurchaseCaracolPT;
