import i18next from 'i18next';
import React from 'react';

import { useTranslation } from "react-i18next";
import PageDafault from '../../../templates/PageDafault';

import '../PrivacyNotice.scss';

import PrivacyNoticeCaracolEN from './components/PrivacyNoticeCaracolEN/PrivacyNoticeCaracolEN';
import PrivacyNoticeCaracolES from './components/PrivacyNoticeCaracolES/PrivacyNoticeCaracolES';
import PrivacyNoticeCaracolPT from './components/PrivacyNoticeCaracolPT/PrivacyNoticeCaracolPT';

function PrivacyNoticeCaracol() {
    const { t } = useTranslation();

    return (
        <>
            <PageDafault>
                <div className="container py-5">
                    <h1 className="mb-5 text-center display-2">{t('privacy.title')}</h1>
                    <div className="card py-4 px-3">
                        {
                            i18next.language === "pt"
                                ?
                                <PrivacyNoticeCaracolPT />
                                :
                                i18next.language === "en"
                                    ?
                                    <PrivacyNoticeCaracolEN />
                                    :
                                    i18next.language === "es"
                                        ?
                                        <PrivacyNoticeCaracolES />
                                        :
                                        <PrivacyNoticeCaracolPT />
                        }
                    </div>
                </div>
            </PageDafault>
        </>
    );
}

export default PrivacyNoticeCaracol;