import React from "react";

function LocationCaracolES() {
    return (
        <>
            <div className="p-3">
                <div className="top mb-2">
                    <p>
                        El <b>Parque del Caracol</b> está aproximadamente a 8,7 km del centro de la ciudad de Canela, en Rio Grande do Sul. Simplemente diríjase hacia la ciudad de Gramado, en Rio Grande do Sul, tomando la carretera nacional RS 466.
                    </p>
                    <p>
                        Parque del Caracol.
                    </p>
                </div>
            </div>
        </>
    )
}

export default LocationCaracolES;