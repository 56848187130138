import React from "react";
import { Container } from "react-bootstrap";
import PageDafault from "../../templates/PageDafault";

import { useTranslation } from "react-i18next";
import AcessRules from "../../components/AccessRules/AccessRules";

import "./AccessRulesPage.scss";

function AccessRulesPage() {
    const [t] = useTranslation();

    return (
        <>
            <PageDafault>
                <div className="bg-fundo">
                    <Container className="page-static bg-how-to-get py-5">
                        <h1 className="mb-5 text-center display-2">{t("navHeader.sideBar.acessRules")}</h1>
                        <div className="card">
                            <AcessRules />
                        </div>
                    </Container>
                </div>
            </PageDafault>
        </>
    )
}

export default AccessRulesPage;