import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { Helmet } from 'react-helmet';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import { Provider } from 'react-redux';
import store from './store/cart';

import './assets/sass/global.scss'

import ScrollToTop from "./infra/ScrollToTop/ScrollToTop";

import Routes from './routes';
//import { Helmet } from 'react-helmet';

/* import CookieAlert from './components/CookieAlert/CookieAlert'; */

function App() {

  library.add(fas, fal, fad, fab);
  return (
    <div className="App">
      <Provider store={store} >
        <I18nextProvider i18n={i18n}>
          <BrowserRouter>
            <Helmet>
              <meta name="adopt-website-id" content="4f0cac40-eb87-44f4-9c58-0582ec903070" />
              <script src="//tag.goadopt.io/injector.js?website_code=4f0cac40-eb87-44f4-9c58-0582ec903070" className="adopt-injector"></script> {/* Adopt */}

              <meta name="facebook-domain-verification" content="78iz23thcoesllv31eprr9ba9bjusa" />

              <script id="ze-snippet" src="https://static.zdassets.com/ekr/snippet.js?key=10e52647-9e1b-48d0-91ac-afe87ada7d97"> </script>

              <meta property="og:url" content={process.env.REACT_APP_SERVER_URL} />
            </Helmet>
            <ScrollToTop />
            <Routes />
          </BrowserRouter>
          {/* <CookieAlert /> */}
        </I18nextProvider>
      </Provider>

    </div>
  );
}

export default App;