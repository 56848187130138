import React from "react";

function AcessRulesES() {
    return (
        <>
            {/* <p>Planee su visita con seguridad y sin imprevistos</p> */}

            <h6 className="my-4"><b>No se permitirá:</b></h6>

            <ul className="list-acess-rules">
                <li>Acceso con animales, excepto perros guía o animales de apoyo emocional, con documentación acreditativa.</li>
                <li>Está prohibido bañarse o beber agua del río.</li>
                <li>Filmar o fotografiar el Parque del Caracol con fines comerciales, sin previa autorización de la empresa.</li>
                <li>Portar o utilizar Drones.</li>
                <li>Acceder con materiales de gran tamaño (por ejemplo: trípode, instrumentos musicales, sombrilla, silla de playa, coolers, trajes voluminosos, maletas o equipos de más de 25x35x55 cm).</li>
                <li>Entrar o permanecer sin camisa, con trajes de baño o descalzo.</li>
                <li>Entrar con patinetas, monopatines, patines o zapatos con ruedas incorporadas, bicicletas o similares.</li>
                <li>Que los niños adolescentes menores de 16 (dieciséis) años accedan o permanezcan en el parque sin compañía de los padres o responsables mayores de edad. Los responsables de los menores deben presentar documentación comprobatoria de la relación de parentesco de hasta tercer grado (padres, madres, hermanos, tíos, sobrinos, abuelos), o autorización de los padres, conforme previsto en la ley.</li>
                <li>Plantar o retirar especies de la flora.</li>
                <li>Practicar cualquier tipo de depredación del medio ambiente.</li>
                <li>Alimentar, acercarse o cazar animales.</li>
                <li>Portar armas de fuego, municiones, dispositivos de autodefensa (spray de pimienta, armas de choque).</li>
                <li>Música a volumen alto, utilizar cornetas, silbatos, megáfonos o dispositivos artificiales que emitan ruidos.</li>
                <li>Realizar manifestaciones diversas.</li>
                <li>Utilizar los baños del parque para baño.</li>
                <li>Entrar usando cascos o cubiertas que oculten la cara - LEY 6717/14.</li>
                <li>Comercializar cualquier tipo de producto o servicio sin previa autorización de la empresa.</li>
                <li>Realizar cualquier apología del crimen.</li>

                {/* <div className="mt-4">
                    <i>
                    AVISO: La visita podrá ser, por parte del equipo del Parque Caracol, filmada, grabada y/o fotografiada para posterior publicación, transmisión, retransmisión, reproducción y/o divulgación en televisión, radio, internet, publicidad en general y cualquier otro vehículo de comunicación, producción de CD y DVD. El Comprador acuerda que, al asistir a la visita, autoriza el uso gratuito de su imagen, nombre y voz, en los términos  aquí mencionados, en Brasil y en el exterior, sin límite de veces o tiempo, sin que ello caracterice un uso indebido de la imagen o cualquier otro derecho y sin que de dicho uso se produzca ninguna carga y/o indemnización.
                    </i>
                </div> */}
            </ul>
        </>
    )
}

export default AcessRulesES;