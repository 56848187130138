import React, { /* useEffect,  */useState } from 'react';
import Slider from 'react-slick';
import i18next from 'i18next';

//import { Link as LinkRoll } from 'react-scroll';

import { Container, Row } from 'react-bootstrap';

/* Imagens do Slider Padrão */
import BannerCaracolUrgenciaPT2 from '../../../assets/img/bannerHomeCaracol/BannerCaracolGaúcho.jpg';
import BannerCaracolUrgenciaEN2 from '../../../assets/img/bannerHomeCaracol/BannerCaracolGaúcho.jpg';
import BannerCaracolUrgenciaES2 from '../../../assets/img/bannerHomeCaracol/BannerCaracolGaúcho.jpg';
import BannerCaracolUrgenciaMobilePT2 from '../../../assets/img/bannerHomeCaracol/BannerCaracolGaúchoMobile.jpg';
import BannerCaracolUrgenciaMobileEN2 from '../../../assets/img/bannerHomeCaracol/BannerCaracolGaúchoMobile.jpg';
import BannerCaracolUrgenciaMobileES2 from '../../../assets/img/bannerHomeCaracol/BannerCaracolGaúchoMobile.jpg';

import BannerCaracolUrgenciaPT from '../../../assets/img/bannerHomeCaracol/BannerGaucho.png';
import BannerCaracolUrgenciaEN from '../../../assets/img/bannerHomeCaracol/BannerGaucho.png';
import BannerCaracolUrgenciaES from '../../../assets/img/bannerHomeCaracol/BannerGaucho.png';
import BannerCaracolUrgenciaMobilePT from '../../../assets/img/bannerHomeCaracol/BannerGauchoMobile.jpg';
import BannerCaracolUrgenciaMobileEN from '../../../assets/img/bannerHomeCaracol/BannerGauchoMobile.jpg';
import BannerCaracolUrgenciaMobileES from '../../../assets/img/bannerHomeCaracol/BannerGauchoMobile.jpg';

import BannerFeijoadaDilceDesktop from '../../../assets/img/bannerHomeCaracol/BANNER-DESKTOP-Dilce.jpg';
import BannerFeijoadaDilceMobile from '../../../assets/img/bannerHomeCaracol/BANNER-MOBILE-Dilce.jpg';
/* END - Imagens do Slider Padrão */

import './BannerHomeCaracol.scss';
import { Link } from 'react-router-dom';

function BannerHomeCaracol() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [changeBanner, setChangeBanner] = useState<boolean>();

  const settings = {
    draggable: false,
    useTransform: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1500,
    autoplaySpeed: 5500,
    initialSlide: 0 /* Math.floor(Math.random() * 2) */,
    cssEase: 'ease-out',
  };

  const typeDevice = window.innerWidth < 480 ? 'mobile' : 'desktop';

  /* Mudar o Routes para o padrão no dia 30/06/2022 às 19:00 */
  /*   useEffect(() => {
      let date = new Date().toLocaleDateString('pt-BR');
  
      let dateNow = date.split(' ')[0].split('/').reverse().join('');
  
      if (`${dateNow}` >= '20231005' && `${dateNow}` <= '20231016') {
        setChangeBanner(true);
      } else {
        setChangeBanner(false);
      }
    }, []); */
  /* END - Mudar o Routes para o padrão no dia 30/06/2022 às 19:00 */

  return (
    <div className="bg-main-banner">
      <Slider {...settings}>
            <Link
              to={
                i18next.language === 'pt'
                  ? "/ticket/ingresso-gaucho-br"
                  : i18next.language === 'en'
                    ? "/ticket/ticket-gaucho-en"
                    : i18next.language === 'es'
                      ? "/ticket/boleto-gaucho-es"
                      : "/ticket/ingresso-gaucho-br"
              }
            >
                <Container className="bg-secondary banner-home p-0 m-0" fluid>
                  <Container className="p-0 m-0" fluid>
                    {/*<LinkRoll
                      activeClass="active"
                      to="main-product"
                      spy={true}
                      smooth={true}
                      offset={-250}
                      duration={500}
          >*/}
              <Row className="p-0 m-0">
                {typeDevice === 'desktop' ? (
                  <img
                    className="p-0 m-0"
                    src={
                      i18next.language === 'pt'
                        ? BannerCaracolUrgenciaPT
                        : i18next.language === 'en'
                          ? BannerCaracolUrgenciaEN
                          : i18next.language === 'es'
                            ? BannerCaracolUrgenciaES
                            : BannerCaracolUrgenciaPT
                    }
                    alt="Banner do Parque do Caracol com os dizeres conheça o parque do caracol, onde a natureza e os sorrisos se encontram."
                  />
                ) : (
                  <img
                    className="p-0 m-0"
                    src={
                      i18next.language === 'pt'
                        ? BannerCaracolUrgenciaMobilePT
                        : i18next.language === 'en'
                          ? BannerCaracolUrgenciaMobileEN
                          : i18next.language === 'es'
                            ? BannerCaracolUrgenciaMobileES
                            : BannerCaracolUrgenciaMobilePT
                    }
                    alt="Banner do Parque do Caracol com os dizeres conheça o parque do caracol, onde a natureza e os sorrisos se encontram."
                  />
                )}
              </Row>


            {/*</LinkRoll>*/}
          </Container>
        </Container>
            </Link>

            {/* <a target='_blank'
              href={`https://ofertas.parquecaracol.com.br/o-que-fazer-no-parque-caracol`}
            >
                <Container className="bg-secondary banner-home p-0 m-0" fluid>
                  <Container className="p-0 m-0" fluid>                    
              <Row className="p-0 m-0">
                {typeDevice === 'desktop' ? (
                  <img
                    className="p-0 m-0"
                    src={BannerFeijoadaDilceDesktop}
                    alt="Banner Feijoada da Dilce"
                  />
                ) : (
                  <img
                    className="p-0 m-0"
                    src={BannerFeijoadaDilceMobile}
                    alt="Banner Feijoada da Dilce"
                  />
                )}
              </Row>           
          </Container>
        </Container>
            </a> */}
        </Slider>
    </div>
  );
}

export default BannerHomeCaracol;
