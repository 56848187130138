import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useAppSelector } from "../../store/hooks";

export interface propPlus {
    getChangeInstallments: any;
    defaultChecked: any;
}

const GetInstallmentsForSource: React.FC<propPlus> = ({
    defaultChecked, getChangeInstallments
}: propPlus) => {
    const cartItem: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));
    const [info, setInfo] = useState<any>(null);
    const [installments, setInstallments] = useState<number>(1);

    useEffect(() => {
        async function ChangeInstallments() {
            try {
                const { data } = await api.get(`/api/Checkout/GetInstallmentsForSource?source=site`);
                if (data.status !== 400) {
                    setInfo(data.data.data.result);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {

                }

            }
        }
        ChangeInstallments();
    }, []);


    useEffect(() => {
        getChangeInstallments(1);
        for (let x = 1; x <= info?.length; x++) {
            if (cartItem.totalCart >= info[x]?.amountMin && cartItem.totalCart <= info[x]?.amountMax) {
                setInstallments(info[x]?.installments);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cartItem.totalCart, info])



    if(cartItem?.totalCart !== 0 && cartItem?.totalCart !== '0') {
        return (
            <>
                <div className="custom-control custom-radio mb-3">
                    <input type="radio" id="parsePayments1" name="customPayment" value="1" className="custom-control-input" onChange={(e: any) => getChangeInstallments(e.target.value)} checked={1 === Number(defaultChecked)} />
                    <label className="custom-control-label pPayment small d-flex flex-row align-items-center" htmlFor="parsePayments1"> À vista R$ {cartItem?.totalCart?.toFixed(2).split('.').join(',')}</label>
                </div>
    
                {[...Array(Number(installments - 1))].map((x, i) =>
                    <div key={i} className="custom-control custom-radio mb-3">
                        <input type="radio" id={'parsePayments' + (i + 2)} name="customPayment" value={(i + 2)} className="custom-control-input" onChange={(e: any) => getChangeInstallments(e.target.value)} checked={Number(i + 2) === Number(defaultChecked)} />
                        <label className="custom-control-label pPayment small d-flex flex-row align-items-center" htmlFor={'parsePayments' + (i + 2)} >
                            <span className="mx-1" data-parse={i}> {(i + 2)}x R$ {(cartItem?.totalCart / (i + 2)).toFixed(2).split('.').join(',')}</span>
                            <small> sem juros</small>
                        </label>
                    </div>
                )}
                <div className="invalid-payments">Escolha uma das opções de parcelamento</div>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default GetInstallmentsForSource;