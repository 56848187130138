import React from 'react';

function ModalAlertEN() {
  return (
    <>
      <p>
        <strong>
          Parque do Caracol has an important piece of information for you! Stay
          tuned for our special end-of-year opening hours:
        </strong>
      </p>
      <p>
        <strong>12/31 (New Year's Eve): 9:00 AM to 3:00 PM</strong>
      </p>
      <p>
        <strong>01/01 (New Year): 11:00 AM to 5:30 PM</strong>
      </p>
      <p>
        <strong>
          On other days, the Park will operate normally from 9:00 AM to 5:30 PM.
        </strong>
      </p>
      <p>
        <strong>See you soon!</strong>
      </p>
    </>
  );
}

export default ModalAlertEN;
