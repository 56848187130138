import React from "react";

import { Container } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import PageDafault from "../../templates/PageDafault";
/* import Newsletter from "../../components/Newsletter/Newsletter"; */

import "./OurHistory.scss";

function OurHistory() {
    const { t } = useTranslation();

    return (
        <>
            <PageDafault>
                <Container className="page-static py-5">
                    <h1 className="mb-5 text-center display-2">{t("ourHistory.title")}</h1>
                    <div className="card py-4 px-3">
                        <div className="p-3">
                            <p>
                                {t("ourHistory.text01")}
                            </p>

                            <p>
                                {t("ourHistory.text02")}
                            </p>

                            <p>
                                {t("ourHistory.text03")}
                            </p>

                            <p>
                                {t("ourHistory.text04")}
                            </p>
                        </div>
                    </div>
                </Container>
            </PageDafault>
        </>
    )
}

export default OurHistory;