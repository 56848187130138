import React from 'react';

function RegulationEN() {
    return (
        <>
            {/* <h1 className="mb-5 text-center display-2">Regulation of the Promotion “Black Friday <br /> Parque Bondinho Pão de Açúcar® 2022”</h1> */}

            <div className="card py-4 px-3">
                <p>
                    1) The “Black Friday Parque Bondinho Pão de Açúcar® 2022” promotion is valid for purchases made exclusively on the official website, (<a href='/'>www.parquebondinho.com.br</a>), from November 23rd, 2022 to December 05th, 2022 or until stocks are run out.
                </p>

                <p>
                    2) Black Friday promotion tickets are valid for visiting among November 23rd, 2022 to December 16th, 2022.
                </p>

                <p>
                    3) The promotion is valid for all visitors who buy the product: Black Friday Sugarloaf Ticket and Black Friday Carioca Maravilha Ticket. Limited to 12 promotional tickets per login.
                </p>

                <p>
                    4) The value of Black Friday Sugarloaf Ticket is R$99,90 and the value of the Black Friday Carioca Maravilha Ticket is R$49,90.
                </p>

                <p>
                    5) Promotional discounts are already applied to the value of the product, not being cumulative with any other current promotion or half-price ticket.
                </p>

                <p>
                    6) The promotional value is not valid for Fast Pass Tickets such as Tours Tickets.
                </p>

                <p>
                    7) The discount is valid only for the full ride (4 sections).
                </p>

                <p>
                    8) The promotion is valid for all visitors, provided that the remaining prerequisites of this regulation are met.
                </p>

                <p>
                    9) The company reserves the right to discontinue the promotion on the days of event in the park, in case of sold-out capacity, and for maintenance or force majeure reasons.
                </p>

                <p>
                    10) Parque Bondinho Pão de Açúcar® undertakes to comply with the laws, rules and regulations applicable to the personal data processed by virtue of the campaign “Black Friday Parque Bondinho Pão de Açúcar® 2022”, including, without limitation, Law No. 13.709/2018 (General Personal Data Protection Law).
                </p>
            </div>
        </>
    )
}

export default RegulationEN;