import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import Location from "../../components/Location/Location";
import PageDafault from "../../templates/PageDafault";

import './HowToGet.scss';

function HowToGet() {
    const [t] = useTranslation();

    return (
        <>
            <PageDafault>
                <div className="bg-fundo">
                    <Container className="page-static bg-how-to-get py-5">
                        <h1 className="mb-5 text-center display-2">{t("howtoget.title01")}<br />{t("howtoget.title02")}</h1>
                        <Location />
                    </Container>
                </div>
            </PageDafault>
        </>
    )
}

export default HowToGet;