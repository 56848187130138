import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

import { Link as LinkRoll } from "react-scroll";

function HowToGetCaracolEN() {
    return (
        <>
            <div className="how-to-get-caracol">
                <div className="how-to-get-top">
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />
                    <span>Getting to the Park</span>
                </div>

                <div className="how-to-get-description">
                    <p>
                        <b>Caracol Park</b> is approximately 8,7 km from the center of Canela, in Rio Grande do Sul (RS). Just head towards Gramado, and drive by RS 466 Road.
                    </p>
                    <p>
                        Caracol Park.
                    </p>
                </div>

                <LinkRoll
                    activeClass="active"
                    to="main-product"
                    spy={true}
                    smooth={true}
                    offset={-250}
                    duration={500}
                >
                    <Button variant="outline-primary mb-5">
                        Discover the main attractions
                    </Button>
                </LinkRoll>
            </div>
        </>
    )
}

export default HowToGetCaracolEN;