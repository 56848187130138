import React from 'react';

import { useTranslation } from "react-i18next";

import './AboutUs.scss';
import PageDafault from '../../templates/PageDafault';

const AboutUs = () => {
    const { t } = useTranslation();

    return (
        <>
            <PageDafault>
                <div className="container about-us">

                    <div className="py-5 px-3 px-md-0">

                        <h1 className="text-center pb-4 display-4"> {t('aboutus.title')}</h1>

                        <div className="row align-items-center justify-content-center">
                            <div className="col-12 col-md-6 pl-0 pr-0 pr-md-5">
                                <div className="embed-responsive embed-responsive-16by9">
                                    <iframe title="about us" className="embed-responsive-item" src="https://www.youtube.com/embed/Dbgk8wPJbAk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 pt-4 pt-md-0">
                                <p className="about-us-description">{t("aboutus.description")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </PageDafault>
        </>
    );
}

export default AboutUs;