import React from 'react';

function TermsOfPurchaseCaracolES() {
  return (
    <>
      <p>
        Antes de finalizar su compra conozca las políticas y condiciones de
        venta de entrada:
      </p>

      <p className="mb-5">
        Dudas sobre la política y término de compra pueden ser esclarecidas con
        el <b>PARQUE DEL CARACOL:</b>{' '}
        <a href="mailto:sac@cnct.com.br">sac@cnct.com.br</a>.
      </p>

      <p className="mb-4">
        <b>1.</b> La compra estará sujeta a la disponibilidad de entradas y a la
        aprobación del operador de su tarjeta de crédito, que podrá tardar hasta
        5 (cinco) días o compensación del pago vía PIX. El cliente solo recibirá
        la confirmación de la programación del paseo después de la confirmación
        del pago.
      </p>

      <p className="mb-4">
        <b>2.</b> Al comprar en nuestro sitio web, su compra puede estar sujeta
        a revisión y aprobación de nuestro departamento de seguridad, que podrá
        solicitar documentos de comprobante de registro para finalizar su
        pedido.
      </p>

      <p className="mb-4">
        <b>3.</b> Revise atentamente los datos de su pedido (horario, día,
        período, cantidades) antes de la confirmación de su compra. No se
        permitirá cambiar su entrada a otra fecha programada. Su entrada es un
        producto único, es decir, después de su compra ya no estará disponible
        para la venta.
      </p>

      <p className="mb-4">
        <b>4.</b> Para las compras con tarjeta de crédito de terceros, será
        indispensable presentar una copia del documento oficial de identidad con
        foto del titular de la tarjeta de crédito utilizado para la compra y una
        copia únicamente del anverso de la tarjeta. La no presentación de los
        documentos queda sujeta a la no autorización de entrada.
      </p>

      <p className="mb-4">
        <b>5.</b> Conforme al Código de Protección al Consumidor (Artículo 49),
        la solicitud de cancelación deberá realizarse dentro del plazo de 7
        (siete) días de la fecha de compra y hasta 24 (veinticuatro) horas antes
        de la fecha y hora inicial del recorrido. El reembolso del importe de la
        entrada será efectuado en su totalidad. Después de este período, no será
        posible la solicitud de cancelación.
      </p>

      <p className="mb-4">
        <b>6.</b> La no asistencia en la fecha del paseo acarreará el "no show".
        Recordando que la entrada tiene flexibilidad de 7 días de visitación.
      </p>

      <p className="mb-4">
        <b>7.</b> Solo el Usuario, titular de la compra, puede realizar la
        solicitud de cancelación del pedido.
      </p>

      <p className="mb-4">
        <b>8.</b> La solicitud de cancelación está sujeta a revisión; por lo
        tanto, el mero hecho de que el usuario promueva la solicitud no
        garantiza que su pedido sea cancelado.
      </p>

      <p className="mb-4">
        <b>9.</b> La devolución del valor se efectuará en el sitio web en enlace
        propio que, tras confirmar su autenticidad, efectuará la solicitud de
        devolución de valores ante la administradora de tarjeta de crédito. La
        devolución de la factura de la tarjeta seguirá las normas de cada
        operador/banco emisor, pudiendo ser acreditado en la factura siguiente o
        en la subsiguiente, de acuerdo con la fecha de cierre de la factura. En
        casos de PIX, se le pedirá los datos bancarios para depósito.
      </p>

      <p className="mb-4">
        <b>10.</b> En caso de cancelación de la visita por parte del Parque del
        Caracol, los valores relativos a las entradas serán devueltos.
      </p>

      <p className="mb-4">
        <b>11.</b> En caso de compra de entradas a través de Internet, puede
        presentar su entrada en papel (imprimiéndola) o en formato digital (por
        ejemplo, por teléfono móvil o tablet). La entrada contiene un código QR
        que será identificado en los torniquetes.
      </p>

      <p className="mb-4">
        <b>12.</b> En caso de pérdida de la entrada, el Parque del Caracol no se
        responsabiliza por la emisión de una nueva entrada.
      </p>

      <p className="mb-4">
        <b>13.</b> Media entrada: Las personas que adquieran entradas del tipo
        media entrada deberán acreditar a través de documentos
        originales/impresos, que validen el derecho al beneficio y recibirán
        pulseras de identificación.
      </p>

      <p className="mb-4">
        <b>14.</b> Las entradas obtenidas de fuentes no autorizadas pueden ser
        inválidas, perdidas, robadas o falsificadas; el Parque del Caracol, al
        verificar dicha práctica, podrá revocarlas, anularlas o cancelarlas, sin
        derecho a reembolso alguno.
      </p>

      <p className="mb-4">
        <b>15.</b> El Parque del Caracol no se responsabiliza de las entradas
        adquiridas en canales de venta no oficiales, ya sea a través de persona
        física, persona jurídica, otros sitios y/o comunidades en internet, con
        los que el Parque del Caracol no tiene ningún vínculo.
      </p>

      <p className="mb-4">
        <b>16.</b> El Usuario es responsable y debe verificar siempre,
        atentamente, los datos de su pedido (cantidad de billetes, fecha de la
        visita, tipo de billete), antes de la confirmación de su compra. Su
        entrada es un producto único, es decir, después de su compra ya no
        estará disponible para la venta.
      </p>

      <p className="mb-4">
        <b>17.</b> El Parque del Caracol atenderá y/o prestará soporte solo al
        Usuario, es decir, a la persona física o jurídica debidamente
        registrada, y exclusivamente en adquisiciones que comprobadamente hayan
        ocurrido a través de la cuenta de aquel Usuario junto al sitio de venta
        de entradas.
      </p>

      <p>
        <b>18.</b> Las reglas de compra y cancelación son establecidas por el
        Parque del Caracol.
      </p>
    </>
  );
}

export default TermsOfPurchaseCaracolES;
