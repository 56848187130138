import React from 'react';
import { Container } from "react-bootstrap";

import ListReservations from '../../components/Reservations/ListReservations/ListReservations';

import { useTranslation } from "react-i18next";

import './MyReservations.scss';
import PageDafault from '../../templates/PageDafault';

function MyReservations() {
    const [t] = useTranslation();

    return (
        <>
            <PageDafault>
                <Container className="my-reservations py-5">
                    <h1 className="mb-5 text-center display-2">{t("myReservations.title")}</h1>
                    <ListReservations />
                </Container>
            </PageDafault>
        </>
    );
}

export default MyReservations;