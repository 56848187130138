import React from 'react';

function ModalAlertES() {
  return (
    <>
      <p>
        <strong>
          ¡El Parque do Caracol tiene una información importante para ti!
          Mantente atento a nuestros horarios especiales de apertura de fin de
          año:
        </strong>
      </p>
      <p>
        <strong>31/12 (Nochevieja): 9:00 AM a 3:00 PM</strong>
      </p>
      <p>
        <strong>01/01 (Año Nuevo): 11:00 AM a 5:30 PM</strong>
      </p>
      <p>
        <strong>
          El resto de días, el Parque funcionará con normalidad de 9:00 a 17:30
          horas.
        </strong>
      </p>
      <p>
        <strong>¡Hasta pronto!</strong>
      </p>
    </>
  );
}

export default ModalAlertES;
