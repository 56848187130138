import React, { useState, useEffect } from 'react';

import Slider from "react-slick";
//import configData from "../../../config/config.json";

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./ProductSlider.scss";

export interface arrayImages {arrayImages: any, imagesBaseUrl: any, alts?: any};

const TourSlider: React.FC<arrayImages> = ({
    arrayImages,imagesBaseUrl, alts
  }: arrayImages) => {
  const [nav1, setNav1] = useState<any>();
  const [nav2, setNav2] = useState<any>();
  const [slider1, setSlider1] = useState<any>();
  const [slider2, setSlider2] = useState<any>();

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  }, [slider1 ,slider2]);

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };

  const settingsThumbs = {
    slidesToShow: 10,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    //centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    infinite: false,
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 1,
          infinite: false
        }
      },
      {
        breakpoint: 481,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: false
        }
      }
    ]
  };

  return (

    <div className="tourSlider">

      <div className="slider-wrapper">

        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={slider => (setSlider1(slider))}
        >

          {arrayImages?.map((slide: any, index: any) =>
            <div className="slick-slide" key={index}>
              <img className="slick-slide-image" src={`${imagesBaseUrl}${slide}`} alt={""} />
            </div>

          )}

        </Slider>
        <div className="thumbnail-slider-wrap">
          
        </div>
      </div>

    </div>
  );
}

export default TourSlider;