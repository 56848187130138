import React from "react";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import { Card } from "react-bootstrap";
import LocationPT from "./components/LocationPT/LocationPT";
import LocationEN from "./components/LocationEN/LocationEN";
import LocationES from "./components/LocationES/LocationES";

function Location() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [t] = useTranslation();

    return (
        <>
            <Card className="py-4 px-3">

                {
                    i18next.language === "pt"
                        ?
                        <LocationPT />
                        :
                        i18next.language === "en"
                            ?
                            <LocationEN />
                            :
                            i18next.language === "es"
                                ?
                                <LocationES />
                                :
                                <LocationPT />
                }
            </Card>
        </>
    )
}

export default Location;