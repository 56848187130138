import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";

import { Link as LinkRoll } from "react-scroll";

function HowToGetCaracolES() {
    return (
        <>
            <div className="how-to-get-caracol">
                <div className="how-to-get-top">
                    <FontAwesomeIcon icon={["fas", "map-marker-alt"]} />
                    <span>Cómo Llegar</span>
                </div>

                <div className="how-to-get-description">
                    <p>
                        El <b>Parque del Caracol</b> está aproximadamente a 8,7 km del centro de la ciudad de Canela, en Rio Grande do Sul. Simplemente diríjase hacia la ciudad de Gramado, en Rio Grande do Sul, tomando la carretera nacional RS 466.
                    </p>
                    <p>
                        Parque del Caracol.
                    </p>
                </div>

                <LinkRoll
                    activeClass="active"
                    to="main-product"
                    spy={true}
                    smooth={true}
                    offset={-250}
                    duration={500}
                >
                    <Button variant="outline-primary mb-5">
                        Descubre las atracciones principales
                    </Button>
                </LinkRoll>
            </div>
        </>
    )
}

export default HowToGetCaracolES;