import React from "react";
import { useTranslation } from "react-i18next";
import PageDafault from "../../templates/PageDafault";
import Slider from "react-slick";

import pdf01 from '../../assets/img/pdf/Codigo-de-Conduta_page-0001.jpg';
import pdf02 from '../../assets/img/pdf/Codigo-de-Conduta_page-0002.jpg';
import pdf03 from '../../assets/img/pdf/Codigo-de-Conduta_page-0003.jpg';
import pdf04 from '../../assets/img/pdf/Codigo-de-Conduta_page-0004.jpg';
import pdf05 from '../../assets/img/pdf/Codigo-de-Conduta_page-0005.jpg';
import pdf06 from '../../assets/img/pdf/Codigo-de-Conduta_page-0006.jpg';
import pdf07 from '../../assets/img/pdf/Codigo-de-Conduta_page-0007.jpg';
import pdf08 from '../../assets/img/pdf/Codigo-de-Conduta_page-0008.jpg';
import pdf09 from '../../assets/img/pdf/Codigo-de-Conduta_page-0009.jpg';
import pdf10 from '../../assets/img/pdf/Codigo-de-Conduta_page-0010.jpg';
import pdf11 from '../../assets/img/pdf/Codigo-de-Conduta_page-0011.jpg';
import pdf12 from '../../assets/img/pdf/Codigo-de-Conduta_page-0012.jpg';
import pdf13 from '../../assets/img/pdf/Codigo-de-Conduta_page-0013.jpg';
import pdf14 from '../../assets/img/pdf/Codigo-de-Conduta_page-0014.jpg';
import pdf15 from '../../assets/img/pdf/Codigo-de-Conduta_page-0015.jpg';
import pdf16 from '../../assets/img/pdf/Codigo-de-Conduta_page-0016.jpg';
import pdf17 from '../../assets/img/pdf/Codigo-de-Conduta_page-0017.jpg';
import pdf18 from '../../assets/img/pdf/Codigo-de-Conduta_page-0018.jpg';
import pdf19 from '../../assets/img/pdf/Codigo-de-Conduta_page-0019.jpg';
import pdf20 from '../../assets/img/pdf/Codigo-de-Conduta_page-0020.jpg';

import './Compliance.scss';

function Compliance() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = useTranslation();

    const settings = {
        draggable: true,
        useTransform: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        speed: 500,
        initialSlide: 0,
        cssEase: "ease-out"
    };

    return (
        <>
            <PageDafault>
                <div className="container py-5">
                    <h1 className="mb-5 text-center display-2">Compliance</h1>
                    <div className="card py-4 px-3">
                        <p>
                            Nós da ITER PARTICIPAÇÕES S.A e todo o seu conglomerado, estamos comprometidos com a cultura de integridade. Nossas atividades e compromissos são pautados no cumprimento do nosso Código de Conduta e Ética, leis, regulamentos, demais políticas e diretrizes aplicáveis ao negócio.
                        </p>
                        <p>
                            O departamento de Compliance atua mediante a aplicação de um conjunto de medidas e procedimentos de forma a prevenir, detectar e tratar quaisquer desvios ou inconformidades que possam viabilizar a ocorrência de irregularidades, fraudes e corrupção.
                        </p>

                        <div className="w-100 m-auto my-2 bg-compliance">
                            <Slider {...settings}>
                                <img src={pdf01} alt="" width='90%' />
                                <img src={pdf02} alt="" width='90%' />
                                <img src={pdf03} alt="" width='90%' />
                                <img src={pdf04} alt="" width='90%' />
                                <img src={pdf05} alt="" width='90%' />
                                <img src={pdf06} alt="" width='90%' />
                                <img src={pdf07} alt="" width='90%' />
                                <img src={pdf08} alt="" width='90%' />
                                <img src={pdf09} alt="" width='90%' />
                                <img src={pdf10} alt="" width='90%' />
                                <img src={pdf11} alt="" width='90%' />
                                <img src={pdf12} alt="" width='90%' />
                                <img src={pdf13} alt="" width='90%' />
                                <img src={pdf14} alt="" width='90%' />
                                <img src={pdf15} alt="" width='90%' />
                                <img src={pdf16} alt="" width='90%' />
                                <img src={pdf17} alt="" width='90%' />
                                <img src={pdf18} alt="" width='90%' />
                                <img src={pdf19} alt="" width='90%' />
                                <img src={pdf20} alt="" width='90%' />
                            </Slider>
                        </div>

                    </div>
                </div>
            </PageDafault>
        </>
    )
}

export default Compliance;