import React from 'react';

import i18next from 'i18next';

import PageDafault from '../../../templates/PageDafault';

import CookiesCaracolPT from './components/CookiesCaracolPT/CookiesCaracolPT';
import CookiesCaracolEN from './components/CookiesCaracolEN/CookiesCaracolEN';
import CookiesCaracolES from './components/CookiesCaracolES/CookiesCaracolES';

import { useTranslation } from 'react-i18next';

function CookiesCaracol() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { t } = useTranslation();

    return (
        <PageDafault>
            <div className="container py-5">
                <h1 className="mb-5 text-center display-2">{t('cookies.title')}</h1>
                <div className="card py-4 px-3">
                    {
                        i18next.language === "pt"
                            ?
                            <CookiesCaracolPT />
                            :
                            i18next.language === "en"
                                ?
                                <CookiesCaracolEN />
                                :
                                i18next.language === "es"
                                    ?
                                    <CookiesCaracolES />
                                    :
                                    <CookiesCaracolPT />
                    }
                </div>
            </div>
        </PageDafault>
    )
}

export default CookiesCaracol;