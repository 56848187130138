import React, { useState, useEffect } from "react";

import i18next from "i18next";

import api from "../../services/api";

import './SlowBuy.scss';
import SlowBuyTour from "./SlowBuyTour/SlowBuyTour";
import SlowBuyTicket from "./SlowBuyTicket/SlowBuyTicket";

import TagManager from 'react-gtm-module';

function SlowBuy() {
    const [tourResponse, setTourResponse] = useState<any>("null");
    const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';

    const [langSelect, setLangSelect] = useState<any>(lng);
    const [count, setCount] = useState<number>(1);

    const url = window.location.href;
    const codeUrl = url.split("/");
    const slugUrl = codeUrl[codeUrl.length - 1].split("?")[0];
    const isTrilha = codeUrl[4].split("?")[2];

    useEffect(() => {
        let idChannel: number = 1;
        async function getItems() {
            const apiRequest = codeUrl[codeUrl.length - 1].split("?")[1].split('&')[0] === "type=tour" ?
                `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTourAsync` :
                `${process.env.REACT_APP_SERVER_URL_API}/api/Products/FetchSingleTicketAsync`;
            try {
                const { data } = await api.post(apiRequest,
                    {
                        productCode: "",
                        lang: lng,
                        Slug: `${slugUrl.replaceAll("%20", " ").replaceAll("%C3%A9", "é")}`,
                        idCanal: isTrilha === "trilha" ? 7 : idChannel
                    }
                );
                if (data.status !== 400) {
                    if (data.statusCode === 204) {
                        if(count <= 3) {
                            if(data.data === null && (i18next.language === "pt-BR" || i18next.language === 'pt')) {
                                setCount(count + 1);
                                setLangSelect('EN');
                                i18next.changeLanguage('en');
                            } else if(data.data === null && i18next.language === "en") {
                                setCount(count + 1);
                                setLangSelect('ES');
                                i18next.changeLanguage('es');
                            } else {
                                setCount(count + 1);
                                setLangSelect('BR');
                                i18next.changeLanguage('pt');
                            }
                        } else {
                            window.location.href = "/404"
                        }
                    } else {
                        setTourResponse(data.data);
                    }
                }
            } catch (error) { }
        }

        const config = {
            headers: { "ngrok-skip-browser-warning": "69420" },
        };

        async function getIdChannel() {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config);
                if (data.statusCode === 200) {
                    idChannel = data.data.data;
                    getItems()
                }
            } catch (error) { }
        }

        getIdChannel();

    }, [langSelect]);

    useEffect(() => {
        if (tourResponse !== "null") {
            TagManager.dataLayer({
                dataLayer: {
                    ecommerce: {
                        detail: {
                            products: [{
                                name: tourResponse.productName,         // Name or ID is required.
                                id: tourResponse.productCode,
                                price: tourResponse?.modalities[0]?.tarif?.price,
                                brand: 'Bondinho',
                            }]
                        }
                    }
                }
            });
        }
    }, [tourResponse]);

    if (tourResponse !== "null") {
        return (
            <>
                {
                    tourResponse.typeProduct === "TOUR"
                        ?
                        <SlowBuyTour
                            tourResponse={tourResponse}
                            lng={lng}
                        />
                        :
                        <SlowBuyTicket
                            tourResponse={tourResponse}
                            lng={lng}
                        />
                }
            </>
        );
    } else {
        return (
            <>
                <div className="container fast-buy-modal my-4">
                    <h2 className="fast-buy-title animated-background" style={{ height: "36px", width: "50%" }}></h2>
                    <div className="row fast-buy-content" style={{ height: "100%x" }}>
                        <div className="col-12 col-md-5 fast-buy-description" style={{ marginBottom: "40px" }}>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "50%", marginBottom: "15px" }}></div>

                            <div className='' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>

                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "50%", marginBottom: "15px" }}></div>

                            <div className='' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>

                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>
                            <div className='animated-background' style={{ height: "12px", width: "50%", marginBottom: "15px" }}></div>

                            <div className='' style={{ height: "12px", width: "100%", marginBottom: "15px" }}></div>

                            <div className='animated-background' style={{ height: "12px", width: "30%", marginBottom: "15px" }}></div>



                        </div>

                        <div className="col-12 col-md-7 fast-buy-people">
                            <h4 className="text-primary animated-background" style={{ height: "24px", width: "50%" }}></h4>
                            <div className="text-primary animated-background" style={{ height: "18px", width: "40%" }}></div>
                            <div className="text-primary animated-background" style={{ height: "36px", width: "100%", marginTop: "30px" }}></div>

                            <div className="buy-button">
                                <div className="btn btn-block  link-add animated-background" style={{ height: "36px", width: "30%", marginTop: "30px" }}></div>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        );
    }
}

export default SlowBuy;