import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./TourOptions.scss";
import DropdownNumberPeople from "../DropdownNumberPeople/DropdownNumberPeople";
import DoubleCalendar from "../Calendar/DoubleCalendar";
import Button from "@restart/ui/esm/Button";
import Modal from "react-bootstrap/Modal";
import i18next from "i18next";
import ArraiaPopupPT from "../SlowBuy/SlowBuyTour/components/ArraiaPopupPT";
import ArraiaPopupEN from "../SlowBuy/SlowBuyTour/components/ArraiaPopupEN";
import ArraiaPopupES from "../SlowBuy/SlowBuyTour/components/ArraiaPopupES";
import DropdownHours from "../DropdownHours/DropdownHours";
import RefundRules from "../RefundRules/RefundRules";
import { useAppSelector } from "../../store/hooks";

export interface options {
  options: any,
  response: any,
  action: any;
}

const TourOptions: React.FC<options> = ({
  options, response, action
}: options) => {
  const cartBPA: any = JSON.parse(
    JSON.stringify(useAppSelector((state: any) => state.cart))
  );

  const { t } = useTranslation();
  const [dateTour, setDateTour] = useState<any>([]);
  /*   const [hour, setHour] = useState<any>("19:00:00");
    const [productHours, setProductHours] = useState<any>([]); */
  const [id, setId] = useState<any>();
  /* const [alert, setAlert] = useState<any>(); */
  const [optionProduct, setOptionProduct] = useState<any>();
  const [alertPromo, setAlertPromo] = useState<any>(false);
  const [enableCart, setEnableCart] = useState<any>(false);
  const [alertBool, setAlertBool] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>("");
  const [alertArraia, setAlertArraia] = useState<any>(false);
  const [tariffs, setTariffs] = useState<any>({
    maxNumPeople: response.maxNumPeople,
    minNumPeople: response.minNumPeople
  });
  const [indexador, setIndexador] = useState<any>([]); ///array que me diz quais dos tourOptions vai habilitar o dropdown

  const [isIntegration, setIsIntegration] = useState<any>(false);
  const [ranges, setRanges] = useState<any>(null);
  const [showHours, setShowHours] = useState<any>(false);
  const [showDropdown, setShowDropdown] = useState<any>(false);

  useEffect(() => {
    var aux: any = [];
    for (var i = 0; i < options.length; i++) {
      aux.push({
        adult: 0,
        child: 0,
        infant: 0,
      })
    }
    setDateTour(aux);
  }, [options])

  const [numberPeople, setNumberPeople] = useState<any>(null);

  function changePriceDateTour(obj: any) {
    setShowHours(true);
    setDateTour(obj);
    setNumberPeople({
      adults: '1',
      childs: '0',
      infants: '0',
      elders: '0',
      student: '0',
      globalPeople: '0'
    });
  }

  const verifyItens = (option: any, index: any) => {
    setOptionProduct(option);
    if (numberPeople.adults + numberPeople.childs + numberPeople.infants + numberPeople.elders + numberPeople.student + numberPeople.globalPeople > 0) {
      auth(option, index)
    } else {
      setEnableCart(false);
      setAlertMessage(t("tour.alertSelectAPerson"));
      setAlertBool(true);
    }
  }

  const auth = (option: any, index: any) => {
    setId(option.id)
    if (numberPeople === null || dateTour.length < 1) {
      setAlertMessage(t("tour.alertFillAllData"));
      setAlertBool(true);
    } else {
      option.adults = numberPeople.adults;
      option.childs = numberPeople.childs;
      option.infants = numberPeople.infants;
      option.elders = numberPeople.elders;
      option.student = numberPeople.student;

      option.globalPeople = options[0].sellingType === 1 ? 1 : numberPeople.adults + numberPeople.childs + numberPeople.infants + numberPeople.elders + numberPeople.student + numberPeople.globalPeople;
      option.totalPeople = options[0].sellingType === 1 ? numberPeople.globalPeople : undefined;

      option.date = (document.getElementById(`date-${option.id}`) as HTMLInputElement).value.split('/').reverse().join('-');
      option.time = dateTour[index].time;

      const selectedDate = JSON.parse((document.getElementById(`date-${option.id}`) as HTMLInputElement).dataset.object || '{}');

      option.idTarif = selectedDate.idTarif;
      option.idPickup = (document.getElementById(`pickup`) as HTMLInputElement).value;


      option.priceAdults = selectedDate.priceAdultFinal;
      option.priceChilds = selectedDate.priceChildFinal;
      option.priceInfants = selectedDate.priceInfantFinal;
      option.priceElders = 0;
      option.priceStudent = 0;
      option.priceGlobalPeople = selectedDate.priceGlobalFinal;

      option.ranges = ranges[ranges.findIndex((elem: any) => elem.prodModCode === option?.prodModCode)];
      option.hours = dateTour[index].hours;

      option.productModCode = dateTour[index].prodModUniqueCode;

      action(option, response.productCode);
    }
  }

  const setNewNumberPeople = (peoples: any) => {
    setNumberPeople(peoples)
  }

  function addIndex(index: any) {
    var aux: any = indexador;
    if (aux.includes(index) === false) {
      aux.push(index);
    }
    setIndexador(aux);
  }

  return (
    <div>
      {options.length > 0
        ? options.map((option: any, index: any) => {
          return (
            <div key={index} className="row bg-white mt-3 mb-5">
              <div className="col-12 col-sm-8 col-md-8 col-lg-9 descricao pb-3 d-flex justify-content-between flex-column">
                <div>
                  <div className="pb-2">
                    <h5 id="name-mod">{option.modalityName}</h5>
                  </div>
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-6">
                      <h6>{t("productOptions.includes")}</h6>
                      <ul className="inclusoes" id="inclui">
                        {Array.from(option.includedItems).map(
                          (item: any, indexS: any) => (
                            <li key={indexS}>{item}</li>
                          )
                        )}
                      </ul>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <h6>{t("productOptions.notIncludes")}</h6>
                      <ul className="exclusoes" id="exclui">
                        {Array.from(option.excludedItems).map(
                          (iteme: any, indexE: any) => (
                            <li key={indexE}>{iteme}</li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="bg-cancel-rules">
                  <RefundRules info={response} index={index} />
                </div>
              </div>
              <div
                className="col-12 col-sm-4 col-md-4 col-lg-3 d-flex justify-content-center align-items-center preco"
                id="escolherdata"
              >
                <div className="d-block w-100 text-center ">
                  <small className="d-block">
                    {t("productOptions.precoPorAdulto")}
                  </small>
                  <div className="valor mt-2 mb-3">
                    <small>R$</small>{" "}
                    <strong id="preco-adulto">
                      {option.tarif !== null ? option.tarif.price.toFixed(2).replace(".", ",") : "??"}
                    </strong>
                    <input type="hidden" id="forIDTarif" value="9724" />
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="input-group mb-2">
                        <div className="input-group-prepend d-none">
                          <span
                            className="input-group-text rounded-left-lg"
                            id="homeDestino"
                          >
                            <i className="fal fa-calendar-plus fa-lg text-primary"></i>
                          </span>
                        </div>
                        <div onClick={() => { addIndex(index) }}>
                          <DoubleCalendar
                            modalityID={option.id}
                            product={option}
                            productCode={response.productCode}
                            changePriceDateTour={changePriceDateTour}
                            dateTour={dateTour}
                            index={index}
                            setRanges={setRanges}
                            setIsIntegration={setIsIntegration}
                          />
                        </div>
                        <input
                          type="hidden"
                          data-id="dataInit"
                          value="2021-09-28"
                        />
                        <input type="hidden" id="pickup" value={option.pickupListId} />
                      </div>
                    </div>
                    <div className="col-12">
                      {
                        showHours === true && dateTour[index]?.data
                          ?
                          <DropdownHours
                            changePriceDateTour={changePriceDateTour}
                            dateTour={dateTour}
                            index={index}
                            setPeopleDisponible={setShowDropdown}
                            isIntegration={isIntegration}
                            info={response}
                            isDetailsProduct={true}
                          />
                          :
                          <div className="panel-dropdown bg-white bg-option-hours">
                            <div style={{ height: "20px" }}></div>
                          </div>
                      }
                    </div>
                    <div className="col-12">
                      <div className="panel-dropdown bg-white">
                        {
                          showDropdown === true && dateTour[index]?.data
                            ?
                            <DropdownNumberPeople
                              info={response}
                              actionPeople={setNewNumberPeople}
                              dateTour={dateTour}
                              index={index}
                              tariffs={tariffs}
                              ranges={ranges[ranges.findIndex((elem: any) => elem.prodModCode === option?.prodModCode)]}
                            />
                            :
                            <div style={{ height: "20px" }}></div>
                        }
                      </div>
                    </div>
                  </div>
                  {
                    numberPeople !== null
                      ?
                      <div className="d-flex flex-column">
                        <button
                          title=""
                          className="btn btn-primary btn-block btn-add-cart link-add mb-2"
                          id=""
                          onClick={() => verifyItens(option, index)}
                          disabled={(response.reservationSystem === 99 && cartBPA?.isProductBdB === true) || (response.reservationSystem === 0 && cartBPA?.isProductC2Rio === true) ? true : false}
                        >
                          {t("productOptions.buy")}
                        </button>
                        {
                          (response.reservationSystem === 99 && cartBPA?.isProductBdB === true) || (response.reservationSystem === 0 && cartBPA?.isProductC2Rio === true)
                            ?
                            <div className="bg-danger-custom">
                              <small className="text-center" style={{ fontSize: ".6em" }}>
                                {t("slowBuy.tour.textOperator")}
                              </small>
                            </div>
                            :
                            ""
                        }
                      </div>
                      :
                      <div className="d-flex flex-column">
                        <button
                          title=""
                          className="btn btn-primary btn-block btn-add-cart link-add mb-2"
                          id=""
                          disabled={true}
                        >
                          {t("productOptions.buy")}
                        </button>
                        {
                          (response.reservationSystem === 99 && cartBPA?.isProductBdB === true) || (response.reservationSystem === 0 && cartBPA?.isProductC2Rio === true)
                            ?
                            <div className="bg-danger-custom">
                              <small className="text-center" style={{ fontSize: ".6em" }}>
                                {t("slowBuy.tour.textOperator")}
                              </small>
                            </div>
                            :
                            ""
                        }
                      </div>
                  }
                </div>
              </div>
            </div>
          );
        })
        : ""}
      <Modal
        className="modal-confirm"
        show={alertBool}
        onHide={() => {
          setAlertBool(false)
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className='modal-body text-center'>
          <div>
            <FontAwesomeIcon
              icon={["fal", "times-circle"]}
              size="5x"
              style={{ fontSize: "7.5em", color: "#FF6600" }}
            />

          </div>
          <div dangerouslySetInnerHTML={{
            __html: alertMessage,
          }} className="px-md-5" style={{ padding: "0 3rem" }}>

          </div>
          <div>
            <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={enableCart === true ? () => auth(optionProduct, 0) : () => setAlertBool(false)}>Fechar</Button>
          </div>
        </div>
      </Modal>

      <Modal
        className="modal-confirm"
        show={alertPromo}
        onHide={() => {
          setAlertPromo(false)
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <div className='modal-body text-center'>
          <div>
            <FontAwesomeIcon
              icon={["fal", "times-circle"]}
              size="5x"
              style={{ fontSize: "7.5em", color: "#FF6600" }}
            />

          </div>
          <div dangerouslySetInnerHTML={{
            __html: alertMessage,
          }} className="px-md-5" style={{ padding: "0 3rem" }}>
          </div>

          <div>
            <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setAlertPromo(false)}>Fechar</Button>
          </div>
        </div>
      </Modal>

      <Modal // Promoção Arraiá
        className={"modal-default"}
        show={alertArraia}
        onHide={() => {
          setAlertArraia(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <Modal.Header closeButton></Modal.Header>
          <div
            className="bg-top-modal"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_SERVER_MODAL_DEFAULT})`,
            }}
          >
            <img
              src={`${process.env.REACT_APP_SERVER_LOGO}`}
              alt="logo modal"
            />
          </div>
          <div className="bg-bottom-modal m-auto">
            <div className="arraia-popup">
              {
                i18next.language === "pt"
                  ?
                  <ArraiaPopupPT />
                  :
                  i18next.language === "en"
                    ?
                    <ArraiaPopupEN />
                    :
                    i18next.language === "es"
                      ?
                      <ArraiaPopupES />
                      :
                      <ArraiaPopupPT />
              }
            </div>
            <button
              className="btn btn-primary"
              onClick={enableCart === true ? () => auth(optionProduct, 0) : () => setAlertBool(false)}
            >
              Ok
            </button>
          </div>
        </>
      </Modal>
    </div>
  );
};

export default TourOptions;