import React from 'react';
import '../../App.css';

import './Login.scss';
import AuthLogin from '../../components/Authenticated/AuthLogin/AuthLogin';
import PageDafault from '../../templates/PageDafault';


function Login() {
    return (
        <>
            <PageDafault>
                <div className='container'>
                    <div className='login-container'>
                        <div className="modal-new-account">
                            <h1 className="display-5 mb-4">Login</h1>
                            <AuthLogin />
                        </div>
                    </div>
                </div>
            </PageDafault>
        </>
    );
}

export default Login;