import React from 'react';
import i18next from 'i18next';

import { useTranslation } from 'react-i18next';

import { Button, Col, Container, Row } from 'react-bootstrap';
import Slider from 'react-slick';

import { Link as LinkRoll } from 'react-scroll';
import { Link } from 'react-router-dom';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import './CaracolSlider.scss';

import Slider1 from '../../assets/img/parque_caracol/2.png';
import Slider2 from '../../assets/img/parque_caracol/3.png';
import Slider3 from '../../assets/img/parque_caracol/4.png';
import Slider4 from '../../assets/img/parque_caracol/5.png';
import Slider5 from '../../assets/img/parque_caracol/6.png';
import Slider6 from '../../assets/img/parque_caracol/7.png';
import Slider7 from '../../assets/img/parque_caracol/8.png';
import Slider8 from '../../assets/img/parque_caracol/9.png';
import Slider9 from '../../assets/img/parque_caracol/10.png';
import Slider10 from '../../assets/img/parque_caracol/12.png';
import Slider11 from '../../assets/img/parque_caracol/13.png';

// import Slider1 from '../../assets/img/NatalCaracol/natal-11.png';
// import Slider2 from '../../assets/img/NatalCaracol/natal-22.png';
// import Slider3 from '../../assets/img/NatalCaracol/natal-33.png';
// import Slider4 from '../../assets/img/NatalCaracol/natal-44.png';
// import Slider5 from '../../assets/img/NatalCaracol/natal-55.png';
// import Slider6 from '../../assets/img/NatalCaracol/natal-66.png';

export default function CaracolSlider() {
  const [t] = useTranslation();

  const images: any = [
    Slider1,
    Slider2,
    Slider3,
    Slider4,
    Slider5,
    Slider6,
    Slider7,
    Slider8,
    Slider9,
    Slider10,
    Slider11,
  ];

  const settings = {
    className: 'center',
    dots: false,
    arrows: true,
    centerMode: true,
    infinite: true,
    centerPadding: '0px',
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
  };

  return (
    <Container fluid className="container-caracol-slider">
      <Row className="gap-4 gap-md-0 w-100 mx-0">
        <Col className="px-0 px-sm-3" md={{ offset: 1, span: 6 }}>
          <Slider {...settings}>
            {images.map((item: any, index: any) => (
              <div key={index}>
                <div
                  className="img-slider-caracol"
                  key={index}
                  style={{ backgroundImage: `url(${item})` }}
                ></div>
              </div>
            ))}
          </Slider>
        </Col>

        <Col md={5} className="rediscover">
          <div className="rediscover-top">
            {i18next.language === 'pt' ? (
              <p>
                Naturalmente <br /> o seu Destino <br /> Parque do Caracol.
              </p>
            ) : i18next.language === 'en' ? (
              <p>
                Naturally <br /> your Destiny <br /> Caracol Park.
              </p>
            ) : i18next.language === 'es' ? (
              <p>
                Naturalmente <br /> tu Destino <br /> Parque Caracol.
              </p>
            ) : (
              <p>
                Naturalmente <br /> o seu Destino <br /> Parque do Caracol.
              </p>
            )}

            {/* Natal Caracol
            <p>{t('CaracolSlider.christmasCaracol')}</p> */}
          </div>

          <div className="rediscover-mid">
            <p>{t('CaracolSlider.text')}</p>

            {/* Natal Caracol */}

            {/* <p>{t('CaracolSlider.christmasText')}</p> */}
          </div>

          <div className="rediscover-button">
            <LinkRoll
              activeClass="active"
              to="main-product"
              spy={true}
              smooth={true}
              offset={-250}
              duration={500}
            >
              {/* <Link
              to={{
                pathname:
                  'https://ofertas.parquecaracol.com.br/natal-no-parque',
              }}
              target="_blank"
            > */}
              <Button variant="outline-light">
                {t('CaracolSlider.button')}
              </Button>
            </LinkRoll>
            {/* </Link> */}
          </div>
        </Col>
      </Row>
    </Container>
  );
}
