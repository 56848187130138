import React from "react";
import { useTranslation } from "react-i18next";

import { Link as LinkRoll } from "react-scroll";

import './CardTourSide.scss';

export interface options { options: any };

const CardTourSide: React.FC<options> = ({
    options,
}: options) => {
    const { t } = useTranslation();

    let aux: any = [];

    for (let i = 0; i < options.length; i++) {
        aux.push(options[i].tarif.price);
    }

    return (
        <div className="bg-scroll">
            <div className="card-side mt-4 p-4 text-center">
                {
                    process.env.REACT_APP_CLIENT_NAME === "Bondinho"
                    ?
                    <small>{t("tour.cardSide.priceText")}</small>
                    :
                    <></>
                }
                <p><small>R$</small><b className="display-3">{Math.min(...aux).toFixed(2).replace('.', ',')}</b></p>
                <LinkRoll
                    activeClass="active"
                    to="modalidades"
                    spy={true}
                    smooth={true}
                    offset={-80}
                    duration={500}
                >
                    <button className="btn btn-card-side">{t("tour.cardSide.buyBtn")}</button>
                </LinkRoll>
            </div>
        </div>
    )
}

export default CardTourSide;