import React, { useState, useEffect, Key } from 'react';
//import api from "../../../services/api";
//import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import DoubleCalendar from '../../Calendar/DoubleCalendar';

import { useTranslation } from 'react-i18next';
import Button from '@restart/ui/esm/Button';
import Modal from 'react-bootstrap/Modal';
//import SetCartItem from "../../Cart/SetCartItem";

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addItemCart } from '../../../store/cart';

/* import ModalDefault from "../../Modal/ModalDefault/ModalDefault";
import ReceiptAlert from "../../Alert/ReceiptAlert/ReceiptAlert"; */

import SimpleAlert from '../../Alert/SimpleAlert/SimpleAlert';

import { Link } from 'react-router-dom';
import i18next from 'i18next';
//import ModalAlert from "../../Modal/ModalAlert/ModalAlert";
import ArraiaPopupPT from './components/ArraiaPopupPT';
import ArraiaPopupEN from './components/ArraiaPopupEN';
import ArraiaPopupES from './components/ArraiaPopupES';
import DropdownHours from '../../DropdownHours/DropdownHours';
import api from '../../../services/api';

/* import ModalDefault from "../../Modal/ModalDefault/ModalDefault";
import ModalPrivacy from "../../Modal/ModalPrivacy/ModalPrivacy"; */

export interface props {
  tourResponse: any;
  lng: any;
}

const FastBuyTour: React.FC<props> = ({ tourResponse, lng }: props) => {
  let date = new Date().toLocaleString('pt');
  const dataNow = date
    .split(' ')[0]
    .replaceAll(',', '')
    .split('/')
    .reverse()
    .join('');

  const url = window.location.href;
  const codeUrl = url.split('/');
  const slugUrl = codeUrl[codeUrl.length - 1].split('?')[0];
  const isTrilha = codeUrl[4].split('?')[2];

  const { t } = useTranslation();

  const [numberAdults, setNumberAdults] = useState<number>(0);
  const [numberChilds, setNumberChilds] = useState<number>(0);
  const [numberInfants, setNumberInfants] = useState<number>(0);
  const [numberStudent, setNumberStudent] = useState<number>(0);
  const [numberElders, setNumberElders] = useState<number>(0);
  const [numberPrivate, setNumberPrivate] = useState<number>(0);
  const [numberTotal, setNumberTotal] = useState<number>(1);
  const [numberStockTotal, setNumberStockTotal] = useState<number>(0);
  const [dateTour, setDateTour] = useState<any>([]);
  const [price, setPrice] = useState<any>([]);
  const [numberPeople, setNumberPeople] = useState<any>(null);
  const [showCalendar, setShowCalendar] = useState<any>(false);
  const [showHours, setShowHours] = useState<any>(false);
  const [showDropdown, setShowDropdown] = useState<any>(false);
  const [modalityNumber, setModalityNumber] = useState<any>(0);
  const [closeAccord, setCloseAccord] = useState<any>(1);
  const [alert, setAlert] = useState<any>(false);
  const [alertPromo, setAlertPromo] = useState<any>(false);
  const [alertArraia, setAlertArraia] = useState<any>(false);
  const [enableCart, setEnableCart] = useState<any>(false);
  const [alertMessage, setAlertMessage] = useState<any>('');

  const [order, setOrder] = useState<any>(0);

  const [ranges, setRanges] = useState<any>(null);

  const [lang, setLang] = useState<any>();

  const [isIntegration, setIsIntegration] = useState<any>(false);
  const [boxInfo, setBoxInfo] = useState<boolean>();

  const cartBPA: any = JSON.parse(
    JSON.stringify(useAppSelector((state: any) => state.cart)),
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (lng === 'BR') {
      setLang(tourResponse.slugBR);
    } else if (lng === 'EN') {
      setLang(tourResponse.slugEN);
    } else {
      setLang(tourResponse.slugES);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourResponse]);

  const handleClick = (controll: string, faixa: string) => {
    let stateNumber: any;

    if (faixa === 'Adult') {
      stateNumber = numberAdults;
    } else if (faixa === 'Child') {
      stateNumber = numberChilds;
    } else if (faixa === 'Infant') {
      stateNumber = numberInfants;
    } else if (faixa === 'Elders') {
      stateNumber = numberElders;
    } else if (faixa === 'Student') {
      stateNumber = numberStudent;
    } else {
      stateNumber = numberPrivate;
    }

    let newNumber = numberAdults;

    if (controll === 'sub') {
      if (stateNumber === 0) {
        return false;
      } else {
        newNumber = stateNumber - ranges[`increment${faixa}`];
        if (ranges[`flagStock${faixa}`] === 1) {
          setNumberStockTotal(numberStockTotal - ranges[`increment${faixa}`]);
        }
      }
    }
    if (controll === 'plus') {
      if (ranges[`flagStock${faixa}`] === 1) {
        if (
          (numberStockTotal + ranges[`increment${faixa}`] <=
            dateTour[modalityNumber].maxStockNumber) && (numberStockTotal + ranges[`increment${faixa}`] <= 6)
        ) {
          newNumber = stateNumber + ranges[`increment${faixa}`];
          setNumberStockTotal(numberStockTotal + ranges[`increment${faixa}`]);
        } else {
          return false;
        }
      } else {
        newNumber = stateNumber + ranges[`increment${faixa}`];
      }
    }

    if (faixa === 'Adult') {
      return setNumberAdults(newNumber);
    } else if (faixa === 'Child') {
      return setNumberChilds(newNumber);
    } else if (faixa === 'Infant') {
      return setNumberInfants(newNumber);
    } else if (faixa === 'Elders') {
      return setNumberElders(newNumber);
    } else if (faixa === 'Student') {
      return setNumberStudent(newNumber);
    } else {
      return setNumberPrivate(newNumber);
    }
  };

  function changePriceDateTour(obj: any) {
    setCloseAccord(0);
    setShowHours(true);
    setPrice(obj);
    setDateTour(obj);
    setNumberPeople({
      adults: '1',
      childs: '0',
      infants: '0',
      elders: '0',
      student: '0',
      globalPeople: '0',
    });
  }

  useEffect(() => {
    var aux: any = [];
    for (var i = 0; i < tourResponse.modalities.length; i++) {
      aux.push({
        adult: 0,
        child: 0,
        infant: 0,
      });
    }
    setDateTour(aux);
  }, [tourResponse.modalities]);

  useEffect(() => {
    setNumberTotal(
      numberAdults +
      numberChilds +
      numberInfants +
      numberElders +
      numberStudent +
      numberPrivate,
    );
  }, [
    numberAdults,
    numberChilds,
    numberInfants,
    numberElders,
    numberStudent,
    numberPrivate,
  ]);

  const addToCart = (itemOption: any, productCode: any) => {
    var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

    cartBPA.dados.forEach((elem: any) => {
      if (elem.productCode === productCode) {
        repeatedItem = true;
      }
    });

    if (repeatedItem === true) {
      setAlertMessage(t('tour.alertRepeatedItem'));
      setAlert(true);
      setAlertArraia(false);
    } else {
      const item = {
        productName: tourResponse.productName,
        productNameBR: tourResponse.productNameBR,
        productNameEN: tourResponse.productNameEN,
        productNameES: tourResponse.productNameES,
        imgBaseUrl: tourResponse.imagesBaseUrl,
        imgCart: tourResponse.images[0],
        price: itemOption?.tarif?.price,
        activityStart: tourResponse.modalities[modalityNumber].activityStart,

        idTarif: itemOption.idTarif,
        idPickup: itemOption.idPickup,

        priceAdults: itemOption.priceAdults,
        priceChilds: itemOption.priceChilds,
        priceInfants: itemOption.priceInfants,
        priceElders: itemOption.priceElders,
        priceStudent: itemOption.priceStudent,
        priceGlobalPeople: itemOption.priceGlobalPeople,
        reservationSystem: tourResponse.reservationSystem,

        ranges: ranges,
        hours: dateTour[modalityNumber].hours,

        sellingType: tourResponse.idSellingType,
        adults: itemOption.adults,
        childs: itemOption.childs,
        infants: itemOption.infants,
        elders: itemOption.elders,
        student: itemOption.student,

        globalPeople: itemOption.globalPeople,
        totalPeople: itemOption.totalPeople,

        productType: 1,
        productCode: tourResponse.productCode,
        productModCode: dateTour[modalityNumber].prodModUniqueCode,

        /* productSlug: slugUrl, */
        slugBR: tourResponse.slugBR,
        slugEN: tourResponse.slugEN,
        slugES: tourResponse.slugES,
        time: itemOption.time,
        date: itemOption.date,
        supplier: tourResponse.supplier,
        typePickup: itemOption.embarkingType,
        meetingPoint:
          itemOption.embarkingType === '0' ? itemOption.meetingPoint : '',
        pickupListId:
          itemOption.embarkingType === '0' ? 0 : itemOption.pickupListId,
        pickup: 0,

        discount: 0,
        customValueNet: 0,
        customValueSell: 0,
        goingSource: 'null',
        commingSource: 'null',
        latOrigem: 'null',
        lngOrigem: 'null',
        latDestino: 'null',
        lngDestino: 'null',
        cia: 'null',
        voo: 'null',
        smallSuitcase: 0,
        bigSuitcase: 0,
        internalNotes: ' ',
        idVeiculo: 0,
        passengers: [],

        channel: isTrilha === 'trilha' ? 7 : undefined,
      };

      //SetCartItem(item);
      dispatch(addItemCart(item));
      window.location.href = window.location.origin + '/checkout';
    }
  };

  const verifyItens = (option: any) => {
    if (
      numberAdults +
      numberChilds +
      numberInfants +
      numberElders +
      numberStudent +
      numberPrivate >
      0
    ) {
      if (
        tourResponse.productCode !== process.env.REACT_APP_MARAVILHA &&
        tourResponse.productCode !== process.env.REACT_APP_ARRAIA
      ) {
        if (numberInfants > 0 || numberChilds > 0) {
          setEnableCart(true);
          setAlert(true);

          if (
            tourResponse.productCode === 'PDN8707' ||
            tourResponse.productCode === 'PBN3638' ||
            tourResponse.productCode === 'PRO2086'
          ) {
            setAlertMessage(`${t('slowBuy.tour.alertMinimium')}`);
          } else {
            setAlertMessage(`${t('slowBuy.tour.alert')}`);
          }
        } else {
          auth(option);
        }
      } else if (tourResponse.productCode === process.env.REACT_APP_ARRAIA) {
        // Promoção Arraiá
        setEnableCart(true);
        setAlertArraia(true);
      } else {
        if (
          (numberInfants > 0 &&
            tourResponse.productCode === process.env.REACT_APP_MARAVILHA) ||
          numberChilds > 0
        ) {
          setEnableCart(true);
          setAlert(true);
          setAlertMessage(`${t('slowBuy.tour.alert')}`);
        } else {
          auth(option);
        }
      }
    } else {
      setEnableCart(true);
      setAlertMessage(t('tour.alertSelectAPerson'));
      setAlert(true);
    }
  };

  const auth = (option: any) => {
    if (numberPeople === null || dateTour.length < 1) {
      setAlertMessage(t('tour.alertFillAllData'));
      // setAlert(true);
    } else {
      option.adults = numberAdults;
      option.childs = numberChilds;
      option.infants = numberInfants;
      option.elders = 0;
      option.student = 0;

      option.globalPeople =
        dateTour[modalityNumber].idSellingType === 1
          ? 1
          : numberAdults +
          numberChilds +
          numberInfants +
          numberElders +
          numberStudent +
          numberPrivate;
      option.totalPeople =
        dateTour[modalityNumber].idSellingType === 1
          ? numberPrivate
          : undefined;

      option.date = (
        document.getElementById(`date-${option.id}`) as HTMLInputElement
      ).value
        .split('/')
        .reverse()
        .join('-'); ///DoubleCalendar
      /* option.date = (document.getElementById(`date-hidden`) as HTMLInputElement).value.split('/').reverse().join('-'); */ ///FastBuyCalendar
      option.time = dateTour[modalityNumber].time;

      const selectedDate = JSON.parse(
        (document.getElementById(`date-${option.id}`) as HTMLInputElement)
          .dataset.object || '{}',
      ); ///DoubleCalendar
      /* const selectedDate = JSON.parse((document.getElementById(`date-hidden`) as HTMLInputElement).dataset.object || '{}'); */ ///FastBuyCalendar

      option.idTarif = selectedDate.idTarif;
      /* option.idPickup = (document.getElementById(`pickup`) as HTMLInputElement).value; */
      option.idPickup = '';

      option.priceAdults = selectedDate.priceAdultFinal;
      option.priceChilds = selectedDate.priceChildFinal;
      option.priceInfants = selectedDate.priceInfantFinal;
      option.priceElders = 0;
      option.priceStudent = 0;
      option.priceGlobalPeople = selectedDate.priceGlobalFinal;

      /* Campanha Parque Caracol Pascoa */
      addToCart(option, tourResponse.productCode);
      /* END - Campanha Parque Caracol Pascoa */
    }
  };

  const handleChangeModalities = (value: any) => {
    setShowCalendar(false);
    setShowDropdown(false);
    setModalityNumber(value);
    let timer1 = setTimeout(() => setShowCalendar(true), 100);

    return () => {
      clearTimeout(timer1);
    };
    // setShowCalendar(true);
  };

  useEffect(() => {
    let date = new Date().toLocaleString('pt-BR');
    let dateNow = date
      .split(' ')[0]
      .split('/')
      .reverse()
      .join('')
      .replaceAll(',', '');

    const newSlug =
      i18next.language === 'pt'
        ? tourResponse.slugBR
        : i18next.language === 'en'
          ? tourResponse.slugEN
          : i18next.language === 'es'
            ? tourResponse.slugES
            : tourResponse.slugBR;

    if (
      slugUrl !== newSlug.split(' ').join('%20').split('í').join('%C3%AD') &&
      newSlug !== undefined
    ) {
      window.location.href =
        window.location.origin + '/compra-rapida/' + newSlug + '?type=tour';
    }
    if (`${dateNow}` <= '20240101') {
      setBoxInfo(true);
    } else {
      setBoxInfo(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  useEffect(() => {
    setNumberAdults(0);
    setNumberChilds(0);
    setNumberInfants(0);
    setNumberStudent(0);
    setNumberElders(0);
    setNumberPeople(0);
    setNumberStockTotal(0);
    setNumberTotal(0);
    //setNumberTotalPeople(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dateTour[modalityNumber]?.time,
    dateTour[modalityNumber]?.data,
    modalityNumber,
  ]);

  function HandleCheckout() {
    setOrder(order + 1);
    if (
      tourResponse.productCode === process.env.REACT_APP_MARAVILHA &&
      numberInfants > 0
    ) {
      if (enableCart === true && order === 1) {
        auth(tourResponse.modalities[modalityNumber]);
      } else {
        setAlert(true);
        setAlertMessage(
          `<p class='mb-3'>${t('tour.promotionMother')}</p><p>${t(
            'tour.subPromotionMother',
          )}</p>`,
        );
        setEnableCart(true);
      }
    } else {
      if (enableCart === true) {
        auth(tourResponse.modalities[modalityNumber]);
      } else {
        setAlert(false);
      }
    }
  }

  return (
    <>
      <div className="container fast-buy-modal my-4">
        <h2 className="fast-buy-title">
          {tourResponse.modalities[modalityNumber].modalityName}
        </h2>

        <div className="row fast-buy-content">
          <div className="col-12 col-md-5 fast-buy-description">
            <span
              id="description"
              dangerouslySetInnerHTML={{
                __html: tourResponse.productInfo,
              }}
            ></span>
            <Link
              to={
                tourResponse.typeProduct === 'TOUR'
                  ? `/tour/${lang}`
                  : `/ticket/${lang}`
              }
              className="mt-3"
              style={{ color: '#000' }}
            >
              {t('slowBuy.tour.moreDetails')}
            </Link>
          </div>
          <div className="col-12 col-md-7 fast-buy-people">
            <h4 className="text-primary">{t('slowBuy.tour.title')}</h4>
            <div>
              <div className="">
                <div className="panel-dropdown-content right active">
                  <div className="select-date">
                    <div className="mb-5">
                      <p style={{ fontSize: '18px', color: '#707070' }}>
                        <strong>{t('slowBuy.tour.passo01')}</strong>{' '}
                        {t('slowBuy.tour.title')}
                      </p>
                      <select
                        onChange={(e: any) => {
                          handleChangeModalities(e.target.value);
                        }}
                        className="form-control rounded-0 bg-white py-2 rounded modality-input"
                        name="modalities"
                        id="modalities"
                      >
                        <option value="" disabled selected>
                          {t('slowBuy.tour.title')}
                        </option>
                        {tourResponse.modalities.length > 0
                          ? tourResponse.modalities.map(
                            (item: any, index: Key) => {
                              return (
                                <>
                                  <option value={index}>
                                    {item.modalityName}
                                  </option>
                                </>
                              );
                            },
                          )
                          : ''}
                      </select>
                    </div>
                    <div className="mb-5">
                      {showCalendar === true ? (
                        <>
                          <p style={{ fontSize: '18px', color: '#707070' }}>
                            <strong>{t('slowBuy.tour.passo02')}</strong>{' '}
                            {t('slowBuy.tour.select01')}
                          </p>
                          <DoubleCalendar
                            modalityID={
                              tourResponse.modalities[modalityNumber].id
                            }
                            product={tourResponse.modalities[modalityNumber]}
                            productCode={tourResponse.productCode}
                            changePriceDateTour={changePriceDateTour}
                            dateTour={dateTour}
                            index={modalityNumber}
                            setRanges={setRanges}
                            setIsIntegration={setIsIntegration}
                          ></DoubleCalendar>
                        </>
                      ) : (
                        ''
                      )}
                    </div>

                    {showHours ? (
                      <DropdownHours
                        changePriceDateTour={changePriceDateTour}
                        dateTour={dateTour}
                        index={modalityNumber}
                        setPeopleDisponible={setShowDropdown}
                        isIntegration={isIntegration}
                        info={tourResponse}
                      />
                    ) : (
                      ''
                    )}

                    <div>
                      {showDropdown === true ? (
                        <>
                          <p style={{ fontSize: '18px', color: '#707070' }}>
                            <strong>
                              {dateTour[modalityNumber]?.hours?.length !== 1
                                ? 'Passo 04:'
                                : 'Passo 03:'}
                            </strong>{' '}
                            {t('slowBuy.ticket.select03')}
                          </p>

                          {ranges.labelsRealSorted.map(
                            (faixa: any, index: any) => {
                              let stateNumber: any;

                              if (faixa === 'Adult') {
                                stateNumber = numberAdults;
                              } else if (faixa === 'Child') {
                                stateNumber = numberChilds;
                              } else if (faixa === 'Infant') {
                                stateNumber = numberInfants;
                              } else if (faixa === 'Elders') {
                                stateNumber = numberElders;
                              } else if (faixa === 'Student') {
                                stateNumber = numberStudent;
                              } else {
                                stateNumber = numberPrivate;
                              }

                              return (
                                <>
                                  {ranges[`isActive${faixa}`] === true ? (
                                    <div className="qtyButtons">
                                      <div className="flex-ajuste">
                                        <label>{ranges[`label${faixa}`]}</label>
                                        <small id="idade-crianca" style={{ fontSize: '10px', color: "#707070" }}> {(dateTour[modalityNumber][`${faixa.toLowerCase()}Text`])}</small>
                                      </div>
                                      <div className="d-flex">
                                        <button
                                          className="qtyDec"
                                          onClick={() =>
                                            handleClick('sub', faixa)
                                          }
                                        >
                                          <span>-</span>
                                        </button>
                                        <input
                                          type="text"
                                          name="qtyInput"
                                          value={stateNumber}
                                          id="numero-adulto"
                                        />
                                        <button
                                          className="qtyInc"
                                          onClick={() =>
                                            handleClick('plus', faixa)
                                          }
                                        >
                                          <span>+</span>
                                        </button>
                                      </div>
                                      <div className="price">
                                        {faixa === 'Private' ? (
                                          <span
                                            className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                            id="valor-adulto-sm"
                                          >
                                            R${' '}
                                            {price[0].global
                                              .toFixed(2)
                                              .split('.')
                                              .join(',')}
                                          </span>
                                        ) : (
                                          <span
                                            className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                            id="valor-adulto-sm"
                                          >
                                            R${' '}
                                            {price[0][`${faixa.toLowerCase()}`]
                                              .toFixed(2)
                                              .split('.')
                                              .join(',')}
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            },
                          )}

                          {(numberStockTotal >=
                            dateTour[modalityNumber].maxStockNumber) || (numberStockTotal >= 6) ? (
                            <SimpleAlert alertType="danger">
                              {t('slowBuy.ticket.limit')}
                            </SimpleAlert>
                          ) : (
                            ''
                          )}

                          <hr />
                          <div className="subtotal flex-ajuste">
                            <h5>{t('slowBuy.ticket.sub')}</h5>
                            <span
                              className="price-dropdown text-dark font-weight-bold d-block"
                              id="valor-subtotal-sm"
                            >
                              {' '}
                              R${' '}
                              {(
                                price[0].adult * numberAdults +
                                price[0].child * numberChilds +
                                price[0].infant * numberInfants +
                                price[0].elders * numberElders +
                                price[0].student * numberStudent +
                                (numberPrivate < 1 ? 0 : price[0].global)
                              )
                                .toFixed(2)
                                .split('.')
                                .join(',')}
                            </span>
                          </div>

                          <div className="attentionTour">
                            <small>
                              <strong>{t('slowBuy.tour.attentionTour')}</strong>
                            </small>
                          </div>

                          {boxInfo === true ? (
                            <div className="box-info">
                              <small>{t('slowBuy.tour.boxInfo')}</small>
                              <br></br>
                              <small>{t('slowBuy.tour.boxInfoNextDate')}</small>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div>
                            <small>{t('slowBuy.tour.otherTextCapsula')}</small>
                          </div>

                          <div className="warning mb-0">
                            <p>{t('slowBuy.ticket.alert')}</p>
                          </div>
                          <div style={{ lineHeight: '1.25rem' }}>
                            <small>
                              <strong>{t('slowBuy.tour.tip')}</strong>
                            </small>
                          </div>
                        </>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/* <DoubleCalendar modalityID={tourResponse.modalities[0].id} product={tourResponse.modalities[0]} productCode={tourResponse.productCode} /> */}
            </div>
            <div className="buy-button">
              {numberPeople !== null ? (
                <div className="d-flex flex-column align-items-center">
                  <button
                    title=""
                    className="btn btn-primary btn-block btn-add-cart link-add mb-1"
                    id=""
                    onClick={() =>
                      verifyItens(tourResponse.modalities[modalityNumber])
                    }
                    disabled={
                      (tourResponse.reservationSystem === 99 &&
                        cartBPA?.isProductBdB === true) ||
                        (tourResponse.reservationSystem === 0 &&
                          cartBPA?.isProductC2Rio === true)
                        ? true
                        : false
                    }
                  >
                    {t('productOptions.buy')}
                  </button>

                  {(tourResponse.reservationSystem === 99 &&
                    cartBPA?.isProductBdB === true) ||
                    (tourResponse.reservationSystem === 0 &&
                      cartBPA?.isProductC2Rio === true) ? (
                    <div className="bg-danger-custom">
                      <small className="text-center" style={{ fontSize: ".6em" }}>
                        {t("slowBuy.tour.textOperator")}
                      </small>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <Modal
            className="modal-confirm"
            show={alert}
            onHide={() => {
              setAlert(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <div className="modal-body text-center">
              <div>
                <FontAwesomeIcon
                  icon={['fal', 'times-circle']}
                  size="5x"
                  style={{ fontSize: '7.5em', color: '#FF6600' }}
                />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: alertMessage,
                }}
                className="px-md-4"
                style={{ padding: '0 3rem' }}
              ></div>

              <div>
                <Button
                  type="submit"
                  className="btn btn-primary form-button px-4 py-2"
                  onClick={HandleCheckout}
                >
                  Fechar
                </Button>
              </div>
            </div>
          </Modal>

          <Modal
            className="modal-confirm"
            show={alertPromo}
            onHide={() => {
              setAlertPromo(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <div className="modal-body text-center">
              <div>
                <FontAwesomeIcon
                  icon={['fal', 'times-circle']}
                  size="5x"
                  style={{ fontSize: '7.5em', color: '#FF6600' }}
                />
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: alertMessage,
                }}
                className="px-md-5"
                style={{ padding: '0 3rem' }}
              ></div>

              <div>
                <Button
                  type="submit"
                  className="btn btn-primary form-button px-4 py-2"
                  onClick={() => setAlertPromo(false)}
                >
                  Fechar
                </Button>
              </div>
            </div>
          </Modal>

          <Modal // Promoção Arraiá
            className={'modal-default'}
            show={alertArraia}
            onHide={() => {
              setAlertArraia(false);
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <>
              <Modal.Header closeButton></Modal.Header>
              <div
                className="bg-top-modal"
                style={{
                  backgroundImage: `url(${process.env.REACT_APP_SERVER_MODAL_DEFAULT})`,
                }}
              >
                <img
                  src={`${process.env.REACT_APP_SERVER_LOGO}`}
                  alt="logo modal"
                />
              </div>
              <div className="bg-bottom-modal m-auto">
                <div className="arraia-popup">
                  {i18next.language === 'pt' ? (
                    <ArraiaPopupPT />
                  ) : i18next.language === 'en' ? (
                    <ArraiaPopupEN />
                  ) : i18next.language === 'es' ? (
                    <ArraiaPopupES />
                  ) : (
                    <ArraiaPopupPT />
                  )}
                </div>
                <button
                  className="btn btn-primary"
                  onClick={
                    enableCart === true
                      ? () => auth(tourResponse.modalities[modalityNumber])
                      : () => setAlertArraia(false)
                  }
                >
                  Ok
                </button>
              </div>
            </>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default FastBuyTour;
