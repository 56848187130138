import React from "react";
import i18next from "i18next";

import { useTranslation } from "react-i18next";

import PageDafault from "../../templates/PageDafault";
import WorkUsPT from "./components/WorkUsPT/WorkUsPT";
import WorkUsEN from "./components/WorkUsEN/WorkUsEN";
import WorkUsES from "./components/WorkUsES/WorkUsES";

function WorkUs() {
    const [t] = useTranslation();

    return (
        <PageDafault>
            <div className="container py-5">
                <h1 className="mb-5 text-center display-2">{t('footer.menu.workus')}</h1>
                <div className="card py-4 px-3">
                    {
                        i18next.language === "pt"
                            ?
                            <WorkUsPT />
                            :
                            i18next.language === "en"
                                ?
                                <WorkUsEN />
                                :
                                i18next.language === "es"
                                    ?
                                    <WorkUsES />
                                    :
                                    <WorkUsPT />
                    }
                </div>
            </div>
        </PageDafault>
    )
}

export default WorkUs;