import React from "react";

function LocationES() {
    return (
        <>
            <div className="p-3">
                <div className="top">
                    <p>¡Verifique abajo las diversas opciones de transporte para llegar al Parque Bondinho y elija la que más se adecua a su itinerario!</p>
                    <p>Estamos localizados en <b>Av. Pasteur, 520 – Urca, Río de Janeiro.</b></p>
                </div>

                <h6>Metro</h6>
                
                <p>Desembarque en la estación Botafogo, por la salida E – Mena Barreto. Camine hasta la parada final del autobús 513, en Rua Voluntários da Pátria, para tomarlo y baje en la parada de Universidad UNIRIO. Desde allá, camine 260 metros hasta el Parque Bondinho.</p>

                <h6>Bicicleta</h6>

                <p>Parque Bondinho no tiene un bicicletero, pero usted puede dejar su bicicleta junto al quiosco de periódicos delante del restaurante Árabe da Urca, muy cerca del Parque Bondinho. Traiga su candado. Un poco antes, en Av. Pasteur, también hay un bicicletero de Itaú disponible.</p>

                <h6>Coche</h6>

                <p>Parque Bondinho no dispone de aparcamiento rotativo, pero hemos separado algunos estacionamientos cercanos para usted:</p>
                <ul>
                    <li>Shopping Rio Sul – Rua Lauro Müller, 116 – Botafogo</li>
                    <li>Shopping Casa & Gourmet – Rua Gen. Severiano, 97 – Botafogo</li>
                </ul>

                <p>Confiera algunas plazas rotativas en el barrio de Urca:</p>

                <ul>
                    <li>Rua Odilio Bacelar</li>
                    <li>Rua Osório de Almeida</li>
                    <li>Rua Urbano Santos</li>
                    <li>Avn. Portugal</li>
                    <li>Rua Mal. Cantuária</li>
                </ul>

                <h6>Ferry</h6>

                <p>Los visitantes de la ciudad de Niterói o de las Islas del Gobernador y de Paquetá pueden tomar el ferry hasta Praça XV. Desde allá, se camina hasta el Largo de Carioca (740 metros) o se toma la Línea 2 del VLT (Vehículo Leve sobre Rieles) hasta la estación Cristiano Ottoni – Pequena África para coger el autobús 107 (Central). Baje en Universidad UNIRIO y camine 180 metros hasta el Parque Bondinho.</p>

                <div className="bottom">
                    <h6>Autobús</h6>

                    <p>Las principales líneas para llegar al Parque Bondinho son:</p>
                    <ul>
                        <li>107 – Central – Urca (Sistema de Autobús de Tránsito Rápido – BRS – 1, por Playa de Flamengo)</li>
                        <li>513SP – Urca – Metro Botafogo (Por Shopping Rio Sul – Integración con el metro)</li>
                        <li>518 – Urca - Botafogo (Circular) – por Copacabana</li>
                        <li>519 – Urca - Copacabana – por Botafogo</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LocationES;