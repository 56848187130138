import React from "react";

function LocationPT() {
    return (
        <>
            <div className="p-3">
                <div className="top">
                    <p>Confira abaixo diversas opções de transporte para chegar ao Parque Bondinho e escolha a que mais se adequa ao seu roteiro!</p>
                    <p>Estamos localizados na <b>Av Pasteur, 520 – Urca, Rio de Janeiro.</b></p>
                </div>

                <h6>Metrô</h6>
                <p>Desembarque na estação Botafogo, pela saída E - Mena Barreto. Caminhe até o ponto final do ônibus 513 na Rua Voluntários da Pátria e desça na UNIRIO. De lá, caminhe 260m até o Parque Bondinho.</p>

                <h6>Bicicleta</h6>

                <p>O Parque Bondinho não dispõe de um bicicletário. Mas você pode deixar a bicicleta próximo à banca de jornal em frente ao restaurante Árabe da Urca, bem próximo ao Parque Bondinho. Traga seu cadeado. Na Av Pasteur também está disponível, um pouco antes, uma estação de Bike Itaú.</p>

                <h6>Carro</h6>

                <p>O Parque Bondinho não possui estacionamento rotativo, mas separamos alguns estacionamentos próximos para você:</p>
                <ul>
                    <li>Shopping Rio Sul – Rua Lauro Muller, 116 – Botafogo</li>
                    <li>Shopping Casa & Gourmet – Rua Gen. Severiano, 97 – Botafogo</li>
                </ul>

                <p>Confira algumas vagas rotativas ao redor da Urca:</p>

                <ul>
                    <li>Rua Odilio Bacelar</li>
                    <li>Rua Osório de Almeida</li>
                    <li>Rua Urbano Santos</li>
                    <li>Avn. Portugal</li>
                    <li>Rua Mal. Cantuária</li>
                </ul>

                <h6>Barca</h6>

                <p>O visitante de Niterói, Ilha do Governador e Paquetá pode pegar a barca que para na Praça XV. De lá, caminhe até o Largo da Carioca (740m), ou pegue a Linha 2 do VLT até a estação Cristiano Ottoni - Pequena África, e pegue o ônibus 107 (Central). Desça na UNIRIO e caminhe 180m até o Parque Bondinho.</p>

                <div className="bottom">
                    <h6>Ônibus</h6>

                    <p>As principais linhas para chegar ao Parque Bondinho são:</p>
                    <ul>
                        <li>107 – Central – Urca (Brs 1 – via Praia do Flamengo)</li>
                        <li>513SP – Urca – Metrô Botafogo (Via Rio Sul – Integração com metrô)</li>
                        <li>518 – Urca - Botafogo (Circular) – via Copacabana</li>
                        <li>519 – Urca - Copacabana – via Botafogo</li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default LocationPT;