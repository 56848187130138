import { useState, useEffect } from 'react';
//import i18next from 'i18next';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import api from '../../../services/api';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ProductsCarrousel.scss';
import { Link } from 'react-router-dom';

import i18next from 'i18next';

export interface propAction {
  codCategory: any;
}

const ProductsCarrousel: React.FC<propAction> = ({
  codCategory,
}: propAction) => {
  const [t] = useTranslation();
  const lng =
    i18next.language === 'pt'
      ? 'BR'
      : i18next.language === 'en'
        ? 'EN'
        : i18next.language === 'es'
          ? 'ES'
          : 'BR';

  const [nav1] = useState<any>();
  const [nav2] = useState<any>();
  const [, setSlider1] = useState<any>();
  //const [slider2] = useState<any>();

  const [resProduct, setResProduct] = useState<any>(null);
  const [layout, setLayout] = useState<any>(window.innerWidth);
  const [seeMore, setSeeMore] = useState<any>(
    layout < 575 ? 10 : layout < 768 ? 2 : layout < 1024 ? 3 : 4,
  );

  //Tamanho da Tela
  window.addEventListener('resize', function () {
    var newWidth = window.innerWidth;
    setLayout(newWidth);
  });
  //Tamanho da Tela

  function HandleSeeMore() {
    if (layout < 575) {
      if (seeMore <= resProduct.length) {
        setSeeMore(seeMore + 4);
      } else {
        setSeeMore(1);
      }
    } else if (layout < 768) {
      if (seeMore <= resProduct.length) {
        setSeeMore(seeMore + 4);
      } else {
        setSeeMore(2);
      }
    } else if (layout < 1024) {
      if (seeMore <= resProduct.length) {
        setSeeMore(seeMore + 4);
      } else {
        setSeeMore(3);
      }
    } else {
      if (seeMore <= resProduct.length) {
        setSeeMore(seeMore + 4);
      } else {
        setSeeMore(4);
      }
    }
  }

  function scrollView() {
    var scrollElement = document.getElementById('buy-product');
    scrollElement?.scrollIntoView(true);
  }

  useEffect(() => {
    let idChannel: number = 1;

    async function getProducts() {
      try {
        const { data } = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetProductsFromCategories`,
          {
            categoryCode: codCategory,
            lang: lng,
            channel: idChannel
          },
        );

        if (data.status !== 400) {
          setResProduct(data.data);
        }
      } catch (error) { }
    }

    const config = {
      headers: { "ngrok-skip-browser-warning": "69420" },
    };

    async function getIdChannel() {
      try {
        const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/api/Products/GetChannelBySource/site`, config);
        if (data.statusCode === 200) {
          idChannel = data.data.data;
          getProducts();
        }
      } catch (error) { }
    }

    getIdChannel();
  }, [codCategory, lng]);

  const settingsMain = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 10,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '60px',
          infinite: false,
          dots: false,
        },
      },
    ],
  };

  if (resProduct !== null) {
    return (
      <div
        id="main-product"
        className="atividadesDestacadas atividadesDestacadasCaracol w-100"
      >
        <Slider
          className="pb-3"
          {...settingsMain}
          asNavFor={nav2}
          ref={(slider) => setSlider1(slider)}
        >
          {resProduct.slice(0, seeMore).map((item: any, index: any) => {
            return (
              <div
                key={index}
                className="col-12 col-sm-6 col-md-4 col-lg-3 p-2 product-slider"
              >
                <Link
                  to={
                    item.productType === 'TOUR'
                      ? '/compra-rapida/' + item.productSlug + '?type=tour'
                      : item.productCode === 'TKT-BF1234'
                        ? '/black-friday/' + item.productSlug + '?type=ticket'
                        : '/compra-rapida/' + item.productSlug + '?type=ticket'
                  }
                  key={item.categoryCode}
                  className="card card-slider rounded overflow-hidden "
                >
                  <div
                    className="activ-img"
                    style={{
                      backgroundImage: `url(${item.imagesBaseUrl}${item.productImg})`,
                    }}
                  />
                  <div className="card-body d-flex flex-column justify-content-between">
                    <div className="mb-0 mb-md-2">
                      <p className="tourName mb-2">{item.productName}</p>
                      <div className="px-2">
                        {item.productIncludeItem.map(
                          (items: any, index: any) => {
                            return (
                              <p
                                key={index}
                                className="tourDescript"
                                title={items.split('•	').join('')}
                              >
                                <FontAwesomeIcon
                                  className="keep-color"
                                  icon={['fad', 'ticket']}
                                  size="1x"
                                  style={{ marginRight: '5px' }}
                                />
                                <span>{items.split('•	').join('')}</span>
                              </p>
                            );
                          },
                        )}
                      </div>
                    </div>
                    <div className="product-content">
                      <div>
                        <div
                          className=""
                          style={{ marginTop: '1rem', lineHeight: '.75rem' }}
                        >
                          {item.productCode !== process.env.REACT_APP_GAUCHO ? (
                            <p className="card-text h4">
                              <small className="text-uppercase">r$</small>
                              {item.price.toFixed(2).split('.').join(',')}
                            </p>
                          ) : (
                            <>
                              <small className="text-muted">
                                De:{' '}
                                <span
                                  style={{ textDecoration: 'line-through' }}
                                >
                                  <small className="text-uppercase">r$</small>
                                  75,00
                                </span>
                              </small>
                              <p className="card-text h4">
                                <small className="text-muted">Por: </small>{' '}
                                <small className="text-uppercase">r$</small>
                                {item.price.toFixed(2).split('.').join(',')}
                              </p>
                            </>
                          )}
                        </div>
                        <div className="buy-btn centerCenter">
                          <object style={{ width: '100%' }}>
                            <Link
                              to={
                                item.productType === 'TOUR'
                                  ? '/compra-rapida/' +
                                  item.productSlug +
                                  '?type=tour'
                                  : item.productCode === 'TKT-BF1234'
                                    ? '/black-friday/' +
                                    item.productSlug +
                                    '?type=ticket'
                                    : '/compra-rapida/' +
                                    item.productSlug +
                                    '?type=ticket'
                              }
                              className="btn btn-primary btn-natal my-1 p-0 rounded btn-lg "
                              type="button"
                            >
                              {t('productsCarrousel.buttonBuy')}
                            </Link>
                          </object>
                          <object>
                            <Link
                              to={
                                item.productType === 'TOUR'
                                  ? '/tour/' + item.productSlug
                                  : item.productCode === 'TKT-BF1234'
                                    ? '/black-friday'
                                    : '/ticket/' + item.productSlug
                              }
                              className="viewDetailsButton mt-3"
                            >
                              {t('productsCarrousel.seeDetails')}
                            </Link>
                          </object>
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </Slider>
        {process.env.REACT_APP_CLIENT_NAME === 'Bondinho' ? (
          layout < 576 ? (
            ''
          ) : (
            <div
              className="d-flex justify-content-center seeMoreSection"
              onClick={HandleSeeMore}
            >
              {seeMore < resProduct.length ? (
                <button
                  style={{ height: '40px' }}
                  className="btn rounded btn-lg "
                  type="button"
                >
                  {t('productsCarrousel.seeMore')}
                </button>
              ) : (
                <button
                  style={{ height: '40px' }}
                  className="btn rounded btn-lg "
                  type="button"
                  onClick={scrollView}
                >
                  {t('productsCarrousel.collect')}
                </button>
              )}
            </div>
          )
        ) : (
          ''
        )}
      </div>
    );
  } else {
    return (
      <div
        id="main-product"
        className="atividadesDestacadas atividadesDestacadasCaracol w-100"
      >
        <Slider
          {...settingsMain}
          asNavFor={nav2}
          ref={(slider) => setSlider1(slider)}
        >
          <div className="col-3 product-slider">
            <div className="card rounded overflow-hidden ">
              <div className="activ-img animated-background"></div>
              <div className="card-body">
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', maxWidth: '150px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>

                <div
                  className=""
                  style={{
                    maxHeight: '16px',
                    marginTop: '25px',
                    marginBottom: '30px',
                  }}
                >
                  <p
                    className="card-text h4 text-primary animated-background"
                    style={{ maxHeight: '16px', marginBottom: '5px' }}
                  ></p>
                  <p
                    className="card-text h4 text-primary animated-background"
                    style={{ maxHeight: '16px', marginBottom: '5px' }}
                  ></p>
                </div>

                <div className="centerCenter">
                  <button
                    className="btn btn-primary py-2 rounded btn-lg "
                    type="button"
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 product-slider">
            <div className="card rounded overflow-hidden ">
              <div className="activ-img animated-background"></div>
              <div className="card-body">
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', maxWidth: '150px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>

                <div
                  className=""
                  style={{
                    maxHeight: '16px',
                    marginTop: '25px',
                    marginBottom: '30px',
                  }}
                >
                  <p
                    className="card-text h4 text-primary animated-background"
                    style={{ maxHeight: '16px', marginBottom: '5px' }}
                  ></p>
                  <p
                    className="card-text h4 text-primary animated-background"
                    style={{ maxHeight: '16px', marginBottom: '5px' }}
                  ></p>
                </div>

                <div className="centerCenter">
                  <button
                    className="btn btn-primary py-2 rounded btn-lg "
                    type="button"
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 product-slider">
            <div className="card rounded overflow-hidden ">
              <div className="activ-img animated-background"></div>
              <div className="card-body">
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', maxWidth: '150px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>

                <div
                  className=""
                  style={{
                    maxHeight: '16px',
                    marginTop: '25px',
                    marginBottom: '30px',
                  }}
                >
                  <p
                    className="card-text h4 text-primary animated-background"
                    style={{ maxHeight: '16px', marginBottom: '5px' }}
                  ></p>
                  <p
                    className="card-text h4 text-primary animated-background"
                    style={{ maxHeight: '16px', marginBottom: '5px' }}
                  ></p>
                </div>

                <div className="centerCenter">
                  <button
                    className="btn btn-primary py-2 rounded btn-lg "
                    type="button"
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 product-slider">
            <div className="card rounded overflow-hidden ">
              <div className="activ-img animated-background"></div>
              <div className="card-body">
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', maxWidth: '150px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>
                <p
                  className="animated-background"
                  style={{ maxHeight: '16px', marginBottom: '5px' }}
                ></p>

                <div
                  className=""
                  style={{
                    maxHeight: '16px',
                    marginTop: '25px',
                    marginBottom: '30px',
                  }}
                >
                  <p
                    className="card-text h4 text-primary animated-background"
                    style={{ maxHeight: '16px', marginBottom: '5px' }}
                  ></p>
                  <p
                    className="card-text h4 text-primary animated-background"
                    style={{ maxHeight: '16px', marginBottom: '5px' }}
                  ></p>
                </div>

                <div className="centerCenter">
                  <button
                    className="btn btn-primary py-2 rounded btn-lg "
                    type="button"
                  >
                    Comprar
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    );
  }
};

export default ProductsCarrousel;
