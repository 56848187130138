import React from "react";

function AcessRulesEN() {
    return (
        <>
            {/* <p>Schedule your visit safely and without unforeseen events.</p> */}

            <h6 className="my-4"><b>It is not allowed to:</b></h6>

            <ul className="list-acess-rules">
                <li>Access with animals, except guide dogs or emotional support animals, with supporting documentation.</li>
                <li>It is forbidden to bathe or drink water from the river.</li>
                <li>Shoot or photograph the Caracol Park for commercial purposes without prior authorization from the company.</li>
                <li>Carry or use Drones.</li>
                <li>Enter with large materials (e.g., tripod, musical instruments, umbrella, beach chair, cooler, voluminous costumes, bags, or equipment above 25×35×55 cm).</li>
                <li>Enter or remain shirtless, in bathing suits or barefoot.</li>
                <li>Enter with skateboard, scooter, skates or shoes with built-in wheels, bicycle, or similar.</li>
                <li>Child or adolescent under 16 (sixteen) years to enter or remain in the park unaccompanied by parents or guardians. Guardians of minors must provide documentation proving the relationship of kinship up to third degree (fathers, mothers, siblings, uncles, nephews, grandparents), or authorization of the parents, as provided by law.</li>
                <li>Plant or remove flora.</li>
                <li>Practice any environmental depredation.</li>
                <li>Feed, approach or hunt the animals.</li>
                <li>Carry firearms, ammunition, self-defense devices (pepper spray, stun guns).</li>
                <li>Loud music, use horns, whistles, large megaphones or artificial devices that make noise.</li>
                <li>Perform manifestations.</li>
                <li>Use the park bathrooms for bathing.</li>
                <li>Enter using helmets or covers that hide the face – LAW 6717/14.</li>
                <li>Sell any product or service without prior authorization of the company.</li>
                <li>Perform any crime promotion.</li>

                {/* <div className="mt-4">
                    <i>
                        WARNING: The visit may be filmed, recorded, and/or photographed for subsequent publication, transmission, retransmission, reproduction, and/or dissemination on television, radio, internet, advertising in general, and any other communication vehicle, and/or the production of CDs, and DVDs. The Purchaser agrees that, upon attending the visit, they authorize the free use of their image, name, and voice, as mentioned above, in Brazil and abroad, without limit of times or period, without this characterizing misuse of the image or any other right and without any charge and/or indemnity.
                    </i>
                </div> */}
            </ul>
        </>
    )
}

export default AcessRulesEN;