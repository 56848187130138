import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import i18next from "i18next";

import { Container, Row } from "react-bootstrap";
import './Newsletter.scss'

export interface propNew {
  status: any,
  message: any,
  onValidated: any
}

const Newsletter: React.FC<propNew> = ({
  status, message, onValidated
}: propNew) => {
  const [t] = useTranslation();
  const lng = i18next.language === "pt" ? "BR" : i18next.language === "en" ? "EN" : i18next.language === "es" ? "ES" : 'BR';
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [sucess, setSucess] = useState(
    <div style={{ color: "green", textAlign: "center" }}>
      {
        lng === 'BR'
          ?
          "Obrigado! Inscrição realizada com sucesso!"
          :
          lng === "EN"
            ?
            "Thank you! Registration successful!"
            :
            lng === "ES"
              ?
              "Gracias. ¡Registro exitoso!"
              :
              "Obrigado! Inscrição realizada com sucesso!"
      }
    </div>
  );
  const [error, setError] = useState(
    <div style={{ color: "red", textAlign: "center" }}>
      {
        lng === 'BR'
          ?
          "E-mail já cadastrado!"
          :
          lng === "EN"
            ?
            "E-mail already registered"
            :
            lng === "ES"
              ?
              "Correo electrónico ya registrado"
              :
              "E-mail já cadastrado!"
      }
    </div>
  );
  const sending = useState(
    <div style={{ color: "blue", textAlign: "center" }}>
      {
        lng === 'BR'
          ?
          "Carregando..."
          :
          lng === "EN"
            ?
            "Loading..."
            :
            lng === "ES"
              ?
              "Cargando"
              :
              "Carregando..."
      }
    </div>
  );

  const handleSubmit = (e: any) => {
    if (process.env.REACT_APP_CLIENT_NAME === "Bondinho") {
      e.preventDefault();
      email &&
        firstName &&
        email.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email,
          MERGE1: firstName,
          "group[46362][8]": 8 // Grupo Bondinho
        });
    } else {
      e.preventDefault();
      email &&
        firstName &&
        email.indexOf("@") > -1 &&
        onValidated({
          EMAIL: email,
          MERGE1: firstName,
          "group[46362][4]": 4 // Grupo Caracol
        });
    }
  }

  useEffect(() => {
    if (status === "success") {
      clearFields();

      setTimeout(() => {
        setSucess(<div></div>)
      }, 10000);
    }
    setSucess(
      <div style={{ color: "green", textAlign: "center" }}>
        {
          lng === 'BR'
            ?
            "Obrigado! Inscrição realizada com sucesso!"
            :
            lng === "EN"
              ?
              "Thank you! Registration successful!"
              :
              lng === "ES"
                ?
                "Gracias. ¡Registro exitoso!"
                :
                "Obrigado! Inscrição realizada com sucesso!"
        }
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  const clearFields = () => {
    setFirstName('');
    setEmail('');
  }

  useEffect(() => {
    if (status === "error") {
      setTimeout(() => {
        setError(<div></div>)
      }, 10000);
    }
    setError(
      <div style={{ color: "red", textAlign: "center" }}>
        {
          lng === 'BR'
            ?
            "E-mail já cadastrado!"
            :
            lng === "EN"
              ?
              "E-mail already registered"
              :
              lng === "ES"
                ?
                "Correo electrónico ya registrado"
                :
                "E-mail já cadastrado!"
        }
      </div>
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return (
    <>
      <Container fluid className="bg-newsletter p-0" style={{ backgroundImage: `url(${process.env.REACT_APP_IMAGE_NEWSLETTER})` }}>
        <div className="bg-newsletter-bottom">
          <Container className="newsletter py-4">
            <Row className="px-3 mx-0 pt-5 pb-3">
              <div className="text-newsletter">
                <h2 className="newsletter-title">{t('newsletter.title')}</h2>
                {
                  process.env.REACT_APP_CLIENT_NAME === "Bondinho"
                    ?
                    <>
                      <p className="text-newsletter">{t('newsletter.subtitle')}</p>
                      <p className="text-newsletter fw-normal">{t('newsletter.description')}</p>
                    </>
                    :
                    <>
                      <p className="text-newsletter fw-normal">{t('newsletter.description')}</p>
                    </>
                }
              </div>
              <div className="col-12">
                <form method="post" onSubmit={(e) => handleSubmit(e)} action={process.env.REACT_APP_MAILCHIMP}>
                  <Row className="my-2">
                    <div className="col-12 col-md-5">
                      <input
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder={t('newsletter.placeholderName')}
                        className="mb-2 mb-md-0"
                        value={firstName}
                        onChange={(e: any) => setFirstName(e.target.value)}
                      />
                    </div>
                    <div className='d-none'><input type="hidden" name="tags" value="7316242" /></div>
                    <div className="col-12 col-md-5">
                      <input
                        type="email"
                        name="E-mail"
                        id="email"
                        placeholder={t('newsletter.placeholderEmail')}
                        className="mb-2 mb-md-0"
                        value={email}
                        onChange={(e: any) => setEmail(e.target.value)}
                      />
                    </div>
                    <div className="col-12 col-md-2">
                      <button
                        type="submit"
                        className="btn-newsletter">{t('newsletter.button')}
                      </button>
                    </div>
                  </Row>
                </form>
              </div>
            </Row>
            <div className='message-newsletter'>
              {status === "sending" &&
                <div className='pb-3'>{sending}</div>
              }
              {status === "error" &&
                <div className='pb-3'>{error}</div>
              }
              {status === "success" &&
                <div className='pb-3'>{sucess}</div>
              }
            </div>

            {
              process.env.REACT_APP_CLIENT_NAME === "Caracol"
                ?
                i18next.language === "pt"
                  ?
                  <div className='d-flex justify-content-center'>
                    <small className="text-newsletter" style={{ lineHeight: "1.5rem", fontSize: ".85rem" }}>Ao informar seus dados, você concorda em receber newsletter do Parque do Caracol e concorda com a nossa <a href="/aviso-de-privacidade" style={{ color: "#FFCF01" }}>Política de Privacidade</a>. Você pode se descadastrar a qualquer momento.</small>
                  </div>
                  :
                  i18next.language === "en"
                    ?
                    <div className='d-flex justify-content-center'>
                      <small className="text-newsletter" style={{ lineHeight: "1.5rem", fontSize: ".85rem" }}>By providing your data, you agree to receive a newsletter from Parque do Caracol and agree with our <a href="/aviso-de-privacidade" style={{ color: "#FFCF01" }}>Privacy Policy</a>. You can unsubscribe at any time.
                      </small>
                    </div>
                    :
                    i18next.language === "es"
                      ?
                      <div className='d-flex justify-content-center'>
                        <small className="text-newsletter" style={{ lineHeight: "1.5rem", fontSize: ".85rem" }}>Al proporcionar sus datos, acepta recibir un boletín de noticias del Parque do Caracol y está de acuerdo con nuestra <a href="/aviso-de-privacidade" style={{ color: "#FFCF01" }}>Política de privacidad</a>. Puedes darte de baja en cualquier momento.</small>
                      </div>
                      :
                      <div className='d-flex justify-content-center'>
                        <small className="text-newsletter" style={{ lineHeight: "1.5rem", fontSize: ".85rem" }}>Ao informar seus dados, você concorda em receber newsletter do Parque do Caracol e concorda com a nossa <a href="/aviso-de-privacidade" style={{ color: "#FFCF01" }}>Política de Privacidade</a>. Você pode se descadastrar a qualquer momento.</small>
                      </div>
                :
                <>
                </>
            }

          </Container>
        </div>
      </Container >
    </>
  );
}

export default Newsletter;