import React, { useState, useEffect } from 'react';

import { useTranslation } from "react-i18next";
import i18next from "i18next";

export interface propTour {
  tour: any,
};

const CheckoutSummartTour: React.FC<propTour> = ({
  tour
}: propTour) => {
  const [language, setLanguage] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    setLanguage(i18next.language)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language])

  return (
    <div>
      <div className="row border-bottom align-items-center">
        <div className="col-12 text-left mt-4">
          <h6 className="m-0 p-0 text-uppercase">{language === "pt" ? tour.productNameBR : language === "en" ? tour.productNameEN : language === "es" ? tour.productNameES : tour.productNameBR}</h6>
          <small>{t("checkoutSteps.date")}: {tour.date.split("-").reverse().join("/")} {tour?.hours?.length !== 1 || tour?.reservationSystem === 99 ? tour?.time?.split(":")[0] + ":" + tour?.time?.split(":")[1] : ""}{" "}
          </small>
        </div>

        {
          tour?.reservationSystem === 99
            ?
            <div className='my-2'>
              <small><strong>Local de Embarque:</strong> {tour?.pickupInfo?.address}</small>
            </div>
            :
            <></>
        }
      </div>

      {
        tour.ranges.labelsRealSorted.map((faixa: any, i: any) => {
          return (
            <div key={i}>
              {
                (tour[`${faixa.toLowerCase()}`] | tour[`${faixa.toLowerCase()}s`]) > 0
                  ?
                  faixa !== "Private"
                    ?
                    <div className="row border-bottom py-2 small align-items-center">
                      <div className="col-5 text-left">{tour.ranges[`label${faixa}`]}: </div>
                      <div className="col-2 text-center px-0">{(tour[`${faixa.toLowerCase()}`] | tour[`${faixa.toLowerCase()}s`])} x</div>
                      <div className="col-5 text-right ">R$ {(Number(tour[`price${faixa}`]) | Number(tour[`price${faixa}s`])).toFixed(2).split('.').join(',')}</div>
                    </div>
                    :
                    <div className="row border-bottom py-2 small align-items-center">
                      <div className="col-5 text-left">{tour.ranges[`labelPrivate`]}: </div>
                      <div className="col-2 text-center px-0">{tour[`totalPeople`]} x</div>
                      <div className="col-5 text-right ">R$ {Number(tour[`priceGlobalPeople`]).toFixed(2).split('.').join(',')}</div>
                    </div>
                  :
                  ""
              }
            </div>
          )
        })}
      {tour.discountBool === true
        ?
        <div className="row border-bottom py-2 small align-items-center">
          <div className="col-5 text-left">Desconto: </div>
          <div className="col-2 text-center px-0"> x</div>
          <div className="col-5 text-right ">{Number(tour.discountValue)}%</div>
        </div>
        :
        ''
      }
      {/* <div className="row border-bottom py-2 small">
        <div className="col-12 text-right "><strong data-price="subtotal">R$ {tour.totalPrice.toFixed(2).split('.').join(',')}</strong></div>
      </div> */}
    </div>
  );
}

export default CheckoutSummartTour;