import React from "react";

import { Container } from "react-bootstrap";

import { useTranslation } from "react-i18next";

//import { Link } from 'react-router-dom';

import PageDafault from "../../templates/PageDafault";

import "./ExclusiveBonde.scss";

function ExclusiveBonde() {
    const [t] = useTranslation();

    return (
        <>
            <PageDafault>
                <Container className="page-static py-5">
                    <h1 className="mb-5 text-center display-2">{t("exclusiveBonde.title")}</h1>
                    <div className="card py-4 px-3">
                        <div className="p-3">
                            <p className="mb-4">
                                {t("exclusiveBonde.text01")}
                            </p>

                            <ul className="exclusive-bonde mb-4">
                                <li><u>{t("exclusiveBonde.list01-1")}</u>{t("exclusiveBonde.list01-2")}</li>
                                <li>{t("exclusiveBonde.list02-1")}<u>{t("exclusiveBonde.list02-2")}</u>{t("exclusiveBonde.list02-3")}</li>
                                <li>{t("exclusiveBonde.list03-1")}<u>{t("exclusiveBonde.list03-2")}</u>{t("exclusiveBonde.list03-3")}<u>{t("exclusiveBonde.list03-4")}</u>{t("exclusiveBonde.list03-5")}</li>
                                <li>{t("exclusiveBonde.list04")}</li>
                                <li>{t("exclusiveBonde.list05")}</li>
                            </ul>

                            <p>
                                {t("exclusiveBonde.text02")}
                            </p>

                            <div className="d-flex flex-column align-items-center mb-4">
                                <h5 className="mb-2"><strong>{t("exclusiveBonde.info")}</strong></h5>
                                <a href="mailto:negocios@bondinho.com.br">negocios@bondinho.com.br</a>
                                {/* <Link to="/contato?bonde-exclusivo" className="btn btn-primary text-uppercase px-5 py-2 my-2"><strong>{t("exclusiveBonde.want")}</strong></Link> */}
                            </div>

                            <p>
                                {t("exclusiveBonde.text03")}
                            </p>
                        </div>
                    </div>
                </Container>
            </PageDafault>
        </>
    )
}

export default ExclusiveBonde;