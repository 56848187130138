import React from "react";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import "./HowToGetCaracol.scss";
import i18next from "i18next";
import HowToGetCaracolPT from "./components/HowToGetCaracolPT/HowToGetCaracolPT";
import HowToGetCaracolEN from "./components/HowToGetCaracolEN/HowToGetCaracolEN";
import HowToGetCaracolES from "./components/HowToGetCaracolES/HowToGetCaracolES";


function HowToGetCaracol() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [t] = useTranslation();

  return (
    <>
      <Container
        fluid
        className="page-static-caracol bg-how-to-get-caracol"
      >
        <Row>
          <Col
            md={{ span: 4, offset: 1 }}
            sm={10}
            className="d-flex justify-content-end px-3 py-auto"
          >
            {
              i18next.language === "pt"
                ?
                <HowToGetCaracolPT />
                :
                i18next.language === "en"
                  ?
                  <HowToGetCaracolEN />
                  :
                  i18next.language === "es"
                    ?
                    <HowToGetCaracolES />
                    :
                    <HowToGetCaracolPT />
            }
          </Col>
          <Col md={7}>
            <div className="embed-responsive embed-responsive-16by9 h-100 w-100">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3478.9900151897073!2d-50.85569252499975!3d-29.311968547697074!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x951ecd5b1eff3555%3A0x8f7d9481de645462!2sParque%20do%20Caracol!5e0!3m2!1spt-BR!2sbr!4v1672078196497!5m2!1spt-BR!2sbr"
                width="100%"
                height="100%"
                frameBorder="0"
                title="how to get"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default HowToGetCaracol;
