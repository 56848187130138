import React from "react";

function AcessRulesPT() {
    return (
        <>
            {/* <p>Programe sua visita com segurança e sem imprevistos.</p> */}

            <h6 className="my-4"><b>Não é permitido:</b></h6>

            <ul className="list-acess-rules">
                <li>Acessar com animais, exceto cães guia ou animais de apoio emocional mediante documentação comprobatória.</li>
                <li>Proibido banhar-se ou beber água do Rio.</li>
                <li>Filmar ou fotografar o Parque do Caracol para fins comerciais sem prévia autorização da empresa.</li>
                <li>Portar ou utilizar drones.</li>
                <li>Acessar com materiais de grande porte (ex.: tripé, instrumentos musicais, guarda-sol, cadeira de praia, cooler, fantasias volumosas, malas ou equipamentos acima de 25 x 35 x 55 cm).</li>
                <li>Acessar ou permanecer sem camisa, com trajes de banho ou descalço.</li>
                <li>Acessar com skate, patinete, patins ou sapatos com rodas embutidas, bicicleta ou similares.</li>
                <li>Criança ou adolescente menor de 18 (dezesseis) anos acessar ou se manter no parque desacompanhado dos pais ou responsáveis maiores de idade. Os responsáveis pelos menores devem apresentar documentação comprovatória da relação de parentesco de até terceiro grau (pais, mães, irmãos, tios, sobrinhos, avós) ou autorização dos pais, conforme previsto em lei.</li>
                <li>Plantar ou retirar espécies da flora.</li>
                <li>Praticar qualquer tipo de depredação do meio ambiente.</li>
                <li>Alimentar, se aproximar ou caçar os animais.</li>
                <li>Portar armas de fogo, munição, dispositivos de autodefesa (spray de pimenta, armas de choque).</li>
                <li>Som alto, utilizar cornetas, apitos, grandes megafones ou dispositivos artificiais que emitam ruídos. </li>
                <li>Realizar manifestações diversas.</li>
                <li>Utilizar os banheiros do parque para banho.</li>
                <li>Acessar usando capacetes ou coberturas que ocultem a face – Lei nº 6717/14.</li>
                <li>Comercializar qualquer tipo de produto ou serviço sem prévia autorização.</li>
                <li>Realizar qualquer apologia ao crime.</li>
                
                {/* <div className="mt-4">
                    <i>
                        AVISO: a visita poderá ser, por parte da equipe do Parque Caracol, filmada, gravada e/ou fotografada para posterior publicação, transmissão, retransmissão, reprodução e/ou divulgação em televisão, rádio, internet, publicidade em geral e qualquer outro veículo de comunicação, produção de CD e DVD. O comprador concorda que, ao comparecer à visita, autoriza a utilização gratuita de sua imagem, seu nome e sua voz, nos termos ora mencionados, no Brasil e no exterior, sem limite de vezes e tempo, sem que isso caracterize uso indevido de imagem ou qualquer outro direito e sem que dessa utilização ocorra qualquer ônus e/ou indenização.
                    </i>
                </div> */}
            </ul>
        </>
    )
}

export default AcessRulesPT